import { Component, Input, Output, EventEmitter } from "@angular/core";

interface EditControlChange {
  key: string;
  value: any;
}

@Component({
  selector: "app-form-control-edit",
  templateUrl: "./form-control-edit.html",
  styleUrls: ["./form-control-edit.sass"],
})
export class FormControlEditComponent {
  @Input() value: any;
  @Input() ID: string;
  @Output() updateValue: EventEmitter<EditControlChange> = new EventEmitter<
    EditControlChange
  >();
  editMode: boolean;

  editField() {
    this.editMode = true;
  }

  onInput(change: any) {
    this.editMode = false;
    this.value = change.target.value;
    this.updateValue.emit({ key: this.ID, value: change.target.value });
  }
}
