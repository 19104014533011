import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap, shareReplay } from "rxjs/operators";
import { environment } from "../../../environments/environment";

import { BaseApiService } from "../baseAPI";
import { User } from "@modules/_models/User";
// import { SocialUser } from "angularx-social-login";

const API_USERS_URL = environment.baseUrl;

const cache = new Map();
@Injectable()
export class CoursesService extends BaseApiService<any>{

    constructor(http: HttpClient) {
        super(http);
        this.url = "/courses";
    }

    getCourses(query: any): Observable<any> {
        const filter = query ? "?filter=" + `${JSON.stringify(query)}` : "";
        return this.http.get<any>(this.baseUrl + this.url + `/findCourses` + filter);
    }
}
