import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-exams-test-simple-learning",
  templateUrl: "./exams-test-simple-learning.html",
  styleUrls: ["./exams-test-simple-learning.sass"],
})
export class ExamsTestSimpleLearningComponent implements OnInit {
  ngOnInit() {}
}
