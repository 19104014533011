import {
  Component,
  Input,
  TemplateRef,
  EventEmitter,
  Output,
} from "@angular/core";
import { UsersService } from "@services/apis/users.service";

@Component({
  selector: "app-dialog-component",
  template: `<div class="modal-dialog" *ngIf="isOpen">
    <div class="modal-dialog-content">
      <ng-container
        *ngTemplateOutlet="
          modalDialogContent ? modalDialogContent : defaultModalDialogContent
        "
      ></ng-container>
      <ng-template #defaultModalDialogContent>
        <span>Modal dialog content.</span>
      </ng-template>
      <ng-container
        *ngTemplateOutlet="
          modalDialogFooter ? modalDialogFooter : defaultModalDialogFooter
        "
      ></ng-container>
      <ng-template #defaultModalDialogFooter>
        <button class="btn-green btn no-border"  (click)="close()">
          {{ closeBtnText }}
        </button>
      </ng-template>
    </div>
  </div>`,
  styleUrls: ["./common-dialog.component.sass"],
})
export class CommonDialogComponent {
  @Input() modalDialogContent: TemplateRef<any>;
  @Input() modalDialogFooter: TemplateRef<any>;
  @Input() isOpen: boolean;
  @Input() closeBtnText: string;
  @Output() emitClose = new EventEmitter<boolean>();
  constructor(private userApi: UsersService) {}
  close(): void {
    this.isOpen = false;
    this.emitClose.emit();
  }
}
