<form 
(ngSubmit)="f.form.valid &&updateFormStep()"
#f="ngForm"
class="register-form"
name="registerData"
>
<div class="home-analytics-profile"  style="direction: rtl;">

  <div class="home-analytics-profile-form-container" style="direction: rtl;">
      <p class="home-analytics-profile-form-container-title" >
        معلومات المستخدم والدفع
      </p>
      <div class="form-field-inline">
        <div class="form-field">
          <label for="firstName">الاسم الاول</label>
          <input
            class="form-control"
            type="text"
            name="firstName"
            [(ngModel)]="user&&user.firstName"
            #firstName="ngModel"
            required
            [ngClass]="{
              'has-danger':
                (f.submitted || firstName.touched) &&
                firstName.hasError('required')
            }"
            [ngModelOptions]="{ standalone: true }"
          />
        <div
        *ngIf="
          (f.submitted || firstName.touched) &&
          firstName.hasError('required')
        "
        class="feed_back"
        style="color: red"
      >
        الاسم الاول مطلوب
      </div>
        </div>
        <div class="form-field" style="margin-right: 4%; margin-left: 0%;">

          <label for="lastName">الاسم الاخير</label>         
          <input
          id="lastName"
          class="form-control"
          type="text"
          name="lastName"
          [(ngModel)]="user&&user.lastName"
          [ngModelOptions]="{ standalone: true }"
          #lastName="ngModel"
          required
          [ngClass]="{
            'has-danger':
              (f.submitted || lastName.touched) &&
              lastName.hasError('required')
          }"
        />
        <div
          *ngIf="
            (f.submitted || lastName.touched) && lastName.hasError('required')
          "
          class="feed_back"
          style="color: red"
        >
          الاسم الاخير مطلوب
        </div>



          
        </div>
      </div>
      <div class="form-field">
        <label>رقم الهاتف</label>
        <input
          class="form-control"
          type="text"
          name="phone"
          #phone="ngModel"
          [(ngModel)]="user&&user.mobile"
          [ngModelOptions]="{ standalone: true }"
          required
          [ngClass]="{ 'has-danger': f.submitted && !user.mobile }"
        />
        <div
          *ngIf="f.submitted && !user.mobile"
          class="feed_back"
          style="color: red"
        >
          رقم الهاتف مطلوب
        </div>
        <div
          *ngIf="f.submitted && invalidMobile"
          class="feed_back"
          style="color: red"
        >
          رقم الهاتف غير صحيح
        </div>
        <div
        *ngIf="f.submitted && phoneExist"
        class="form-control-feedback"
        style="color: red"
      >
      هذا الجوال لديه حساب أخر بالفعل
      </div>
      </div>
      <div class="form-field">
        <label for="firstName">اسم المستخدم</label>
        <input
          class="form-control"
          type="text"
          name="userName"
          [(ngModel)]="user&&user.userName"
          #userName="ngModel"
          required
          [ngClass]="{
            'has-danger':
              (f.submitted || userName.touched) &&
              userName.hasError('required')
          }"
          [ngModelOptions]="{ standalone: true }"
        />
      <div
      *ngIf="
        (f.submitted || userName.touched) &&
        userName.hasError('required')
      "
      class="feed_back"
      style="color: red"
    >
     اسم المستخدم مطلوب
    </div>
      </div>
      <div class="form-field">
        <label>الأيميل</label>
        <input
          class="form-control"
          type="email"
          name="email"
          [(ngModel)]="user&&user.email"
          [ngModelOptions]="{ standalone: true }"
          #email="ngModel"
          required
          pattern="[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
          [ngClass]="{
            'has-danger':
              (f.submitted || email.touched) &&
              (email.hasError('required') || email.hasError('pattern'))
          }"
        />
        <div
          *ngIf="(f.submitted || email.touched) && email.hasError('required')"
          class="feed_back"
          style="color: red"
        >
          الأيميل مطلوب
        </div>
        <div
          *ngIf="(f.submitted || email.touched) && email.hasError('pattern')"
          class="feed_back"
          style="color: red"
        >
          الأيميل غير صيح
        </div>
        <div
          *ngIf="emailExists"
          class="feed_back"
          style="color: red"
        >
        هذاالأيميل لديه حساب أخر بالفعل
        </div>
      </div>
  </div>







  <div class="form-centered-separator-line"></div>



  <div class="home-analytics-profile-form-container" style="direction: rtl;">
      <p class="home-analytics-profile-form-container-title">
        تغيير كلمة المرور
      </p>
      <div class="form-field form-field-with-left-icon">
        <label for="password">كلمة المرور الحالية</label>
        <div
          toggle="#passowrd"
          class="toggle-password-visibility-icon icon-100"
          (mousedown)="showPassword()"
          (mouseup)="hidePassword()"
        ></div>
        <input
          id="password"
          class="form-control"
          type="password"
          name="password"
          #pass="ngModel"
          required
          [(ngModel)]="currentPassword"
          [ngModelOptions]="{ standalone: true }"
          [ngClass]="{
            'has-danger':
              (f.submitted || pass.touched) && pass.hasError('required')
          }"
        />
        <div
          *ngIf="(f.submitted || pass.touched) && pass.hasError('required')"
          class="feed_back"
          style="color: red"
        >
          كلمه المرور مطلوبه
        </div>
      </div>
      <div class="form-field form-field-with-left-icon">
        <label for="password">كلمة المرور الجديدة</label>
        <div
          toggle="#passowrd"
          class="toggle-password-visibility-icon icon-100"
          (mousedown)="showPassword()"
          (mouseup)="hidePassword()"
        ></div>
        <input
          id="password"
          class="form-control"
          type="password"
          name="password"
          #pass="ngModel"
          [(ngModel)]="newPassword"
          [ngModelOptions]="{ standalone: true }"
        />
      </div>
      <div class="form-field form-field-with-left-icon">
        <label>
          <label for="confirm_password">تأكيد كلمة المرور</label>
        </label>
        <div class="toggle-password-visibility-icon icon-100"></div>
        <input
          id="confirmPassword"
          class="form-control"
          type="password"
          name="confirmPassword"
          #confirmPass="ngModel"
          [(ngModel)]="confirmPassword"
          [ngModelOptions]="{ standalone: true }"
        />
        <div
          *ngIf="notMatched"
          class="feed_back"
          style="color: red"
        >
          كلمه المرور لاتطابق
        </div>
      </div>
      <div
      *ngIf="invalidPassword"
      class="feed_back"
      style="color: red"
    >
     كلمه المرور غير صحيحه
    </div>
      <div class="form-field-submit">
        <button class="btn btn-large  btn-green no-border">حفظ</button>
      </div>
  </div>

  
</div>
</form>

<div class="saved" *ngIf="savedForm">
  <div class="row bg_white w-100 justify-content-center">
    <div class="col-lg-3">
      <p class="green text-center">تم الحفظ</p>
    </div>
  </div>
</div>
