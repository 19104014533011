import { PromiseComponent } from "./promise/promise";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PlansService } from "@services/apis/plans.service";
import { TransactionsService } from "@services/apis/transaction.service";
import { UsersService } from "@services/apis/users.service";
import * as moment from "moment";

@Component({
  selector: "app-home-analytics",
  templateUrl: "./home-analytics.component.html",
  styleUrls: ["./home-analytics.component.sass"],
})
export class SubAndPaymentComponent implements OnInit {
  isAnalyticsDialogOpened: boolean;
  transactions: any;
  participation: any;
  subscriptions: any[] = [];
  monthDurations: any;
  startDate: any;
  endDate: any;
  modalOption: NgbModalOptions = null;
  years: any;
  months: any;
  days: any;
  constructor(
    private userApi: UsersService,
    private router: Router,
    private transactionApi: TransactionsService,
    private participationApi: PlansService,
    private modalService: NgbModal
  ) {
    moment.locale("ar");
  }
  async ngOnInit() {
    localStorage.removeItem('currentCourseId');
    let currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser) {
      this.userApi.get(currentUser.userId).subscribe(
        (res) => {
          if (res != null && (res.promise == null || res.promise == false)) {
            // this.isAnalyticsDialogOpened = true;
            currentUser.promise = false;
            localStorage.setItem("currentUser", JSON.stringify(currentUser));
            this.modalService.open(PromiseComponent, {
              windowClass: "forgot-password",
              backdrop: "static",
              keyboard: false,
            });
          } else if (res.promise != null || res.promise == true) {
            currentUser.promise = true;
            localStorage.setItem("currentUser", JSON.stringify(currentUser));
          }
        },
        (error) => {
          console.log(error);
          this.router.navigate(["/auth"]);
          // this.isAnalyticsDialogOpened = true;
        }
      );
      let transactions: any = await this.transactionApi
        .query({
          where: {
            userId: currentUser.userId,
            isDeleted: { neq: true }, isSuccess: true,
            // endDate: {
            //   gte: moment()
            //   // .format("YYYY-MM-DD[T00:00:00.000Z]"),
            // },
          }
        })
        .toPromise();
      this.transactions = transactions.result.filter(
        (x) => x.isSuccess == true &&
          moment(x.endDate).isAfter(moment())
          && moment().isAfter(moment(x.startDate))
      );
      if (this.transactions != null && this.transactions.length > 0) {
        if (currentUser) {
          let isSubscriptionValid = moment(
            this.transactions[this.transactions.length - 1].endDate
          ).isAfter(moment());
          currentUser.subscribedAndPaid = true;
          if (isSubscriptionValid != true)
            currentUser.subscribedAndPaid = false;
          currentUser.allowLecture = this.transactions[0].allowLecture;
          currentUser.allowExam = this.transactions[0].allowExam;
          localStorage.setItem("currentUser", JSON.stringify(currentUser));
        }
      }
      this.transactions = transactions.result.filter(
        (x) => x.isSuccess == true 
      );
      if (this.transactions != null && this.transactions.length > 0) {
        // this.startDate = this.transactions[0].startDate;
        // this.endDate = this.transactions[this.transactions.length - 1].endDate;
        this.participation = await this.participationApi
          .get(this.transactions[0].planId)
          .toPromise();

        this.transactions.forEach(sub => {
          this.setDuration(sub.id, sub.endDate)
        });
        // moment.locale("ar");
        // this.monthDurations = moment
        //   .duration(moment(this.endDate).diff(moment()))
        //   .humanize();
        // moment.locale("en");
        // let durationObject = moment.duration(
        //   moment(this.endDate).diff(moment())
        // )["_data"];
        // console.log(durationObject);
        // this.setDuration(durationObject);
      }
    }
  }

  setDuration(id: string, endDate: any) {
    let transaction = this.transactions.find(x => x.id === id);
    let durationObject = moment.duration(
      moment(endDate).diff(moment())
    )["_data"];

    if(durationObject.seconds < 0) durationObject.isOver = true;
    else durationObject.isOver = false;

    if (durationObject.years > 0) {
      if (durationObject.years == 1) transaction.years = "سنة";
      if (durationObject.years == 2) transaction.years = "سنتين";
      else transaction.years = `${durationObject.years} سنوات`;
    }
    if (durationObject.months > 0) {
      if (durationObject.months == 1) transaction.months = "شهر";
      if (durationObject.months == 2) transaction.months = "شهرين";
      else transaction.months = `${durationObject.months} أشهر`;
    }
    if (durationObject.days > 0) {
      if (durationObject.days == 1) transaction.days = "يوم";
      if (durationObject.days == 2) transaction.days = "يومين";
      else transaction.days = `${durationObject.days} أيام`;
    }
    transaction.isOver = durationObject.isOver;
  }
  reparticipation(courseId: string) {
    this.router.navigate(["/sign-up"], { queryParams: { courseId } }).then();
  }
  openAnalyticsDialog() {
    this.isAnalyticsDialogOpened = true;
  }
  handlepromiseClose() {
    const currentUser: any = localStorage.getItem("currentUser");
    if (currentUser) {
      this.userApi.promise(true, JSON.parse(currentUser).userId).subscribe(
        (res) => {
          this.isAnalyticsDialogOpened = false;
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
}
