import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from "@angular/core";
import { ExamsService } from "@services/apis/exam.service";
import { LecturesService } from "./../../../../services/apis/lectures.service";

interface GridHeadingItem {
  key: any;
  value: any;
}
// todo
// `Test` interface.
@Component({
  selector: "app-lections-previous-tests",
  templateUrl: "lections-previous-tests.html",
  styleUrls: ["./lections-previous-tests.sass"],
})
export class LectionsPreviousComponent implements OnInit {
  @Input() isRightNavOpened: boolean;

  searchPlaceholder: string;
  searchValue: string;
  courseId: string;
  testsData: Array<any>;
  gridHeadingItems: Array<GridHeadingItem>;
  constructor(
    public lecturesService: LecturesService,
    private userExamApi: ExamsService,
    private activatedRoute: ActivatedRoute
  ) {}
  getTimeType(userExam) {
    if (userExam != null && userExam.exam != null && userExam.exam.time) {
      return "وقتى";
    }
    return "غير وقتى";
  }
  async getLectureNames(userExam) {
    let lectureNames = "";
    if (userExam != null && userExam.exam != null) {
      let lectures: any = await this.lecturesService
        .query({ where: { id: { inq: userExam.exam.lectures } } })
        .toPromise();
      lectureNames = lectures.result.map((x) => x.LectureName).join(",");
    } else {
      let lectures: any = await this.lecturesService
        .query({ where: { id: userExam.lectureId } })
        .toPromise();
      lectureNames = lectures.result.map((x) => x.LectureName).join(",");
    }
    return lectureNames.toString();
  }
  async ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if(params.courseId) {
        this.courseId = params.courseId;
      } 
      else {
        this.activatedRoute.firstChild.params.subscribe(param => {
          this.courseId = param.courseId;
      })
    }
    })
      
      const currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
      let userId = null;
      if (currentUser) {
        userId = currentUser.userId;
      }
      let exams: any = await this.userExamApi
      .query({
        where: {
          userId: userId,
          courseId: this.courseId,
          isCompleted: true,
        },
      })
      .toPromise();
      this.testsData = exams.result;
      console.log(exams);
      for (let i = 0; i < this.testsData.length; i++) {
        this.testsData[i].lectureNames = await this.getLectureNames(
          this.testsData[i]
          );
        }
        
        this.gridHeadingItems = [
          {
            key: "more",
            value: "المزيد",
          },
          {
            key: "questionsCount",
            value: "عدد الاسئلة",
          },
          {
            key: "parts",
            value: "الأجزاء",
          },
          {
        key: "questionsTypes",
        value: "نوع الاسئلة",
      },
      {
        key: "exam",
        value: "التاريخ",
      },
      {
        key: "name",
        value: "الاسم",
      },
      {
        key: "result",
        value: "النتيجة",
      },
    ];
    this.searchPlaceholder = "أبحث عن الاختبار";
  
  }
  
  onChangeSearchValue(event: any) {
    this.searchValue = event.target.value;
  }
  
  returnNumberOfLectures(lec) {
    let isArrayOfLectures = Array.isArray(lec);
    if (isArrayOfLectures == true) {
      return lec.length;
    }
    return 1;
  }
}
