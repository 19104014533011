<div class="register paddingsmall" > 
  <app-register-stepper></app-register-stepper>
  <!-- Register form -->
  <div *ngIf="activeStep === 0">
    <form
      (ngSubmit)="f.form.valid && updateFormStep(1)"
      #f="ngForm"
      class="register-form"
      name="registerData"
      *ngIf="currentUser === null"
    >
      <div class="form-field-inline" style="direction: rtl">
        
        <div class="form-field" >
          <label for="firstName">الاسم الاول</label>
          <input
            class="form-control"
            type="text"
            name="firstName"
            [(ngModel)]="registerForm.firstName"
            #firstName="ngModel"
            required
            [ngClass]="{
              'has-danger':
                (f.submitted || firstName.touched) &&
                firstName.hasError('required')
            }"
            [ngModelOptions]="{ standalone: true }"
          />
          <div
            *ngIf="
              (f.submitted || firstName.touched) &&
              firstName.hasError('required')
            "
            class="feed_back"
            style="color: red"
          >
            الاسم الاول مطلوب
          </div>
        </div>
        <div class="form-field" style="margin-right: 4%;" >
          <label for="lastName">الاسم الاخير</label>
          <input
            id="lastName"
            class="form-control"
            type="text"
            name="lastName"
            [(ngModel)]="registerForm.lastName"
            [ngModelOptions]="{ standalone: true }"
            #lastName="ngModel"
            required
            [ngClass]="{
              'has-danger':
                (f.submitted || lastName.touched) &&
                lastName.hasError('required')
            }"
          />
          <div
            *ngIf="
              (f.submitted || lastName.touched) && lastName.hasError('required')
            "
            class="feed_back"
            style="color: red"
          >
            الاسم الاخير مطلوب
          </div>
        </div>
      </div>
      <div class="form-field">
        <label>الأيميل</label>
        <input
          class="form-control"
          type="email"
          name="email"
          [(ngModel)]="registerForm.email"
          [ngModelOptions]="{ standalone: true }"
          #email="ngModel"
          required
          pattern="[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
          [ngClass]="{
            'has-danger':
              (f.submitted || email.touched) &&
              (email.hasError('required') || email.hasError('pattern'))
          }"
        />
        <div
          *ngIf="(f.submitted || email.touched) && email.hasError('required')"
          class="feed_back"
          style="color: red"
        >
          الأيميل مطلوب
        </div>
        <div
          *ngIf="(f.submitted || email.touched) && email.hasError('pattern')"
          class="feed_back"
          style="color: red"
        >
          الأيميل غير صحيح
        </div>
        <div
          *ngIf="emailExists"
          class="feed_back"
          style="color: red"
        >
          هذاالأيميل لديه حساب أخر بالفعل
        </div>
      </div>
      <div class="form-field">
        <label>رقم الهاتف</label>
        <input
          class="form-control"
          type="text"
          name="phone"
          #phone="ngModel"
          [(ngModel)]="registerForm.phone"
          [ngModelOptions]="{ standalone: true }"
          required
          [ngClass]="{ 'has-danger': f.submitted && !registerForm.phone }"
        />
        <div
          *ngIf="f.submitted && !registerForm.phone"
          class="feed_back"
          style="color: red"
        >
          رقم الهاتف مطلوب
        </div>
        <div
          *ngIf="f.submitted && invalidMobile"
          class="feed_back"
          style="color: red"
        >
          رقم التليفون غير صحيح
        </div>
        <div
        *ngIf="f.submitted && phoneExist"
        class="form-control-feedback"
        style="color: red"
      >
      هذا الجوال لديه حساب أخر بالفعل
      </div>
      </div>
      <div class="form-field form-field-with-left-icon">
        <label for="password">كلمة المرور</label>
        <div
          toggle="#passowrd"
          class="toggle-password-visibility-icon icon-100"
          (mousedown)="showPassword('password')"
          (mouseup)="hidePassword('password')"
        ></div>
        <input
          id="password"
          class="form-control"
          type="password"
          name="password"
          #pass="ngModel"
          required
          [(ngModel)]="registerForm.password"
          [ngModelOptions]="{ standalone: true }"
          [ngClass]="{
            'has-danger':
              (f.submitted || pass.touched) && pass.hasError('required')
          }"
        />
        <div
          *ngIf="(f.submitted || pass.touched) && pass.hasError('required')"
          class="feed_back"
          style="color: red"
        >
          كلمه المرور مطلوبه
        </div>
      </div>
      <div class="form-field form-field-with-left-icon">
        <label>
          <label for="confirm_password">تأكيد كلمة المرور</label>
        </label>
        <div
        toggle="#confirmPassword"
        class="toggle-password-visibility-icon icon-100"
        (mousedown)="showPassword('confirmPassword')"
        (mouseup)="hidePassword('confirmPassword')"
         ></div>
        <input
          id="confirmPassword"
          class="form-control"
          type="password"
          name="confirmPassword"
          #confirmPass="ngModel"
          [(ngModel)]="registerForm.confirmPassword"
          [ngModelOptions]="{ standalone: true }"
          [ngClass]="{
            'has-danger':
              (f.submitted || confirmPass.touched) &&
              confirmPass.hasError('required')
          }"
        />
        <div
          *ngIf="
            (f.submitted || confirmPass.touched) &&
            confirmPass.hasError('required')
          "
          class="feed_back"
          style="color: red"
        >
          تاكيد كلمه المرور مطلوبه
        </div>
        <div
          *ngIf="notMatched"
          class="feed_back"
          style="color: red"
        >
          كلمه المرور لا تطابق
        </div>
      </div>
      <br />
      <!-- لست روبوتا -->
      <div class="app-capcha-field">
        <div class="col-10 captcha">
          <ngx-recaptcha2
            #captchaElem
            siteKey="6Lcqg84ZAAAAAChkjNc6RVHq10Q4Gb8qtejxQR-z"
            standalone
            name="key"
            (success)="handleSuccess($event)"
            (reset)="handleReset()"
          >
          </ngx-recaptcha2>
        </div>
      </div>
      <div
        *ngIf="(f.submitted || confirmPass.touched) && !includeCaptcha"
        class="feed_back"
        style="color: red; height: 0px;"
      >
        تاكد انك لست روبروت
      </div>
      <div
        *ngIf="(f.submitted || confirmPass.touched) && captchaFailed"
        class="feed_back"
        style="color: red; height: 0px;"
      >
        تاكد انك لست روبروت
      </div>
      <div
      *ngIf="(f.submitted || confirmPass.touched) && errorWithRegister"
      class="feed_back"
      style="color: red; height: 0;"
    >
      حدث خطا اثناء التسجيل
    </div>
      <div class="form-field form-field-submit">
        <button type="submit" class="no-border btn-green submit-btn" style="margin-top:2%;">
          تسجيل الدخول
        </button>
      </div>
    </form>
  </div>
  <div class="register-fellowship-registration-plans" *ngIf="activeStep === 1">
    <app-fellowship-table
      [withResolveActionOnTable]="true"
      (pressResolveAction)="getPressOnFellowshipTableResolveAction($event)"
    ></app-fellowship-table>
  </div>
  <!-- Register Payments form -->
  <div class="register-fellowship-payments" *ngIf="activeStep ===2">
    <div class="register-fellowship-payments-preview edittable">
      <div class="register-fellowship-payments-preview-heading">
        <div class="register-fellowship-payments-preview-heading-top">
          <span class="text-red">تطوير لخطة 2 لمحاضرات مباشرة</span>
          <span class="text-title text-extra-bold">الخطة</span>
        </div>
        <p class="text-description">
          الخطة الأولى الوصول لجميع المحاضرات المسجلة والاسئلة العشوائية
        </p>
      </div>
      <div class="coupon">
        <div class="row" dir="rtl">
          <div class="col-8 p_div">
            <div class="row">
              <p class="code">كود الخصم</p>
            </div>
          </div>
          <div class="col-8 input_div">
            <div class="row">
              <input #coupon type="text" class="code_input">
              <button (click)="couponValidation(coupon.value)"  type="button" class="code_input_validator">
                تطبيق
              </button>
            </div>
          </div>
          <div class="col-4 px-0 error_div">
            <div class="row">
              <p class="code_wrong" *ngIf="codeWrong">كود خطأ</p>
              <p class="code_wrong" *ngIf="wrongPlan"> كود لا يصلح للخطة المٌختارة</p>
              <p class="code_wrong" *ngIf="isCouponUsed">لا يمكن إستخدام أكثر من كوبون</p>
            </div>
          </div>
        </div>
      </div>
      <div class="register-fellowship-payments-preview-heading-body">
        <p class="text-title">
          <span class="text-extra-bold">المبلغ</span>
        </p>
        <ul class="register-fellowship-payments-preview-heading-body-items">
          <li>
            <p>
              <span>ريال</span>
              <span>{{ participationItem.text }}</span>
            </p>
            <p>قبل الخصم</p>
          </li>
          <li>
            <p>
              <span>ريال</span>
              <span>{{ participationItem.afterDiscount}}</span>
            </p>
            <p>بعد الخصم</p>
          </li>
          <li>
            <p>
              <span>ريال</span>
              <span>{{ participationItem.afterDiscount}}</span>
            </p>
            <p>الكلي</p>
          </li>
        </ul>
      </div>
    </div>
    <app-register-payment-form
      (afterSubmitPaymentInfo)="handlePaymentInfo($event)"
      [user]="user"
      [participationItem]="participationItem"
    ></app-register-payment-form>
  </div>
  <div class="register-complete-sign-up" *ngIf="activeStep === 3">
    <app-confirm-phone-number
      [verificationInfo]="[]"
      (submitVerification)="handleVerificationData($event)"
    ></app-confirm-phone-number>
  </div>
</div>
