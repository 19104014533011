import { Component, OnInit } from "@angular/core";

interface RegisterStep {
  isPassed?: boolean;
  name: string;
}

@Component({
  selector: "app-register-stepper",
  templateUrl: "./register-stepper.html",
  styleUrls: ["./register-stepper.sass"],
})
export class RegisterStepperComponent implements OnInit {
  steps: Array<RegisterStep>;

  ngOnInit() {
  let currentUser= localStorage.getItem("currentUser");
  if(currentUser){
    this.steps = [
      {
        name: "تفاصيل الدفع",
      },
      {
        name: "خطة الاشتراك",
        isPassed: true,
      }
    ];
  }else{
    this.steps = [
      {
        name: "تفاصيل الدفع",
      },
      {
        name: "خطة الاشتراك",
        isPassed: true,
      },
      {
        name: "بيانات الحساب",
      },
    ];
  }
  }
}
