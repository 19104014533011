<div class="lections-previous-tests">
    <div class="lections-previous-tests--content" [ngClass]="{'collapsed': lecturesService.rightNavState }">
        <div class="lections-previous-tests--content--section">

            <div class="lections-previous-tests">
                <div class="lections-previous-tests--looking-for-tests">

                </div>
                <div class="lections-previous-tests--display-previous-tests">
                    <div class="ui-grid row">
                        <div class="col-lg-6">
                            <div class="row justify-content-center">
                                <table>
                                    <tr>
                                        <td class="text-center">
                                            {{rightAnswerPercentage()}} %
                                            <img src="../../../../../assets/images/exams/green_circle.svg" alt="">
                                        </td>
                                        <td class="text-center">إجابة صحيحة</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">
                                            {{wrongAnswerPercentage()}}%
                                            <img src="../../../../../assets/images/exams/red_circle.svg" alt="">
                                        </td>
                                        <td class="text-center">إجابة خاطئة</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <circle-progress [percent]="rightAnswerPercentage()" [radius]="100" [space]="-10" [outerStrokeWidth]="16"
                                [innerStrokeWidth]="10" [outerStrokeColor]="'#47c260'" [innerStrokeColor]="'#CC088A'"
                                [animation]="false" [showInnerStroke]="false" [showImage]=true [animationDuration]="300"
                                [title]="'UI'" [outerStrokeGradient]=false [outerStrokeGradientStopColor]="'#47c260'"
                                [lazy]=true [showUnits]=false [animateTitle]=false [animationDuration]=1000
                                [clockwise]=false [startFromZero]=false></circle-progress>
                        </div>
                        <div class="ui-grid--heading col-lg-12">
                            <div class="row justify-content-between">
                                <div class="col-lg-4 direction">
                                    <p class="direction header_font text-right">
                                        الاسم
                                    </p>
                                </div>
                                <div class="col-lg-4">
                                    <div class="row">
                                        <div class="col-lg-3">
                                            <p class="direction header_font">
                                                الاسئلة الصحيحة
                                            </p>
                                        </div>
                                        <div class="col-lg-3">
                                            <p class="direction header_font">
                                                 الاسئلة الخاطئة
                                            </p>
                                        </div>
                                        <div class="col-lg-3">
                                            <p class="direction header_font">
                                                الأجزاء
                                            </p>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="ui-grid--body col-lg-12">
                            <div class="row flex_wrap">
                                <div *ngFor="let test of testsData" class="ui-grid--body-item col-lg-12">
                                    <div class="row flex_wrap justify-content-between">
                                        <div class="col-lg-4 direction">
                                            <p class="direction header_font text-right">
                                                {{test.name}}
                                            </p>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="row">
                                                <div class="col-lg-3">
                                                    <p class="direction header_font">
                                                        {{test.rightAnswers}}
                                                    </p>
                                                </div>
                                                <div class="col-lg-3">
                                                    <p class="direction header_font">
                                                        {{test.wrongAnswers}}
                                                    </p>
                                                </div>
                                                <div class="col-lg-3">
                                                    <p class="direction header_font body_cell_width">
                                                        {{returnNumberOfLectures(test.lectureId)}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <ul class="pagination-conten pagination-div">
                            <li class="pagination-control-left pagination-control pagination-content-left">
                                <div class="left-control-icon icon-100">
                                    <div class="arrow-icon icon-100"></div>
                                </div>
                            </li>
                            <li class="pagination-count-item">
                                <!-- *ngFor="let paginationPageCount of Questions; let ID = index;"
          [ngClass]="{'active': ID === activePaginationItem }" -->
                                <!-- <span>{{ID + 1}}</span> -->
                                <span>{{1}}</span>
                            </li>
                            <li class="pagination-control-right pagination-control pagination-content-right ">
                                <div class="right-control-icon icon-100">
                                    <div class="arrow-icon icon-100"></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>