<div class="form-field form-field-edit">
  <button class="btn-edit-icon" (click)="editField()"></button>
  <input
    type="text"
    (change)="onInput($event)"
    *ngIf="editMode"
    value="{{value}}"
  />
  <span *ngIf="!editMode">{{value}}</span>
</div>
