<div class="content">

  <nav class="navbar navbar-expand-lg navbar-light nav ">

    <div class=" navbar-collapse" id="navbarText">
      <div class="row navWidth">
        <div (click)="onBack()" style="cursor: pointer !important;" class="col center-div">
          <img src="assets/icons/preev.png" class=" marginLeft iconReturn c-pointer" />

          <span class="navbar-text editSpanFont marginLeft">
            العودة الى صفحة المادة
          </span>
        </div>

        <div class="col center-div ">
          <img (click)="prevLecture()" src="assets/icons/preev.png" class="nexprev prevosLeft c-pointer" />

          <span (click)="prevLecture()" class="navbar-text editSpanFont prevosLeft margRow c-pointer">
            السابق
          </span>

          <span (click)="nextLecture()" class="navbar-text editSpanFont next-margin c-pointer">
            التالى
          </span>


          <img (click)="nextLecture()" src="assets/icons/nextt.png" class="nexprev c-pointer" />



        </div>
        <div>
          <div class="col">

            <div class="edit-to-btn">
              <button class="right-area">
                <div>
                  <div class="book-icon img"></div>
                </div>
                <span class="spant">زمالتي</span>
              </button>
            </div>


          </div>
        </div>

      </div>
    </div>
  </nav>


  <div>


    <div class="row height90 max-w100 w-100 mr-0">

      <div class="col-12 col-lg-6 paddingLeft">
        <div class="margTop">

          <!-- <a (click)="downloadPdf()" class="btn-green btn-large  no-border btn ">
            <span class="color-span">تحميل PDF</span></a> -->
        </div>
        <div class="hegtDiv">
          <div class="contentTitle" style=" overflow: hidden;
          padding-top: 99%; /* 16:9*/
          position: relative;">
            <iframe *ngIf="urlSafe" style="border: 0;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;" frameBorder="0" [src]="urlSafe"></iframe>
          </div>
        </div>


      </div>

      <div class="col-lg-6 divColTwo notesWrapper" [ngClass]="{'col-lg-12': smScreen == true}">
        <!-- <div *ngIf="lecture?.videoLink && !isIos"> -->
        <div id="videoContainer">
          <vime-player #player [(currentTime)]="userLecture.lastSeenTime" (vPlaybackEnded)="videoEnd()" (vCurrentTimeChange)="onVideoChange($event)"
                       id="youtubLink" controls>
            <!-- <vime-youtube  [videoId]="youtubeLinkToId(lecture?.videoLink)"></vime-youtube> -->
            <vime-video>
              <!-- *ngIf="lecture?.videoLink && !isIos" -->
              <source [src]="lecture.videoLink" />
              <source [src]="lecture.videoLinki" />
              <!-- *ngIf="lecture?.videoLink && isIos" -->
            </vime-video>
          </vime-player>
          <!-- <vime-player #player id="uploadedLink" [(currentTime)]="userLecture.lastSeenTime" (vPlaybackEnded)="videoEnd()" controls
            *ngIf="lecture?.type=='upload'">
            <vime-video>
              <source [src]="lecture?.videoSrc.path" />
            </vime-video>
          </vime-player> -->
        </div>


        <!-- <div *ngIf="lecture?.videoLink && isIos" >
          <vg-player>
            <vg-controls>
              <vg-play-pause></vg-play-pause>
              <vg-playback-button></vg-playback-button>
        
              <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
        
              <vg-scrub-bar>
                  <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                  <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
              </vg-scrub-bar>
        
              <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
        
              <vg-mute></vg-mute>
              <vg-volume></vg-volume>
        
              <vg-fullscreen></vg-fullscreen>
          </vg-controls> -->
        <!-- <video  [vgMedia]="media" #media id="singleVideo" preload="auto" controls>
                <source src="http://static.videogular.com/assets/videos/videogular.mp4" type="video/mp4">
            </video> -->

        <!-- <video *ngIf="lecture?.type=='link'"  #media [vgMedia]="media" id="singleVideo" preload="auto" crossorigin>
                <source [src]="lecture.videoLink" type="video/mp4">
            </video>
          
            <video *ngIf="lecture?.type=='upload'" #media [vgMedia]="media" id="singleVideo" preload="auto" crossorigin>
                <source [src]="lecture?.videoSrc.path" type="video/mp4">
            </video>
         </vg-player> -->
        <!-- </div> -->


        <div class="heightDiv02">
          <div class="row justify-content-between row_reverse pb-2 ">
            <div class="col-lg-6">
              <span class="navbar-text lectionTitle text-right">
                {{lecture?.LectureName}}
              </span>
            </div>
            <div class="col-lg-6">
              <div class="row">
                <!-- <div class="col lec c-pointer w-max">
                  <span class="navbar-text fontSpan margTop" (click)="askLecturer()">
                    اسال المحاضر
                    <img class="icon_width" src="assets/icons/asking.png" />
                  </span>



                </div> -->

                <div *ngIf="authApi.isAllowExam()" class="col lec c-pointer w-max">
                  <span routerLink="/loading" [state]="{ route: 'exam',lectureId: lectureId }" class="navbar-text fontSpan  ">
                    <!-- (click)="openRecreateModal(lectureId)"  -->
                    <!-- [routerLink]="['/exam/lecture/'+lectureId]" -->
                    اختبار تدريبي
                  </span>
                  <img class="im-g icon_width" src="assets/icons/test.png" />

                </div>


              </div>
            </div>
          </div>
          <!-- <div class="divTitle">
          </div> -->
          <div>
          </div>

        </div>
        <div class="row overflow_y">


          <div class="col-lg-12 d_none_sm" *ngFor="let note of userLecture.Notes; let ID=index" [ngClass]="{'marg_t_40':ID == 0}">
            <div class="divRow">
              <img class="right" style=" top: 8px;" src="assets/icons/t.png">
              <span class="addTime c-pointer" (click)="addTime(ID)" style="margin-right: 1%">إضافة توقيت</span>

              <div class="divSubRow">
                <div>
                  <span class="note c-pointer" (click)="saveEditNotes(ID)">حفظ</span>
                  <img class="margRow" (click)="saveEditNotes(ID)" class="editmove" src="assets/icons/saving.png">
                </div>

                <div style="  margin-left: 5%;">

                  <span *ngIf="userLecture.Notes[ID]['note'] !=null" (click)="editNotes(ID)" class="note c-pointer">تعديل
                    ملاحظة</span>
                  <span *ngIf="userLecture.Notes[ID]['note'] == null" (click)="editNotes(ID)" class="note c-pointer">اضافة
                    ملاحظة</span>

                  <img (click)="editNotes(ID)" class="margRow" src="assets/icons/adding.png">

                </div>
              </div>
            </div>


            <div class="timeDiv">
              <span class="time">{{note.time !=null?note.time:""}}</span>
              <img *ngIf="note.time !=null" src="assets/icons/timer.png">

            </div>
            <div *ngIf="userLecture.Notes[ID].showNote" class="contentTitleTwo">
              <textarea cols="50" rows="3" [(ngModel)]="userLecture.Notes[ID]['note']"></textarea>
            </div>
            <div *ngIf="userLecture.Notes[ID].showNote != true" class="contentTitleTwo">
              <h2> {{note.note}} </h2>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>