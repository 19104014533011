import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";

export interface UpdateCheckboxState {
  id: any;
  newState: boolean;
}

@Component({
  selector: "app-checkbox",
  templateUrl: "./app-checkbox.html",
  styleUrls: ["./app-checkbox.sass"],
})
export class AppCheckboxComponent implements OnInit {
  @Input() label: string;
  @Input() id: any;
  @Output() emitToggle = new EventEmitter<UpdateCheckboxState>();
  checked: boolean;

  toggle() {
    this.checked = !this.checked;
    this.emitToggle.emit({
      id: this.id,
      newState: this.checked,
    });
  }

  ngOnInit() {
    this.checked = false;
  }
}
