import { CourseDetailsComponent } from './../modules/shared/components/courses/course-details/course-details';
import { LoadingComponent } from './../components/loading/loading';
import { AdminCoupons } from "./../modules/admin/components/admin-coupons/admin-coupons";
import { AdminProfileComponent } from "./../modules/home/components/admin-profile/admin-profile";
import { SubAndPaymentComponent } from "./../modules/home/components/home-analytics-component/home-analytics.component";
import { SubjectsComponent } from "./../modules/home/components/home-analytics-sub-and-payment-component/home-analytics-sub-and-payment.component";
import { ProfileComponent } from "./../modules/home/components/home-analytics-profile-component/home-analytics-profile.component";
import { CanActivatePromiseGuard } from "./guards/promise.guard";
import { ExamComponent } from "./../modules/exams/exam/exam";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Components.
import { HomeComponent } from "@modules/home/components/home-component/home.component";
import { AdminRootComponent } from "@modules/admin/components/admin-root-component/admin-root.component";
import { AppHomeAnalyticsRootComponent } from "@modules/home/components/home-analytics-root-component/home-analytics-root.component";
import { AdminSubscribtionControlComponent } from "@modules/admin/components/admin-subscribtion-control-component/admin-subscribtion-control.component";
import { AdminArticlesModifyComponent } from "@modules/admin/components/admin-articles-modify-component/admin-articles-modify.component";
import { AdminStudentsManageComponent } from "@modules/admin/components/admin-students-manage-component/admin-students-manage.component";
import { AdminMainControlComponent } from "@modules/admin/components/admin-main-control-component/admin-main-control.component";
import { AdminSummariesModifyComponent } from "@modules/admin/components/admin-summaries-modify-component/admin-summaries-modify.component";
import { AdminQuestionsBankComponent } from "@modules/admin/components/admin-questions-bank-component/admin-questions-bank.component";
import { AdminArticlesListComponent } from "@modules/admin/components/admin-articles-list/admin-articles-list.component";
// import { CanActivateAdminGuard } from "./guards/can-activate-admin.guard";
import { CanActivateHomeGuard } from "./guards/can-activate-home.guard";
import { AdminQuestionsBankLectureComponent } from "@modules/admin/components/admin-questions-bank-lecturer-component/admin-questions-bank-lecture.component";
import { CanActivateExamGuard } from "./guards/can-active-exam.guard";
import { CanActivateExamLectureGuard } from "./guards/can-active-exam-lecture.guard";

// Guards.

const HomeRoutes: Routes = [
  {
    path: "",
    component: HomeComponent,
  },
  {
    path: "analytics/:tabID",
    component: AppHomeAnalyticsRootComponent,
  },
  // {
    // path: "dashboard/admin",
    // component: AdminRootComponent,
    // canActivate: [CanActivateAdminGuard],
  // },
  {
    path: "profile/payment",
    component: SubAndPaymentComponent,
    canActivate: [CanActivateHomeGuard],
  },
  {
    path: "profile/me",
    component: ProfileComponent,
    canActivate: [CanActivateHomeGuard],
  },
  // {
    // path: "admin_profile",
    // component: AdminProfileComponent,
    // canActivate: [CanActivateAdminGuard],
  // },
  {
    path: "profile/subjects",
    component: SubjectsComponent,
    canActivate: [CanActivateHomeGuard, CanActivatePromiseGuard],
  },
  // {
    // path: "dashboard/admin/subscribtion-control",
    // component: AdminSubscribtionControlComponent,
    // canActivate: [CanActivateAdminGuard],
  // },
  // {
    // path: "dashboard/admin/coupons",
    // component: AdminCoupons,
    // canActivate: [CanActivateAdminGuard],
  // },
  // {
    // path: "dashboard/admin/articles-list",
    // component: AdminArticlesListComponent,
    // canActivate: [CanActivateAdminGuard],
  // },
  // {
    // path: "dashboard/admin/articles-modify/:id",
    // component: AdminArticlesModifyComponent,
    // canActivate: [CanActivateAdminGuard],
  // },
  // {
    // path: "dashboard/admin/articles-modify",
    // component: AdminArticlesModifyComponent,
    // canActivate: [CanActivateAdminGuard],
  // },
  // {
    // path: "dashboard/admin/manage-students",
    // component: AdminStudentsManageComponent,
    // canActivate: [CanActivateAdminGuard],
  // },
  // {
    // path: "dashboard/admin/main-control",
    // component: AdminMainControlComponent,
    // canActivate: [CanActivateAdminGuard],
  // },
  // {
    // path: "dashboard/admin/edit-summaries",
    // component: AdminSummariesModifyComponent,
    // canActivate: [CanActivateAdminGuard],
  // },
  // {
    // path: "dashboard/admin/questions-bank",
    // component: AdminQuestionsBankComponent,
    // canActivate: [CanActivateAdminGuard],
  // },
  // {
    // path: "dashboard/admin/questions-bank/:lectureId",
    // component: AdminQuestionsBankLectureComponent,
    // canActivate: [CanActivateAdminGuard],
  // },
  {
    path: "exam/lecture/:lectureId",
    component: ExamComponent,
  },
  {
    path: "exam/:examId",
    component: ExamComponent,
  },
  {
    path: "profile/course-details/:courseId",
    component: CourseDetailsComponent,
  },
  {
    path: "loading",
    component: LoadingComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(HomeRoutes),
    RouterModule.forChild(HomeRoutes),
  ],
  providers: [
  //   CanActivateAdminGuard,
    CanActivateHomeGuard,
    CanActivatePromiseGuard,
    CanActivateExamGuard,
    CanActivateExamLectureGuard,
  ],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
