import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import io from "socket.io-client";

@Injectable()
export class SocketService {
  socket: any;

  constructor() {
    this.connect();
  }

  connect() {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    console.log("usr", user);
    if (user) {
      this.socket = io(environment.socketUrl);
      this.socket.emit("join", { id: user.userId });

      this.socket.on("logoff", (data) => {
        localStorage.removeItem("currentUser");
        location.reload();
      });
    }
  }

  disconnect() {
    this.socket.disconnect();
  }
}
