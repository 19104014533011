<div class="auth">
  <!-- Sign In form -->
  <form class="auth-form" #f="ngForm" *ngIf="!phoneVerificationStepActive">
    <p class="auth-form-title">تسجيل الدخول</p>
    <div class="auth-form-content">
      <div class="form-field auth-form-field">
        <label class="form-control-label">الأيميل</label>
        <input
          class="form-control"
          type="text"
          [(ngModel)]="authForm.email"
          [ngModelOptions]="{ standalone: true }"
        />
      </div>
      <div class="form-field auth-form-field form-field-with-left-icon">
        <label class="form-control-label">كلمة المرور</label>
        <div
        toggle="#passowrd"
        class="toggle-password-visibility-icon icon-100"
        (mousedown)="showPassword()"
        (mouseup)="hidePassword()"
      ></div>
        <a routerLink="./forgot_password">نسيت كلمة المرور؟</a>
        <input
          id="password"
          class="form-control"
          type="password"
          [(ngModel)]="authForm.password"
          [ngModelOptions]="{ standalone: true }"
        />
      </div>
    </div>
    <div
    *ngIf="f.submitted && errorWithlogin"
    class="feed_back"
    style="color: red"
  >
    تاكد ان الايميل او كلمه المرور صحيح
  </div>
    <div class="form-field form-field-submit">
      <button
        class="btn btn-green submit-btn no-border"
        (click)="submitStep(0)"
      >
        تسجيل الدخول
      </button>
    </div>
  </form>
  <div *ngIf="phoneVerificationStepActive" class="auth-complete-sign-in">
    <app-confirm-phone-number
      [verificationInfo]="[]"
      (submitVerification)="handleVerificationData($event)"
    ></app-confirm-phone-number>
  </div>
</div>
