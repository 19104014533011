import { AdminCoupons } from "./components/admin-coupons/admin-coupons";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "@modules/shared/shared.module";

import { AdminRootComponent } from "./components/admin-root-component/admin-root.component";
import { AdminArticlesModifyComponent } from "./components/admin-articles-modify-component/admin-articles-modify.component";
import { AdminSubscribtionControlComponent } from "./components/admin-subscribtion-control-component/admin-subscribtion-control.component";
import { AdminStudentsManageComponent } from "./components/admin-students-manage-component/admin-students-manage.component";
import { AdminMainControlComponent } from "./components/admin-main-control-component/admin-main-control.component";
import { AdminSummariesModifyComponent } from "./components/admin-summaries-modify-component/admin-summaries-modify.component";
import { AdminQuestionsBankComponent } from "./components/admin-questions-bank-component/admin-questions-bank.component";
import { AdminContinueSubscribeComponent } from "./components/admin-continue-subscribe-component/admin-continue-subscribe.component";
import { AdminUpdateSubscribeComponent } from "./components/admin-update-subscribe-component/admin-update-subscribe.component";
import { AdminQuestionConfirmComponent } from "./components/admin-question-confirm-component/admin-question-confirm.component";
import { AdminArticlesListComponent } from "./components/admin-articles-list/admin-articles-list.component";
import { HttpClientModule } from "@angular/common/http";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { VimeModule } from "@vime/angular/dist";
import { SendResponseComponent } from "./components/admin-send-response/admin-send-response";
import { AdminContinueBlockComponent } from "./components/admin-continue-block-component/admin-continue-block.component";
import { AdminsendMessageComponent } from "./components/admin-continue-send-message-component/admin-continue-send-messagecomponent";
import { AdminQuestionsBankLectureComponent } from "./components/admin-questions-bank-lecturer-component/admin-questions-bank-lecture.component";
import { CKEditorModule } from "ckeditor4-angular";
import { AdminMainControlSaveComponent } from "./components/admin-main-control-component/admin-main-control-save-component/admin-main-control-save-component";
import { AdminContinueDeleteComponent } from "./components/admin-continue-delete-component/admin-continue-deletecomponent";

@NgModule({
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    AngularEditorModule,
    ReactiveFormsModule,
    VimeModule,
    CKEditorModule,
  ],
  declarations: [
    AdminRootComponent,
    AdminArticlesModifyComponent,
    AdminSubscribtionControlComponent,
    AdminStudentsManageComponent,
    AdminMainControlComponent,
    AdminSummariesModifyComponent,
    AdminQuestionsBankComponent,
    AdminQuestionsBankLectureComponent,
    AdminContinueSubscribeComponent,
    AdminMainControlSaveComponent,
    AdminContinueBlockComponent,
    AdminContinueDeleteComponent,
    AdminUpdateSubscribeComponent,
    AdminQuestionConfirmComponent,
    AdminArticlesListComponent,
    SendResponseComponent,
    AdminsendMessageComponent,
    AdminCoupons,
  ],
})
export class AdminModule {}
