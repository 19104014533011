import { Component, OnInit } from "@angular/core";
import { CoursesService } from "@services/apis/courses.service";
import { SettingsService } from "@services/apis/setting.service";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.sass"],
})
export class FooterComponent implements OnInit {
  setting: any = {};
  courses: any;
  currentUrl: string = "/";
  constructor(
    private settingApi: SettingsService,
    private courseApi: CoursesService,
    private router: Router
  ) { }
  async ngOnInit() {
    let setting: any = await this.settingApi.query({}).toPromise();
    this.setting = setting.result[0];
    // let courses: any = await this.courseApi.query({}).toPromise();
    // this.courses = courses.result;

    this.router.events.subscribe((next: NavigationEnd) => {
      if (next.url && next.url !== this.currentUrl) {
        this.currentUrl = next.url;
      }
    });
  }
}
