import { ActivatedRoute } from '@angular/router';
import { ExamsService } from "./../../../../services/apis/exam.service";
import { Component, OnInit, Input } from "@angular/core";
import { LecturesService } from "./../../../../services/apis/lectures.service";

@Component({
  selector: "app-performance",
  templateUrl: "performance.html",
  styleUrls: ["./performance.sass"],
})
export class PerformanceComponent implements OnInit {
  @Input() isRightNavOpened: boolean;
  testsData: Array<any>;
  courseId: string;

  constructor(
    public lecturesService: LecturesService,
    private userExamApi: ExamsService,
    private activatedRoute: ActivatedRoute
  ) {}
  async ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params.courseId && params.courseId != undefined && params.courseId != 'undefined') {
        this.courseId = params.courseId;
      }
      else {
        this.activatedRoute.firstChild.params.subscribe(param => {
          if (param.courseId && param.courseId != undefined && param.courseId != 'undefined') {
            this.courseId = param.courseId;
          }
        })
      }
    })
    const currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    let userId = null;
    if (currentUser) {
      userId = currentUser.userId;
    }
    let exams: any = await this.userExamApi
      .query({
        where: {
          courseId: this.courseId,
          userId: userId,
          isCompleted: true,
        },
      })
      .toPromise();
    this.testsData = exams.result;
  }

  returnNumberOfLectures(lec) {
    let isArrayOfLectures = Array.isArray(lec);
    if (isArrayOfLectures == true) {
      return lec.length;
    }
    return 1;
  }

  rightAnswerPercentage() {
    let rightAnswers = 0;
    let allAnswers = 0;
    if (this.testsData) {
      this.testsData.forEach((test) => {
        rightAnswers = rightAnswers + test.rightAnswers;
        allAnswers = allAnswers + Number(test.questionNumbers);
      });
      let percentage = (rightAnswers * 100) / allAnswers;
      return isFinite(Number(percentage.toPrecision(3)))?percentage.toPrecision(3):0;
    }
  }

  wrongAnswerPercentage() {
    let wrongAnswers = 0;
    let allAnswers = 0;
    if (this.testsData) {
      this.testsData.forEach((test) => {
        wrongAnswers = wrongAnswers + test.wrongAnswers;
        allAnswers = allAnswers + Number(test.questionNumbers);
      });
      let percentage = (wrongAnswers * 100) / allAnswers;
      return isFinite(Number(percentage.toPrecision(3)))?percentage.toPrecision(3):0;
    }
  }
}
