import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap, shareReplay } from "rxjs/operators";
import { environment } from "../../../environments/environment";

import { BaseApiService } from "../baseAPI";
import { User } from "@modules/_models/User";
// import { SocialUser } from "angularx-social-login";

const API_USERS_URL = environment.baseUrl;

const cache = new Map();
@Injectable()
export class UsersService extends BaseApiService<any> {
  constructor(http: HttpClient) {
    super(http);
    this.url = "/profiles";
  }

  // deleteUser(userId: string) {
  //   const user = { id: userId, isDeleted: true };
  //   return this.save(user);
  // }

  // updateUser(user: User): Observable<any> {
  //     return this.http.patch(API_USERS_URL + `${this.url}`, user, { headers: this.authorization() });
  // }

  createUser(user: User): Observable<User> {
    return this.http.post<User>(API_USERS_URL + `${this.url}/register`, {
      data: user,
    });
  }
  addStudent(user: User): Observable<User> {
    return this.http.post<User>(
      API_USERS_URL + `${this.url}/addstudent`,
      { data: user },
      { headers: this.authorization() }
    );
  }
  updateUser(data: any): Observable<User> {
    return this.http.post<User>(
      API_USERS_URL + `${this.url}/editprofile`,
      {
        data: data,
      },
      { headers: this.authorization() }
    );
  }
  promise(data: boolean, userId): Observable<User> {
    return this.http.post<User>(API_USERS_URL + `${this.url}/promise`, {
      data: data,
      userId: userId,
    });
  }
  // socialRegister(data: SocialUser): Observable<SocialUser> {
  //   return this.http.post<any>(API_USERS_URL + "/users/socialRegister", { data }, { headers: this.authorization() });
  // }
  changeEmail(userId, email) {
    return this.http.post<any>(
      API_USERS_URL + this.url + "/change-email",
      { email, userId },
      { headers: this.authorization() }
    );
  }
  deleteUser(userId){
    return this.http.post<any>(
      API_USERS_URL + this.url + "/deleteUser",
      { userId },
      { headers: this.authorization() }
    );
  }
}
