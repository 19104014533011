import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseApiService } from "../baseAPI";

@Injectable()
export class StudentLecturesService extends BaseApiService<any> {
    rightNavState: boolean;

    constructor(http: HttpClient) {
        super(http);
        this.url = "/studentLectures";
    }

    updateRightNavState(state: boolean) {
        this.rightNavState = state;
    }
}
