import { Component } from "@angular/core";

// todo
// Update interface.
interface ProjectSubscribe {
  name?: string;
  writedLections?: boolean;
  tests?: boolean;
  multipleTests?: boolean;
  onlineCourse?: boolean;
  price?: string;
  timing?: string;
}

@Component({
  selector: "app-project-control-your-subscribe",
  templateUrl: "project-control-your-subscribe.html",
  styleUrls: ["./project-control-your-subscribe.sass"],
})
export class ProjectControlYourSubscribeComponent {
  Subscribe: ProjectSubscribe;
  formControlOptionPlaceholders: any;

  addOne() {}

  selectControlOption(fieldKey: string, fieldValue: boolean) {
    this.Subscribe[fieldKey] = fieldValue;
  }

  constructor() {
    this.formControlOptionPlaceholders = {
      turnOn: "تمكين",
      kickOff: "عدم التمكين",
    };
    this.Subscribe = {};
  }
}
