import { Router } from '@angular/router';
import { CoursesService } from './../../../../services/apis/courses.service';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'courses-table',
    templateUrl: './courses-table.component.html',
    styleUrls: ['./courses-table.component.scss']
})
export class CoursesTableComponent implements OnInit {
    courses: any[] = [];
    constructor(
        private coursesApi: CoursesService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.coursesApi.getCourses({ where: {status: true, isDeleted: {neq: true}} }).subscribe(res => {
            this.courses = res['result']

        })
    }

    onOpenCourse(courseId: string) {
        const route = 'profile/course-details/' + courseId;
        const userDate: any = JSON.parse(localStorage.getItem("currentUser"));
        // if (!userDate) {
        //     localStorage.setItem('courseRoute', route);
        //     localStorage.setItem('currentCourseId', courseId);
        //     this.router.navigate(['auth']);
        //     return;
        // }
        this.router.navigate([route]);

    }
}
