import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-exams-test-esse",
  templateUrl: "./exams-test-esse.html",
  styleUrls: ["./exams-test-esse.sass"],
})
export class ExamsTestEsseComponent implements OnInit {
  ngOnInit() {}
}
