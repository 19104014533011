import { environment } from './../../../environments/environment';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseApiService } from "../baseAPI";
const API_USERS_URL = environment.baseUrl;

@Injectable()
export class LectureFilesService extends BaseApiService<any> {
    constructor(http: HttpClient) {
        super(http);
        this.url = "/lectureFiles";
    }

    getFiles(lectureId: string) {
        return this.http.post<any>(`${this.baseUrl}${this.url}/getFiles`,
            { lectureId },
            {
                headers: this.authorization()
            })
    }
}
