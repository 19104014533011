import { CreatePassword } from "./../modules/auth/components/auth-component/create-password/create-password";
import { ForgotPasswordComponent } from "./../modules/auth/components/auth-component/forgot-password/forgot-password";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CodeConfirmComponent } from "@modules/auth/components/codeConfirm-component/codeConfirm.component";

// Components.
import { AuthComponent } from "@modules/auth/components/auth-component/auth.component";
import { RegisterComponent } from "@modules/auth/components/register-component/register.component";
import { PaymentErrorComponent } from "@modules/auth/components/PaymentError/paymentError.component";

const AuthRoutes: Routes = [
  {
    path: "auth",
    component: AuthComponent,
  },
  {
    path: "sign-up",
    component: RegisterComponent,
  },
  {
    path: "payment-error",
    component: PaymentErrorComponent,
  },
  {
    path: "confirmCode",
    component: CodeConfirmComponent,
  },
  {
    path: "auth/forgot_password",
    component: ForgotPasswordComponent,
  },
  {
    path: "auth/reset-password",
    component: CreatePassword,
  },
];

@NgModule({
  imports: [RouterModule.forChild(AuthRoutes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
