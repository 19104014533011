import { LectureFilesService } from './services/apis/lecture-files.service';
import { StudentLecturesService } from './services/apis/student-lectures.service';
import { AdminCoupons } from "./modules/admin/components/admin-coupons/admin-coupons";
import { CouponsService } from "./services/apis/coupon.service";
import { NotificationSocketService } from "./services/notifications-socket.service";
import { TimerService } from "./modules/exams/exam/timer.service";
import { ExamsService } from "./services/apis/exam.service";
import { AnswersService } from "./services/apis/answers.service";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { AppRoutingModule } from "@routing/app-routing.module";
import { AuthModule } from "@modules/auth/auth.module";
import { HomeModule } from "@modules/home/home.module";
import { AdminModule } from "@modules/admin/admin.module";
import { ProjectModule } from "@modules/project/project.module";
import { ExamsModule } from "@modules/exams/exams.module";
import { LectionsModule } from "@modules/lections/lections.module";
import { SharedModule } from "@modules/shared/shared.module";

import { AppComponent } from "./app.component";
import { UsersService } from "@services/apis/users.service";
import { AuthenticationService } from "@services/apis/authentication.service";
import { PlansService } from "@services/apis/plans.service";
import { UploadService } from "./services/apis/upload.service";
import { ScriptService } from "ngx-captcha";
import { TransactionsService } from "@services/apis/transaction.service";
import { CoursesService } from "@services/apis/courses.service";
import { SettingsService } from "@services/apis/setting.service";
import { SummariesService } from "@services/apis/summaries.service";
import { LecturesService } from "@services/apis/lectures.service";
import { MessagesService } from "@services/apis/messages.service";
import { QuestionssService } from "@services/apis/questions.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SummariesComponent } from "./components/summaries/summaries.component";
import { MyquestionComponent } from "./components/myquestion/myquestion.component";
import { MynotesComponent } from "./components/mynotes/mynotes.component";
import { MynotestoComponent } from "./components/mynotesto/mynotesto.component";
import { VimeModule } from "@vime/angular/dist";
import { UserLecturesService } from "@services/apis/userLectures.service";
import { NotificationsService } from "@services/apis/notifications.service";
import { NgCircleProgressModule } from "ng-circle-progress";
import { SocketService } from "@services/socket.service";

function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// Setup available of routerLink directive.
@NgModule({
  declarations: [
    AppComponent,
    SummariesComponent,
    MyquestionComponent,
    MynotesComponent,
    MynotestoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    HomeModule,
    AdminModule,
    ProjectModule,
    LectionsModule,
    ExamsModule,
    SharedModule,
    VimeModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    NgbModule,
    NgCircleProgressModule.forRoot({
      radius: 60,
      space: -10,
      outerStrokeGradient: true,
      outerStrokeWidth: 10,
      outerStrokeColor: "#4882c2",
      outerStrokeGradientStopColor: "#53a9ff",
      innerStrokeColor: "#e7e8ea",
      innerStrokeWidth: 10,
      title: "UI",
      animateTitle: false,
      animationDuration: 1000,
      showUnits: false,
      showBackground: false,
      clockwise: false,
      startFromZero: false,
      lazy: true,
    }),
  ],
  providers: [
    UsersService,
    AuthenticationService,
    TransactionsService,
    ScriptService,
    PlansService,
    CoursesService,
    SettingsService,
    SummariesService,
    LecturesService,
    MessagesService,
    QuestionssService,
    UploadService,
    AnswersService,
    ExamsService,
    UserLecturesService,
    NotificationsService,
    SocketService,
    TimerService,
    NotificationSocketService,
    CouponsService,
    StudentLecturesService,
    LectureFilesService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    // window.document.body.clientWidth
    // Screen width.
  }
}
