import { ExamsService } from "./../../../../../services/apis/exam.service";
import { Router } from "@angular/router";
import { MessagesService } from "../../../../../services/apis/messages.service";
import { Component, OnInit, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-recreate-test",
  templateUrl: "recreate-test.html",
  styleUrls: ["./recreate-test.sass"],
})
export class RecreateTestComponent implements OnInit {
  @Input() public lectureId: string;
  userId: string;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private examsApi: ExamsService
  ) {}
  ngOnInit() {
    const currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    this.userId = currentUser.userId;
  }

  recreateTest() {
    this.examsApi
      .creatExamFromLectureId(this.lectureId, true)
      .subscribe(
        (res) => {
        this.router.navigateByUrl("/exam/" + res);
        this.modalService.dismissAll();
          
        },
        (err) => {
          if (err.status === 403) {
            localStorage.removeItem("currentUser");
            sessionStorage.removeItem("currentUser");
            this.router.navigateByUrl("/").then();
            window.location.reload();
          }
      });
  }

  close() {
    this.router.navigateByUrl("/exam/lecture/" + this.lectureId);
    this.modalService.dismissAll();
  }
}
