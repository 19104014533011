<div class="auth">
    <form class="auth-form">
      <p class="auth-form-title"> تغيير كلمة السر</p>
      <div class="auth-form-content">
        <div class="form-field auth-form-field">
          <label class="form-control-label" for="password">كلمة السر</label>
          <input id="password"
            class="form-control"
            type="password" name="pass" #pass="ngModel" required
            [(ngModel)]="password" pattern="(?=^.{6,}$)((?=.*\d)(?=.*\W+)(?![.\n])(?=.*[A-Z])(?=.*[a-z])).*$"  minlength="6"
          />
          <span toggle="#password" class="fa fa-fw fa-eye field-icon toggle-password"
                      (mousedown)="showPassword('password')" (mouseup)="hidePassword('password')"></span>
      </div>
        <div class="form-field auth-form-field">
          <label class="form-control-label" for="repassword"> أعد كتابة كلمة السر</label>
          <input id="repassword"
            class="form-control"
            type="password" name="repass" #repass="ngModel" required
            [(ngModel)]="repassword" 
          />
          <span toggle="#password" class="fa fa-fw fa-eye field-icon toggle-password"
          (mousedown)="showPassword('repassword')" (mouseup)="hidePassword('repassword')"></span>
      </div>

      <div class="form-field auth-form-field">
        <div class="col-12 errorHeight">
            <label class="red text-left"
            *ngIf=" pass.touched && (pass.hasError('pattern') || pass.hasError('minlength'))"
            id="pass_validator">
            Password must contain at least 1 lower case letter, 1 upper case letter, 1 number & 1 special
            symbol and must not be less than 6 characters</label>
            <label class="red" *ngIf="noMatch">لا يوجد تطابق </label>
          </div>
      </div>
      </div>
      <div class="form-field form-field-submit">
        <button
          class="btn btn-green submit-btn no-border"
          [disabled]="pass.hasError('pattern') || pass.hasError('minlength')" type="button" 
          (click)="Submit()">
           تأكيد
        </button>
      </div>
    </form>
  </div>
  