import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-exams-test-random",
  templateUrl: "./exams-test-random.html",
  styleUrls: ["./exams-test-random.sass"],
})
export class ExamsTestRandomComponent implements OnInit {
  ngOnInit() {}
}
