<div class="lections-previous-tests--display-previous-tests">
    <div class="ui-grid row justify-content-center">
        <div class="col-lg-12 mb-5">
            <div class="row reverse justify-content-between">

                <img (click)="close()" class="width20" src="../../../../../../assets/images/exams/ionic-ios-close.svg" alt="">
            </div>
        </div>
        <div class="col-lg-12">
            <div class="row reverse justify-content-center div-padding">
                <h4>
                    هل تريد إعادة الإختبار؟
                </h4>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="row reverse justify-content-between">
                <div class="col-lg-5"></div>
                <div class="col-lg-6 d-flex justify-content-end">
                    <button (click)="recreateTest()" class="bg_green complete font15 lower_btn">
                        نعم
                    </button>
                    <button (click)="close()" class="bg_green complete font15 lower_btn brown_bg">
                        لا
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>