import { Component, Input, Output, EventEmitter } from "@angular/core";
import { AuthenticationService } from "@services/apis/authentication.service";
import { UsersService } from "@services/apis/users.service";

@Component({
  selector: "app-confirm-phone-number",
  templateUrl: "./confirm-phone-number.html",
  styleUrls: ["./confirm-phone-number.component.sass"],
})
export class ConfirmPhoneNumberComponent {
  @Input() verificationInfo: Array<string>;
  @Output()
  submitVerification: EventEmitter<string> = new EventEmitter<string>();
  disabled: boolean = true;
  confirmationBtn: string = "تأكيد";
  constructor(
    private authApi: AuthenticationService,
    private userApi: UsersService
  ) {}
  submit(code: string): void {
    this.submitVerification.emit(code);
  }
  async resendEmailCode() {
    let email;
    const currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser) {
      let profile = await this.userApi.get(currentUser.userId).toPromise();
      if (profile != null) email = profile.email;
    }
    this.authApi.resendEmailCode(email).subscribe();
  }

  onCodeChanged(code: string) {
    if (code.length != 5) {
      this.disabled = true;
      this.confirmationBtn = "تأكيد";
    } else {
      this.disabled = false;
      this.confirmationBtn = "كود خطأ";
    }
  }

  clearCode() {
    Array.from(document.getElementById("code").children).forEach((span) => {
      span.children[0]["value"] = "";
    });
  }
}
