import { Component, Output, Input, EventEmitter, OnInit } from "@angular/core";
import { BaseApiService } from "@services/baseAPI";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";

@Component({
  selector: "app-video-uploader",
  templateUrl: "./video-uploader.html",
  styleUrls: ["./video-uploader.sass"],
})
export class VideoUploaderComponent implements OnInit {
  @Input() placeholder: string;
  @Input() placeholderColor: string;
  @Output() changeVideoSrc: EventEmitter<string> = new EventEmitter();
  videoSrc: string;
  @Input() type: string;
  @Input() index: any;
  @Input() baseApi: any;
  fileSrc: any = {};

  ngOnInit() {
    this.videoSrc = "./some";
  }

  async onVideoSrcChange(event: any) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      let fileDate = await this.baseApi
        .upload(file)
        .pipe(
          catchError((error) => {
            console.error(error);
            this.fileSrc.isLoaded = false;
            this.fileSrc.index = this.index;
            this.fileSrc.type = this.type;
            this.changeVideoSrc.emit(this.fileSrc);
            return of(null);
          })
        )
        .toPromise();
      if (fileDate) {
        this.fileSrc = fileDate;
        this.fileSrc.isLoaded = true;
        this.fileSrc.index = this.index;
        this.fileSrc.type = this.type;
        this.changeVideoSrc.emit(this.fileSrc);
      }
    }
  }
}
