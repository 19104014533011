import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "@services/apis/authentication.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.sass"],
})
export class HomeComponent implements OnInit {
  userId: any;
  user: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService) {}
 async ngOnInit() {
  }
}
