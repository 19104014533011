<div class="row exam" (selectstart)="onTextSelect($event)" (contextmenu)="onRightClick($event)">
    <div class="col-lg-12 div-display">
        <div class="col-lg-2 go_back ">
            <button *ngIf="isCompleted == false" class="exam_submit" (click)="submitExam()">
                تسليم الاجابات
            </button>
            <button *ngIf="isCompleted == true && courseId" [routerLink]="['/lections/'+courseId+'/previous-tests']">
                عودة
            </button>
        </div>
        <app-question-numbers *ngIf="exam" class="questionsComponentClass" [isCompleted]="isCompleted" [exam]="examForNumbersComponent"
                              [answersForBorder]="answersForBorder" [questions]="questions" [displayedQuest]="displayedQuest" [userId]="userId"
                              [examId]="examId" [newAnswer]="newAnswer" (questionNumber)="changeQuestFromNumbersComp($event)">
        </app-question-numbers>
    </div>




    <div class="col-lg-2 exam_navigation margtn-div">
        <div class="navigation">
            <div class="navigation_btn navigation_btn_bottom_border" (click)="changeQuestion('next')">
                <img src="../../../../assets/images/exams/altaly.svg" alt="">
                <p>التالي</p>
            </div>
            <div class="navigation_btn" (click)="changeQuestion('prev')" [ngClass]="{'navigation_btn_bottom_border': isFileProvided()}">
                <img src="../../../../assets/images/exams/alsabeq.svg" alt="">
                <p>السابق</p>
            </div>
            <div *ngIf="isFileProvided()" class="navigation_btn" (click)="downloadTable()">
                <img src="../../../../assets/icons/download.svg" />
                <p class="text-center">جدول البيانات</p>
            </div>
        </div>
    </div>


    <app-question *ngIf="exam" class="col-lg-12 exam_questions margtn-div" [displayedQuest]="displayedQuest" [exam]="exam "
                  [isCompleted]="isCompleted" [answered]="answered" (ArticleQuestionsNumber)="articleCounter($event)"
                  (questionsArray)="questionsArray($event)" (isAnswered)="isAnswered($event)" (isRightAnswerEvent)="isRightAnswerEvent($event)"
                  (showAnswerEvent)="showAnswerEvent($event)" (examUpdates)="examUpdates($event)" (answerForBorder)="answerForBorder($event)"
                  (rightAnswersNumber)="rightAnswersNumber($event)">
    </app-question>




    <div class="col-lg-11 answer" *ngIf="answered || isCompleted == true">
        <div class="row justify-content-center">
            <div class="col-lg-5 d-flex justify-content-center">
                <img src="../../../../assets/images/exams/student_and_board.svg" alt="" *ngIf="isRightAnswer == true">
                <img src="../../../../assets/images/exams/wrong_answer.svg" alt="" *ngIf="isRightAnswer == false">
            </div>
            <div class="col-lg-8 right_answer">
                <div class="row" *ngIf="rightAnswerIsArray(questions[displayedQuest]?.rightAnswer) == true">
                    <p class="col-lg-12 direction_right"
                       *ngFor="let line of rightAnswerSplitting(questions[displayedQuest]?.rightAnswer); let id = index">
                        {{ line }}
                    </p>
                </div>
                <div class="row" *ngIf="rightAnswerIsArray(questions[displayedQuest]?.rightAnswer) == false">
                    <p class="col-lg-12 direction_right" *ngIf="questions[displayedQuest]?.rightAnswer">
                        {{ questions[displayedQuest]?.rightAnswer }}
                    </p>
                    <p class="col-lg-12 direction_right" *ngIf="questions[displayedQuest] && !questions[displayedQuest]?.rightAnswer">
                        {{ getRightAnswerFromChoices().text }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>