import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap, shareReplay } from "rxjs/operators";
import { environment } from "../../../environments/environment";

import { BaseApiService } from "../baseAPI";
import { User } from "@modules/_models/User";
// import { SocialUser } from "angularx-social-login";

const API_USERS_URL = environment.baseUrl;

const cache = new Map();
@Injectable()
export class TransactionsService extends BaseApiService<any>{

    constructor(http: HttpClient) {
        super(http);
        this.url = "/transactions";
    }
    pay(user,participationItem) {
        return this.http.post<any>(API_USERS_URL + this.url + "/pay", {data:user,participationItem:participationItem}, { headers: this.authorization() });
    }
    payPal(user,participationItem,paymentId,payerId) {
        return this.http.post<any>(API_USERS_URL + this.url + "/payPal", {data:user,participationItem:participationItem,paymentId:paymentId,payerId:payerId}, { headers: this.authorization() });
    }
    saveTransaction(data) {
        return this.http.post<any>(API_USERS_URL + this.url + "/saveTransaction", {data:data}, { headers: this.authorization() });
    }
    
}
