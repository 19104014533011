import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { SharedModule } from "@modules/shared/shared.module";
import { ProjectRoutingModule } from "@routing/project-routing.module";

import { ProjectBasePageComponent } from "./components/project-base-page-component/project-base-page.component";
import { ProjectControlYourSubscribeComponent } from "./components/project-control-your-subscribe/project-control-your-subscribe.component";
import { ProjectUpdateArticlesComponent } from "./components/project-update-articles/project-update-articles.component";
import { ProjectQuestionsBankComponent } from "./components/project-questions-bank-component/project-questions-bank.component";

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    SharedModule,
    ProjectRoutingModule,
  ],
  declarations: [
    ProjectBasePageComponent,
    ProjectControlYourSubscribeComponent,
    ProjectUpdateArticlesComponent,
    ProjectQuestionsBankComponent,
  ],
})
export class ProjectModule {}
