<div class="lections-previous-tests--display-previous-tests">
    <div class="ui-grid row justify-content-center">
        <div class="col-lg-12 mb-5">
            <div class="row reverse justify-content-between">
                <h1 class="col-lg-8 text-right">
                    إسأل المحاضر

                </h1>
                <img (click)="close()" class="width20" src="../../../../../../assets/images/exams/ionic-ios-close.svg" alt="">
            </div>
        </div>
        <div class="col-lg-12">
            <div class="row reverse justify-content-center ">
                <p class="col-lg-12 text-right font15 padding_right">
                    اكتب سؤالك هنا
                </p>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="row reverse justify-content-center div-padding">
                <textarea [maxLength]="'250'" [(ngModel)]="message" class="text-border" name="message"  cols="150" rows="10" required></textarea>
            </div>
        </div>
        <div class="col-lg-12 mb-5">
            <div class="row reverse justify-content-center">
                <p class="col-lg-12 text-right font15 padding_right grey">
                    اكتب سؤال مكون من 250 كلمة لا اكثر
                    <img class="width20" src="../../../../../../assets/images/exams/awesome-question-circle.svg" alt="">
                </p>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="row reverse justify-content-between">
                <div class="col-lg-5"></div>
                <div class="col-lg-6 d-flex justify-content-end">
                    <button (click)="submit()" class="bg_green complete font15 lower_btn">
                        إستكمال
                    </button>
                    <button (click)="close()" class="bg_green complete font15 lower_btn brown_bg">
                        عودة
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>