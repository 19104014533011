export const block3ItemCards = [
  {
    title: "landing.block3.block3-card-1-title",
    image: "assets/icons/block-3-icon1.png",
    content: "landing.block3.block3-card-1-description",
  },
  {
    title: "landing.block3.block3-card-2-title",
    image: "assets/icons/block-3-icon2.png",
    content: "landing.block3.block3-card-2-description",
  },
  {
    title: "landing.block3.block3-card-3-title",
    image: "assets/icons/block-3-icon3.png",
    content: "landing.block3.block3-card-3-description",
  },
];

export const block4FellowshipItems = [
  {
    title: "المكثفة",
    items: [
      {
        type: "icon",
        iconSrc: "assets/icons/done-icon.png",
      },
      {
        type: "icon",
        iconSrc: "assets/icons/done-icon.png",
      },
      {
        type: "icon",
        iconSrc: "assets/icons/done-icon.png",
      },
      {
        type: "text",
        text: "ريال 8000",
      },
      {
        type: "text",
        text: "سنة واحدة",
      },
      {
        type: "btn",
        title: "أشتراك",
      },
    ],
  },
  {
    title: "العادية",
    items: [
      {
        type: "icon",
        iconSrc: "assets/icons/done-icon.png",
      },
      {
        type: "icon",
        iconSrc: "assets/icons/done-icon.png",
      },
      {
        type: "icon",
        iconSrc: "assets/icons/done-icon.png",
      },
      {
        type: "text",
        text: "ريال 4000",
      },
      {
        type: "text",
        text: "سنة واحدة",
      },
      {
        type: "btn",
        title: "أشتراك",
      },
    ],
  },
  {
    title: "هناك نوعين من الخطط للاشتراك في موقع زمالتي",
    items: [
      {
        type: "text",
        text: "محاضرات مسجلة",
      },
      {
        type: "text",
        text: "اختبارات مقالي",
      },
      {
        type: "text",
        text: "اختبارات متعددة",
      },
      {
        type: "text",
        text: "رسوم الأشتراك",
      },
      {
        type: "text",
        text: "مدة الأشتراك",
      },
    ],
  },
];
