<div class="app-navbar"
     [ngClass]="{ 'no-nav':currentUrl?.includes('/lection/') || currentUrl == '' || currentUrl == '/' || currentUrl == '/loading' , 'no-shadow': false }">
  <div id="tt" class="app-navbar-dashboard" *ngIf="currentUrl && currentUrl.indexOf('/admin') > -1">
    <ul class="app-navbar-dashboard-menu-left">
      <li (click)="logOut()" [ngClass]="{ 'd-none': smScreen }" class="nav_right_list_li cursor_pointer" data-toggle="tooltip" data-placement="bottom"
          title="تسجيل الخروج">
        <i class="fa fa-sign-out"></i>
        <img src="../../../../../assets/icons/logout.svg" class="signout_icon" alt="">

      </li>
      <li (click)="addActive($event)" class="nav_right_list_li cursor_pointer" [ngClass]="{
        active: currentUrl === 'admin_profile',
        'd-none': smScreen
      }" [routerLink]="['admin_profile']" data-toggle="tooltip" data-placement="bottom" title=" تغيير كلمة السر">
        <img src='../../../../../assets/icons/person.svg' class="vpn_key">
      </li>
      <li (click)="onAdminNotification()" class="alarm-link">

        <notification-menu></notification-menu>

      </li>
      <li class="noty-link" (click)="locateTo('dashboard/admin')" [ngClass]="{
          active: currentUrl === '/dashboard/admin'
        }">
        <!-- routerLink="/dashboard/admin/articles-list" -->
        <div class="noty-icon icon-100 c-pointer width-noty-icon"></div>
      </li>

    </ul>
    <ul class="app-navbar-dashboard-menu-right">
      <li [ngClass]="{}">
        <button (click)="toggleNavList()" class="toggle_nav_list_btn">
          <img src="../../../../../assets/icons/down-arrow.svg" alt="" class="toggle_nav_list_btn_img" />
          <img src="../../../../../assets/icons/up-arrow.svg" alt="" class="toggle_nav_list_btn_img d-none" />
        </button>
      </li>

      <!-- <li (click)="addActive($event)" [ngClass]="{ 'd-none': smScreen }" class="nav_right_list_li" [routerLink]="['dashboard/admin/main-control']">
        <a>التحكم في الرئيسية</a>
        <div class="simple-add-this-icon icon-100"></div>
      </li>
      <li (click)="addActive($event)" [ngClass]="{ 'd-none': smScreen }" class="nav_right_list_li" [routerLink]="['dashboard/admin/coupons']">
        <a> التخفيضات </a>
        <div class="simple-add-this-icon icon-100"></div>
      </li>
      <li (click)="addActive($event)" [ngClass]="{ 'd-none': smScreen }" class="nav_right_list_li" [routerLink]="['dashboard/admin/main-control']">
        <a> الملف الشخصي</a>
        <div class="simple-add-this-icon icon-100"></div>
      </li>
      <li (click)="addActive($event)" class="nav_right_list_li" [ngClass]="{
          active: currentUrl === '/dashboard/admin/manage-students',
          'd-none': smScreen
        }" [routerLink]="['dashboard/admin/manage-students']">
        <a> ادارة الطلاب</a>
        <div class="group-work-icon icon-100"></div>
      </li>
      <li (click)="addActive($event)" class="nav_right_list_li" [ngClass]="{
          active: currentUrl === '/dashboard/admin/questions-bank',
          'd-none': smScreen
        }" [routerLink]="['dashboard/admin/questions-bank']">
        <a>بنك الاسئلة</a>
        <div class="question-brain-icon icon-100"></div>
      </li>
      <li (click)="addActive($event)" class="nav_right_list_li" [ngClass]="{
          active: currentUrl === '/dashboard/admin/edit-summaries',
          'd-none': smScreen
        }" [routerLink]="['dashboard/admin/edit-summaries']">
        <a> تعديل الملخصات</a>
        <div class="simple-add-icon icon-100"></div>
      </li>
      <li (click)="addActive($event)" class="nav_right_list_li" [ngClass]="{
          active: currentUrl === '/dashboard/admin/articles-list',
          'd-none': smScreen
        }" [routerLink]="['dashboard/admin/articles-list']">
        <a>تعديل المواد</a>
        <div class="simple-add-icon icon-100"></div>
      </li>

      <li (click)="addActive($event)" class="nav_right_list_li" [ngClass]="{
          active: currentUrl === '/dashboard/admin/subscribtion-control',
          'd-none': smScreen
        }" [routerLink]="['dashboard/admin/subscribtion-control']">
        <a>التحكم في الاشتراك</a>
        <div class="price-icon icon-100"></div>
      </li> -->
    </ul>
  </div>



  <div class="app-navbar-home-analytics" *ngIf="currentUrl && currentUrl.indexOf('/profile') > -1 && IsLoggedIn()">
    <ul class="app-navbar-home-analytics-menu edit-to-list">
      <li (click)="onAdminNotification()" class="app-navbar-home-analytics-menu-left">

        <notification-menu></notification-menu>

      </li>
      <ul class="app-navbar-home-analytics-menu-right">

        <li class="mx-7" (click)="logOut()">
          <a class="hand c-pointer white_space_nowrap">تسجيل الخروج</a>
          <!-- <i class="fas fa-sign-out" style="font-size:36px"></i> -->
          <img src="../../../../../assets/icons/logout.svg" class="signout_icon" alt="">
        </li>
        <li *ngIf="isPromise()" class="mx-5" [ngClass]="{ active: currentUrl === '/profile/0' }" [routerLink]="['/profile/subjects']">
          <a>المواد</a>
          <div class="block-icon book-store-icon icon-100"></div>
        </li>
        <li *ngIf="isAllowExam()&& !isPromise()" class="mx-5" [ngClass]="{ active: currentUrl === '/profile/0' }"
            [routerLink]="['lections/create-test']">
          <a>الامتحانات</a>
          <div *ngIf="isAllowExam()" class="block-icon book-store-icon icon-100"></div>
        </li>
        <li class="mx-5" [ngClass]="{ active: currentUrl === '/profile/profile' }" [routerLink]="['/profile/me']">
          <a class="white_space_nowrap">الملف الشخصي</a>
          <div class="block-icon avatar-icon icon-100"></div>
        </li>
        <li class="mx-5" [ngClass]="{ active: currentUrl === '/profile/payment' }" [routerLink]="['/profile/payment']">
          <a class="white_space_nowrap">الأشتراك والدفع</a>
          <div class="block-icon price-icon icon-100"></div>
        </li>
      </ul>
    </ul>

    <div class="edit-to-btn">
      <button class="app-navbar-home-analytics-right-area ml-auto-sm" (click)="homeAnalytics()">
        <div class="book-icon-wrapper">
          <div class="book-icon"></div>
        </div>
        <span>زمالتي</span>
      </button>
    </div>
  </div>




  <div class="app-navbar-home row" *ngIf="currentUrl && currentUrl === '/' || (currentUrl && currentUrl.indexOf('/profile') > -1 && !IsLoggedIn())" [ngClass]="{'sm_screen_navbar_home': IsLoggedIn()}">
    <div class="app-navbar-home-left-area col-xs-6 col-lg-8 col-md-6 col-sm-6">
      <div *ngIf="setting.phone" class="app-navbar-home-left-auth-contacts">
        <img src="assets/icons/phone-icon.png" class="phone-image" />
        <span>{{ setting.phone }}</span>
      </div>
      <div class="app-navbar-home-left-auth-actions">
        <a *ngIf="!IsLoggedIn()" class="btn-green btn-large  no-border btn-style" (click)="onNavigateAuth()"><span class="span-color">تسجيل
            دخول</span></a>

        <a *ngIf="!IsLoggedIn()" class="btn-green btn-large  no-border btn-style" (click)="onNavigate()"><span class="span-color">انشاء
            حساب</span></a>

        <button *ngIf="isAdmin()" class="btn-green btn-large no-border" [routerLink]="['/dashboard/admin/articles-list']">
          لوحة التحكم
        </button>

      </div>
    </div>
    <div class="col-xs-3 col-lg-0 col-sm-3 col-md-3"></div>
    <div class="col-xs-2 col-lg-1 col-md-1 col-sm-0">
      <button class="app-navbar-home-right-area" [ngClass]="{'home_btn_sm_screen': !IsLoggedIn()}" (click)="homeAnalytics()">
        <div class="book-icon-wrapper">
          <div class="book-icon"></div>
        </div>
        <span>زمالتي</span>
      </button>
    </div>
  </div>




  <div class="row"
       *ngIf="currentUrl === '/auth' || currentUrl === '/sign-up' || currentUrl === '/auth/forgot_password' || currentUrl?.includes('/auth/reset-password')"
       style="width: 100%">
    <div class="app-navbar-auth-sign-up col-xs-8 col-lg-9 col-sm-7 col-md-8"
         *ngIf="currentUrl === '/auth' || currentUrl === '/sign-up' || currentUrl === '/auth/forgot_password'  || currentUrl.includes('/auth/reset-password')">
      <ul class="app-navbar-auth-menu padding">
        <li class="create_acc"
            *ngIf="currentUrl === '/auth' || currentUrl === '/auth/forgot_password'  || currentUrl.includes('/auth/reset-password')">
          <span>لست مستخدم؟ </span>
          <a class="hand c-pointer" (click)="onNavigate()"> أنشاء حساب</a>
        </li>
        <li class="create_acc" *ngIf="currentUrl === '/sign-up'">
          <span>مستخدم بالفعل؟</span>
          <a class="hand c-pointer" (click)="onNavigateAuth()"> تسجيل الدخول</a>
        </li>
      </ul>
    </div>
  </div>



  <div class="col-xs-3 col-lg-1 col-sm-5 col-md-1"></div>



  <div class="app-navbar-homing col-xs-4 col-lg-2 col-sm-3 col-md-3" *ngIf="currentUrl === '/sign-up'">
    <button class="app-navbar-home-right-area">
      <div class="book-icon-wrapper">
        <div class="book-icon"></div>
      </div>
      <span>زمالتي</span>
    </button>
  </div>



  <div class="app-navbar-home col-xs-4 col-lg-3 col-sm-3 col-md-3" style="width: 10%"
       *ngIf="currentUrl === '/auth' || currentUrl === '/auth/forgot_password'">
    <button class="app-navbar-home-right-area">
      <div class="book-icon-wrapper">
        <div class="book-icon"></div>
      </div>
      <span>زمالتي </span>
    </button>
  </div>



  <div class="app-navbar-exams" *ngIf="currentUrl && currentUrl.indexOf('/exams') > -1">
    <ul class="app-navbar-exams-menu padding">
      <li>
        <a>اختبار عشوائي</a>
      </li>
      <li class="logo-area">
        <a>زمالتي</a>
        <div class="book-icon-wrapper">
          <div class="book-icon"></div>
        </div>
      </li>
    </ul>
  </div>



  <div *ngIf="currentUrl && currentUrl.indexOf('/lections') > -1" class="app-navbar-lections">
    <ul class="app-navbar-lections-menu" [ngClass]="{ opened: isRightNavBarOpened }">
      <li>
        <a *ngIf="currentUrl && currentUrl.indexOf('/create-test') > -1" href="/lections/create-test">انشاء اختبار
          عشوائي </a>
        <a *ngIf="currentUrl && currentUrl.indexOf('/previous-tests') > -1" href="/lections/previous-tests">الاختبارات
          السابقة</a>
        <a *ngIf="currentUrl && currentUrl.indexOf('/performance') > -1" href="/lections/performance">الاداء الكلى</a>
        <a *ngIf="currentUrl && currentUrl =='/lections' " href="/lections">المحاضرات</a>
        <a *ngIf="currentUrl && currentUrl.indexOf('/summeries') > -1" href="/lections/summeries">الملخصات</a>
        <a *ngIf="currentUrl && currentUrl.indexOf('/mynotes') > -1" href="/lections/mynotes">ملاحظاتي</a>
        <a *ngIf="currentUrl && currentUrl.indexOf('/myquestion') > -1" href="/lections/myquestion">اسألتى</a>
      </li>
      <li>
        <button id="right_menu_burger_btn" class="toggle-nav-bar-icon btn-icon" (click)="toggleRightMenu()"></button>
      </li>
    </ul>
    <app-right-nav-bar [isOpened]="isRightNavBarOpened" [sectionItems]="rightBarSectionItems" (emitToggle)="handleState($event)"></app-right-nav-bar>
  </div>




  <div class="app-navbar" style="direction: rtl;" *ngIf="currentUrl && currentUrl.indexOf('/exam/') > -1">

    <div class="col-lg-1" style="margin-right: 8">
      <button class="app-navbar-home-right-area ">
        <div class="book-icon-wrapper">
          <div class="book-icon"></div>
        </div>
        <span>زمالتي</span>
      </button>
    </div>
    <span class="font-size col-lg-8 txt-right"> {{lecName}}</span>
    <div class="col-lg-3 ">
      <h1 id="time_spent" class=" time-style ">{{ displayedTime }} </h1>
    </div>

  </div>



  <div class="app-navbar" style="direction: rtl;" *ngIf="currentUrl && currentUrl.indexOf('/exam/lecture') > -1">

    <div class="col-lg-1" style="margin-right: 8">
      <button class="app-navbar-home-right-area ">
        <!-- (click)="homeAnalytics()" -->
        <div class="book-icon-wrapper">
          <div class="book-icon"></div>
        </div>
        <span>زمالتي</span>
      </button>
    </div>
    <span class="font-size col-lg-8 txt-right">{{lecName}} </span>
    <!-- <span class="font-size col-lg-8 txt-right">مدخل الى اساسيات المحاسبة المحاضرة الاولى (اختبار تدريبي )</span> -->
    <div class="col-lg-3 ">
      <h1 id="time_spent" class=" time-style ">{{ displayedTime }} </h1>
    </div>

  </div>





</div>