<div class="project-control-your-subscribe">
  <div class="project-control-your-subscribe-card">
    <div class="project-control-your-subscribe-card-buttons">
      <button
        class="btn icon-circle btn-icon-plus-red"
        (click)="addOne()"
      ></button>
      <button class="btn menu-toggle-icon no-border"></button>
    </div>
    <form class="project-control-your-subscribe-form">
      <div class="form-field">
        <label>الاسم</label>
        <input
          class="form-control"
          type="text"
          placeholder="الاسم"
          [(ngModel)]="Subscribe.name"
          [ngModelOptions]="{ standalone: true }"
        />
      </div>
      <div class="form-field">
        <label>المحاضرات المسجلة</label>
        <input
          class="form-control form-control-with-options"
          type="text"
          [(ngModel)]="Subscribe.writedLections"
          [ngModelOptions]="{ standalone: true }"
        />
        <div class="form-control-options green-accent">
          <button (click)='selectControlOption("writedLections", true)'>
            {{formControlOptionPlaceholders.turnOn}}
          </button>
          <button (click)='selectControlOption("writedLections", false)'>
            {{formControlOptionPlaceholders.kickOff}}
          </button>
        </div>
      </div>
      <div class="form-field">
        <label>اختبارات المقالي</label>
        <input
          class="form-control form-control-with-options"
          type="text"
          [(ngModel)]="Subscribe.tests"
          [ngModelOptions]="{ standalone: true }"
        />
        <div class="form-control-options"
        [ngClass]="{'green-accent':Subscribe.tests == false}">
          <button (click)='selectControlOption("tests", true)'>
            {{formControlOptionPlaceholders.turnOn}}
          </button>
          <button (click)='selectControlOption("tests", false)'
          [ngClass]="{'green-accent':Subscribe.tests == true}">
            {{formControlOptionPlaceholders.kickOff}}
          </button>
        </div>
      </div>
      <div class="form-field">
        <label>اختبارات متعددة</label>
        <input
          class="form-control form-control-with-options"
          type="text"
          [(ngModel)]="Subscribe.multipleTests"
          [ngModelOptions]="{ standalone: true }"
        />
        <div class="form-control-options green-accent">
          <button (click)='selectControlOption("multipleTests", true)'>
            {{formControlOptionPlaceholders.turnOn}}
          </button>
          <button (click)='selectControlOption("multipleTests", false)'>
            {{formControlOptionPlaceholders.kickOff}}
          </button>
        </div>
      </div>
      <div class="form-field">
        <label>
          <span>( عن طريق موقع zoom )</span>
          <span>دورة اون لاين</span>
        </label>
        <input
          class="form-control form-control-with-options"
          type="text"
          [(ngModel)]="Subscribe.onlineCourse"
          [ngModelOptions]="{ standalone: true }"
        />
        <div class="form-control-options red-accent">
          <button (click)='selectControlOption("onlineCourse", true)'>
            {{formControlOptionPlaceholders.turnOn}}
          </button>
          <button (click)='selectControlOption("onlineCourse", false)'>
            {{formControlOptionPlaceholders.kickOff}}
          </button>
        </div>
      </div>
      <div class="form-field">
        <label>السعر</label>
        <input
          class="form-control"
          type="text"
          placeholder="من نحن وماذا نعمل ؟"
          [(ngModel)]="Subscribe.price"
          [ngModelOptions]="{ standalone: true }"
        />
      </div>
      <div class="form-field">
        <label>المدة</label>
        <input
          class="form-control"
          type="text"
          placeholder="المدة"
          [(ngModel)]="Subscribe.timing"
          [ngModelOptions]="{ standalone: true }"
        />
      </div>
    </form>
  </div>
</div>
