import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class TimerService {
  private time = new BehaviorSubject("00:00:00");
  private examFinished = new BehaviorSubject(false);
  private isExam = new BehaviorSubject(false);
  private isTime = new BehaviorSubject(false);
  private lecName = new BehaviorSubject("إسم المحاضرة");
  currentTime = this.time.asObservable();
  currentExamStatus = this.examFinished.asObservable();
  currentExam = this.isExam.asObservable();
  currentExamHaveTime = this.isTime.asObservable();
  currentLecName = this.lecName.asObservable();

  constructor() {}

  changeTime(time: string) {
    this.time.next(time);
  }
  changeTimeStatus(time: boolean) {
    this.isTime.next(time);
  }

  changeExamStatus(status: boolean) {
    this.examFinished.next(status);
  }

  changeExam(status: boolean) {
    this.isExam.next(status);
  }

  changeLecName(name: string) {
    this.lecName.next(name);
  }
}
