import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
@Injectable()
export class CanActivateHomeGuard implements CanActivate {
  constructor( private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const userDate: any = JSON.parse(localStorage.getItem("currentUser"));
    if (userDate != null) {
      return true;
    } else {
      this.router.navigateByUrl("/");
    }
  }
}
