import { CreatePassword } from "./components/auth-component/create-password/create-password";
import { ForgotPasswordComponent } from "./components/auth-component/forgot-password/forgot-password";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { AuthRoutingModule } from "@routing/auth-routing.module";
import { AuthComponent } from "./components/auth-component/auth.component";
import { RegisterComponent } from "./components/register-component/register.component";
import { RegisterStepperComponent } from "./components/register-stepper-component/register-stepper.component";
import { RegisterPaymentFormComponent } from "./components/register-payment-form-component/register-payment-form.component";
import { RegisterPaymentFormHeadingComponent } from "./components/register-payment-form-heading-component/register-payment-form-heading.component";
import { SharedModule } from "@modules/shared/shared.module";
import { NgxCaptchaModule } from "ngx-captcha";
import { CodeConfirmComponent } from "./components/codeConfirm-component/codeConfirm.component";

@NgModule({
  declarations: [
    AuthComponent,
    CodeConfirmComponent,
    RegisterComponent,
    RegisterStepperComponent,
    RegisterPaymentFormComponent,
    RegisterPaymentFormHeadingComponent,
    ForgotPasswordComponent,
    CreatePassword,
  ],
  imports: [
    BrowserModule,
    AuthRoutingModule,
    FormsModule,
    SharedModule,
    NgxCaptchaModule,
  ],
})
export class AuthModule {}
