import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap, shareReplay } from "rxjs/operators";
import { environment } from "../../../environments/environment";

import { BaseApiService } from "../baseAPI";
import { User } from "@modules/_models/User";
// import { SocialUser } from "angularx-social-login";

const API_USERS_URL = environment.baseUrl;

const cache = new Map();
@Injectable()
export class LecturesService extends BaseApiService<any> {
  rightNavState: boolean;

  constructor(http: HttpClient) {
    super(http);
    this.url = "/lectures";
  }

  updateRightNavState(state: boolean) {
    this.rightNavState = state;
  }

  getLectures(query: any): Observable<any> {
    const filter = query ? "?filter=" + `${JSON.stringify(query)}` : "";
    return this.http.get<any>(this.baseUrl + this.url + `/findLectures` + filter);
}
}
