<div class="lections-create-test">
  <div
    class="lections-create-test--content"
    [ngClass]="{'collapsed': lecturesService.rightNavState }"
  >
    <div class="lections-create-test--content--section">
      <div>
        <button class="question-circle-icon c-pointer"></button>
        <span>وضع الاختبار</span>
      </div>
      <div>
        <app-switcher
          (emitToggle)="handleSwitcherUpdate($event)"
        ></app-switcher>
        <span>وضع وقتي</span>
      </div>
    </div>
    <div class="lections-create-test--content--section">
      <div>
        <div class="question-circle-icon c-pointer"></div>
        <span>نوع الأسئلة</span>
      </div>
      <div class="lections-create-test--content--section-checkbox-area inline">
        <div>
          <div class="badge badge-light">
            <!-- <span> {{ article }} </span> -->
            <span> {{ articleNum() }} </span>
          </div>
          <app-checkbox
            [label]="checkbox1Placeholder"
            [id]="'article'"
            (change)="handleUpdateOnCheckbox($event,'article')"
          ></app-checkbox>
        </div>
        <div>
          <div class="badge badge-light">
            <!-- <span> {{ choices }} </span> -->
            <span> {{ choicesNum() }} </span>
          </div>
          <app-checkbox
            [label]="checkbox2Placeholder"
            [id]="'choices'"
            (change)="handleUpdateOnCheckbox($event,'choices')"
          ></app-checkbox>
        </div>
      </div>
    </div>
    <div class="lections-create-test--content--section">
      <div>
        <div class="question-circle-icon c-pointer"></div>
        <span>وضع الاختبار</span>
      </div>
      <div>
        <div
          class="lections-create-test--content--section-checkbox-area column"
        >
          <div *ngFor="let item of lectures?.slice((secondSec * 2),(secondSec * 3))">
            <div class="badge badge-light">
              <span> {{questions[item.id]}} </span>
            </div>
            <app-checkbox
              [label]="item.LectureName"
              [id]="item.id"
              (change)="handleUpdateOnCheckbox($event,item.id)"
            ></app-checkbox>
          </div>
          
        </div>
        
        <div
          class="lections-create-test--content--section-checkbox-area column"
        >
          <div *ngFor="let item of lectures?.slice(secondSec,(secondSec * 2))">
            <div class="badge badge-light">
              <span> {{questions[item.id]}} </span>
            </div>
            <app-checkbox
              [label]="item.LectureName"
              [id]="item.id"
              (change)="handleUpdateOnCheckbox($event,item.id)"
            ></app-checkbox>
          </div>
        </div>
        <div
          class="lections-create-test--content--section-checkbox-area column"
        >
          <div  *ngFor="let item of lectures?.slice(0,firstSec)">
            <div class="badge badge-light">
              <span> {{questions[item.id]}} </span>
            </div>
            <app-checkbox
              [label]="item.LectureName"
              [id]="item.id"
              (change)="handleUpdateOnCheckbox($event,item.id)"
            ></app-checkbox>
          </div>

        </div>
      </div>
    </div>
    <div class="lections-create-test--content--section">
      <div>
        <div class="question-circle-icon c-pointer"></div>
        <span>وضع الاختبار</span>
      </div>
      <div>
        <p class="validation_msg" *ngIf="chooseQuestionsType">من فضلك اختر نوع لأسئلة</p>
        <p class="validation_msg" *ngIf="chooseLecMsg">من فضلك اختر المحاضرات</p>
        <span>أدخل عدد الأسئلة المراد تضمينها</span>
        <input type="number" value="1" id="numOfQuestions" class="input_badge badge-light badge-big" min="1" [max]="maxLength()">
        <!-- [max]="choices + article" -->
        
      </div>
    </div>
    <div class="submit-area">
      <button class="btn-green btn-large btn" (click)="create()">
        انشاء الاختبار
      </button>
    </div>
  </div>
</div>
