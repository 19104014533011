import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from "@angular/core";
import { LecturesService } from "@services/apis/lectures.service";
import { UserLecturesService } from "@services/apis/userLectures.service";
import * as jsPDF from "jspdf";
@Component({
  selector: "mynotes",
  templateUrl: "./mynotes.component.html",
  styleUrls: ["./mynotes.component.sass"],
})
export class MynotesComponent implements OnInit {
  @Input() isRightNavOpened: boolean;
  isTimeThrowed: boolean;
  notes: any;
  courseId: string;
  constructor(
    public lecturesService: LecturesService,
    private userLecturesApi: UserLecturesService,
    private activatedRoute: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params.courseId && params.courseId != undefined && params.courseId != 'undefined') {
        this.courseId = params.courseId;
      }
      else {
        console.log(this.activatedRoute.children);
        this.activatedRoute.firstChild.params.subscribe(param => {
          if (param.courseId && param.courseId != undefined && param.courseId != 'undefined') {
            this.courseId = param.courseId;
          }
        })
      }
    })
    let sender = "";
    const currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser) {
      sender = currentUser.userId;
    }
    let resultMessages: any = await this.userLecturesApi
      .query({
        where: {
          userId: sender,
          courseId: this.courseId
        },
      })
      .toPromise();
    this.notes = resultMessages.result.filter(
      (x) => x.Notes != null && x.Notes.length > 0
    );
  }
  createPdf(): void {
    let stringArray = [];
    this.notes.forEach((lec) => {
      lec.Notes.forEach((Note) => {
        stringArray.push(`<p> ${Note.note}</p>`);
      });
    });
    let content = stringArray.join(" <br /> ");
    let html = `<body onload="window.print()"> ${content} </body>`;
    // let html = `<p onload="window.print()"> ${this.notes.fore}</p>`;
    let win = window.open("");
    win.document.write(html);
    win.focus();
    win.document.close();
  }
}
