import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-project-base-page",
  templateUrl: "project-base-page.html",
  styles: [
    `
      .info-page {
        padding: 8% 1%;
        text-align: right;
      }
      .info-page p:first-child {
        margin-top: 0;
      }
      .info-page p {
        color: #56677e;
        font-size: 3.5em;
        margin-top: 0.5em;
      }
    `,
  ],
})
export class ProjectBasePageComponent {
  urlPrefix: string;

  constructor(activatedRoute: ActivatedRoute) {
    activatedRoute.data.subscribe((data) => {
      this.urlPrefix = data.prefix;
    });
  }
}
