import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthenticationService } from "@services/apis/authentication.service";
import { TransactionsService } from "@services/apis/transaction.service";
import { UsersService } from "@services/apis/users.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.html",
  styleUrls: ["./forgot-password.sass"],
})
export class ForgotPasswordComponent implements OnInit {
  recoveryEmail = "";
  missingInput = false;
  invalidInput = false;
  missingEmail = false;
  serverError = false;
  emailSent = false;
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {}

  isValidEmail(mail) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail);
  }
  RecoverPassword() {
    this.missingInput = false;
    this.invalidInput = false;
    this.missingEmail = false;
    this.serverError = false;
    if (this.recoveryEmail === "") {
      this.missingInput = true;
    } else if (!this.isValidEmail(this.recoveryEmail)) {
      this.invalidInput = true;
    } else {
      this.authService.forgotPassword({ email: this.recoveryEmail.toLowerCase() }).subscribe(
        (res: any) => {
          this.emailSent = true;
          setTimeout(() => {
            this.router.navigateByUrl("auth");
          }, 5000);
          //   this.modalService.open(PasswordResetSent, {
          //     windowClass: "password-reset",
          //   });
        },
        (err) => {
          if (err.status) {
            this.missingEmail = true;
          } else {
            this.serverError = true;
          }
        }
      );
    }
  }
}
