import { RecreateTestComponent } from "./components/test-preparation/recreate-test/recreate-test";
import { TestPreparationComponent } from "./components/test-preparation/test-preparation";
import { AskLecturerComponent } from "./components/test-preparation/ask-lecturer/ask-lecturer";
import { PerformanceComponent } from "./components/performance/performance";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { SharedModule } from "@modules/shared/shared.module";
import { LectionsRoutingModule } from "@routing/lections-routing.module";

import { LectionsPreviousComponent } from "./components/lections-previous-tests-component/lections-previous-tests.component";
import { LectionsCreateTestComponent } from "./components/lections-create-test-component/lections-create-test.component";
import { LectionComponent } from "./components/lection/lection.component";
import { VimeModule } from "@vime/angular/dist";
import { NgCircleProgressModule } from "ng-circle-progress";
import { NgbProgressbarModule } from "@ng-bootstrap/ng-bootstrap";
import { VgCoreModule } from "@videogular/ngx-videogular/core";
import { VgControlsModule } from "@videogular/ngx-videogular/controls";
import { VgOverlayPlayModule } from "@videogular/ngx-videogular/overlay-play";
import { VgBufferingModule } from "@videogular/ngx-videogular/buffering";
@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    SharedModule,
    LectionsRoutingModule,
    NgbProgressbarModule,
    VimeModule,
    NgCircleProgressModule.forRoot({
      radius: 60,
      space: -10,
      outerStrokeGradient: true,
      outerStrokeWidth: 10,
      outerStrokeColor: "#4882c2",
      outerStrokeGradientStopColor: "#53a9ff",
      innerStrokeColor: "#e7e8ea",
      innerStrokeWidth: 10,
      title: "UI",
      animateTitle: false,
      animationDuration: 1000,
      showUnits: false,
      showBackground: false,
      clockwise: false,
      startFromZero: false,
      lazy: true,
    }),
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
  ],
  declarations: [
    LectionsCreateTestComponent,
    LectionsPreviousComponent,
    PerformanceComponent,
    TestPreparationComponent,
    AskLecturerComponent,
    LectionComponent,
    RecreateTestComponent,
  ],
})
export class LectionsModule {}
