import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-project-update-articles",
  templateUrl: "project-update-articles.html",
  styleUrls: ["./project-update-articles.sass"],
})
export class ProjectUpdateArticlesComponent implements OnInit {
  videoUploaderSrc: string;
  summariesUploaderSrc: string;
  mode: string;
  // todo
  Article: any;

  ngOnInit() {
    this.mode = "viewMode";
    this.Article = {};
    this.videoUploaderSrc = "أرفاق فيديو";
    this.summariesUploaderSrc = "أرفاق ملخصات";
    this.Article.title = "مادة المحاسبة";
    this.Article.details =
      "التحضير لمادة المحاسبة لأختبار الهيئة  السعودية للمحاسبين القانونين";
    this.Article.details_2 =
      "مع التطورات السريعة في المعايير المحاسبية والأنظمة والممارسات الصناعية، يحتاج الأفراد إلى التدريب المستمر لمواكبة هذه التطورات. كما أنهم بحاجة الى تحسين جودة التقارير المالية من خلال التسجيل والإفصاح بدقة عن المعاملات التجارية العادية وغير العادية. أعدت هذه الدورة من قبل الأخصائيين من الأوساط الأكاديمية والذين لديهم خبرة عملية راسخة.";
  }

  addLecture() {
    this.mode = "addMode";
  }

  addLinkToTheVideo() {}

  handleVideoSrcChange($event: any) {
    console.log($event);
  }

  handleEditFieldChange({ key, value }) {
    console.log(key, value);
  }

  modifyArticle() {}

  splitLectures() {}
}
