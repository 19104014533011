import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExamsService } from './../../services/apis/exam.service';
import { RecreateTestComponent } from './../../modules/lections/components/test-preparation/recreate-test/recreate-test';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.html',
    styleUrls: ['./loading.scss']
})
export class LoadingComponent implements OnInit {
    userId: string
    nextRoute: string;
    error: string;
    constructor(
        private activatedRoutes: ActivatedRoute,
        private modalService: NgbModal,
        private router: Router,
        private examsApi: ExamsService
    ) { }

    ngOnInit(): void {
        const currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
        if (currentUser) {
            this.userId = currentUser.userId;
        }

        let state$ = this.activatedRoutes.paramMap
            .pipe(map(() => window.history.state))
        state$.subscribe(r => {
            console.log(r)
            if (r.route) {
                this.nextRoute = r.route
                if (this.nextRoute == 'exam') {
                    this.openRecreateModal(r.lectureId)
                }
                if (this.nextRoute == 'complete') {
                    this.competeLecture(r.userLectures,r.lectures)
                }
            }
        })


    }

openRecreateModal(lecId: string) {
        try {
            this.examsApi
                .query({
                    where: {
                        userId: this.userId,
                        lectureId: lecId,
                        examType: "تدريبي",
                    },
                })
                .subscribe((exams) => {
                    if (exams["count"] > 0) {
                        const lastExam = exams["count"] - 1;
                        if (
                            exams["result"][lastExam] &&
                            exams["result"][lastExam].isCompleted &&
                            exams["result"][lastExam].isCompleted == true
                        ) {
                            const createTest = this.modalService.open(RecreateTestComponent, {
                                windowClass: "forgot-password",
                            });
                            createTest.componentInstance.lectureId = lecId;
                        } else {
                            this.router.navigateByUrl("/exam/" + exams["result"][lastExam].id);
                        }
                    } else {
                        this.examsApi
                        .creatExamFromLectureId(lecId, true)
                        .subscribe((examId) => {
                            this.router.navigateByUrl("/exam/" + examId);
                        });
                    }
                });
                
            } catch (error) {
                this.error = error;
                // this.router.navigateByUrl("/lections");
                
        }
    }
    

    competeLecture(userLectures,lectures) {
        try {
            let unCompleteLecture = userLectures.find(
                (lec) => lec.isCompleted == false
                );
                if (unCompleteLecture != null) {
                    this.router.navigateByUrl(
                        "/lection/watch/" + unCompleteLecture.lectureId
                        );
                    } else {
                        let lecIds = userLectures.map((lec) => lec.lectureId);
                        let unComplete = lectures.find((lec) => !lecIds.includes(lec.id));
                        if (unComplete != null) {
                            this.router.navigateByUrl("/lection/watch/" + unComplete.id);
                        }
                    }
                } catch (error) {
                    this.error = error;
            // this.router.navigateByUrl("/lections");
            
        }
    }
}