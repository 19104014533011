<div class="container first_title_m">
    <div class="row justify-content-center">
        <h1>{{course?.name}}</h1>
        <img [src]="course?.cover" class="img-fluid pt-3" alt="Responsive image">
    </div>
</div>

<div class="container">

    <div class="row my-5 justify-content-center">
        <div class="col-8 lectures">
            <div class="row" dir="rtl">
                <div class="col-12 my-3" dir="rtl">
                    <div class="row">

                        <div class="col-12 heads_col py-3">
                            <h3 class="text-right"><b class="color-dark-green"> إسم المحاضر:</b> &nbsp;
                                {{course?.profile?.firstName || '_______'}} {{course?.profile?.lastName}}
                            </h3>
                            <h4 class="text-right">
                                <b class="color-dark-green"> عن المحاضر:</b> {{course?.teacherAbout || '______'}}
                            </h4>
                            <p class="text-right"><b class="color-dark-green"> وصف المادة:</b> &nbsp; {{course?.type || '___'}}</p>
                            <small class="text-left"><b class="color-dark-green"> عدد الساعات: </b> &nbsp; {{course?.hours || '___'}}</small>
                        </div>

                        <!-- <div class="col-3 heads_col">
                            <h5 class="each_row">إسم المدرب</h5>
                            <h5 class="each_row">عن المدرب</h5>
                            <h5 class="each_row">وصف المادة</h5>
                            <h5 class="each_row">عدد الساعات</h5>
                        </div>
                        <div class="col-8 info_col">
                            <h5 class="each_row color-dark">{{course?.teacherName || '___'}}</h5>
                            <h5 class="each_row color-dark" data-toggle="tooltip" data-placement="bottom" [title]="course?.teacherAbout || '___'">
                                {{course?.teacherAbout || '___'}}</h5>
                            <h5 class="each_row color-dark" data-toggle="tooltip" data-placement="bottom" [title]="course?.type || '___'">
                                {{course?.type ||
                                '___'}}</h5>
                            <h5 class="each_row color-dark">{{course?.hours || '___'}}</h5>
                        </div> -->
                    </div>
                </div>
                <div class="col-12 heads_col lectures_head">
                    <div class="row justify-content-center">
                        <div class="col-8 text-center">
                            <h3 class="color-dark-green "> المحاضرات </h3>
                        </div>
                        <!-- <div class="col-2 text-center">الرابط</div> -->
                    </div>
                </div>
                <div class="col-12 lecture_col" *ngFor="let lecture of lectures; let i = index" [ngClass]="{'heads_col': (i%2 !== 0)}">
                    <div class="row justify-content-start">
                        <div class="col-8 text-right">{{lecture.LectureName}}</div>
                        <!-- <div class="col-2 text-right">
                            <a [routerLink]="['/lection/watch/'+lecture.id]"> عرض المحاضرة </a>
                        </div> -->
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="row justify-content-center" dir="rtl">
        <app-fellowship-table *ngIf="courseId" [courseId]="courseId"></app-fellowship-table>
    </div>
</div>