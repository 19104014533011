import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseModel } from "./base-model";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";

export class BaseApiService<T extends BaseModel> {
  public baseUrl = environment.baseUrl;
  public url = "/";

  constructor(protected http: HttpClient) {}

  protected authorization(): HttpHeaders {
    const token = JSON.parse(localStorage.getItem("currentUser"));

    if (token) {
      return new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Accept", "application/json")
        .set("Authorization", token.id);
    } else {
      return new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Accept", "application/json");
    }
  }

  queryByObject(query: any) {
    return this.http.get<T[]>(
      environment.baseUrl + this.url + "?filter=" + `${JSON.stringify(query)}`,
      { headers: this.authorization() }
    );
  }

  get(id: string) {
    return this.http.get<T>(this.baseUrl + this.url + `/${id}`, {
      headers: this.authorization(),
    });
  }

  query(query: any) {
    const filter = query ? "?filter=" + `${JSON.stringify(query)}` : "";
    return this.http.get<T[]>(this.baseUrl + this.url + "/find" + filter, {
      headers: this.authorization(),
    });
  }

  count() {
    return this.http.get<T[]>(this.baseUrl + this.url + "/count/", {
      headers: this.authorization(),
    });
  }
  save(item: T) {
    return item.id ? this.update(item) : this.add(item);
  }

  add(item: T) {
    return this.http.post<T>(this.baseUrl + this.url, item, {
      headers: this.authorization(),
    });
  }

  update(item: T) {
    return this.http.patch<T>(this.baseUrl + this.url + `/${item.id}`, item, {
      headers: this.authorization(),
    });
  }

  destroy(id: string): Observable<any> {
    return this.http.delete<T>(this.baseUrl + this.url + `/${id}`, {
      headers: this.authorization(),
    });
  }
  export(filter, order) {
    window.open(
      `${this.baseUrl + this.url}/csv${`?filter=${JSON.stringify(filter)}`}`
    );
  }
  exportPdf(id) {
    window.open(`${this.baseUrl + this.url}/img?id=` + id);
  }
  upload(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    const headers = new HttpHeaders();
    headers.append('path', file.name);
    const apiUrl = '/Containers/zamalti-app/upload';

    return this.http.post(this.baseUrl + apiUrl, formData)
        .pipe(
            map(m => {
                return {
                    path: m["result"].files.file[0].providerResponse.location,
                    originalFilename:
                        m["result"].files.file[0].originalFilename,
                    name: m["result"].files.file[0].providerResponse.name
                };
            })
        );
}
}
