import { CanActivateHomeGuard } from "./guards/can-activate-home.guard";
import { TestPreparationComponent } from "./../modules/lections/components/test-preparation/test-preparation";
import { PerformanceComponent } from "./../modules/lections/components/performance/performance";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LectionsCreateTestComponent } from "@modules/lections/components/lections-create-test-component/lections-create-test.component";
import { LectionsPreviousComponent } from "@modules/lections/components/lections-previous-tests-component/lections-previous-tests.component";

import { MynotesComponent } from "components/mynotes/mynotes.component";
import { MynotestoComponent } from "components/mynotesto/mynotesto.component";
import { MyquestionComponent } from "components/myquestion/myquestion.component";
import { SummariesComponent } from "components/summaries/summaries.component";
import { LectionComponent } from "@modules/lections/components/lection/lection.component";
import { CanActivatePromiseGuard } from "./guards/promise.guard";
import { CanActivateExamGuard } from "./guards/can-active-exam.guard";
import { CanActivateExamLectureGuard } from "./guards/can-active-exam-lecture.guard";

const LECTIONS_PREFIX = "lections";

const LectionsRoutes: Routes = [
  {
    path: `${LECTIONS_PREFIX}/:courseId/create-test`,
    component: LectionsCreateTestComponent,
    canActivate: [CanActivateHomeGuard,CanActivateExamGuard],
  },
  {
    path: `${LECTIONS_PREFIX}/:courseId/previous-tests`,
    component: LectionsPreviousComponent,
    canActivate: [CanActivateHomeGuard,CanActivateExamLectureGuard],
  },
  {
    path: `${LECTIONS_PREFIX}/:courseId/performance`,
    component: PerformanceComponent,
    canActivate: [CanActivateHomeGuard,CanActivateExamLectureGuard],
  },
  {
    path: `${LECTIONS_PREFIX}/:courseId`,
    component: TestPreparationComponent,
    canActivate: [CanActivateHomeGuard,CanActivatePromiseGuard],
  },
  {
    path: `${LECTIONS_PREFIX}/:courseId/test_preparation`,
    component: TestPreparationComponent,
    canActivate: [CanActivateHomeGuard],
  },
  {
    path: `${LECTIONS_PREFIX}/:courseId/summeries`,
    component: SummariesComponent,
    canActivate: [CanActivateHomeGuard,CanActivatePromiseGuard],
  },
  {
    path: `${LECTIONS_PREFIX}/:courseId/myquestion`,
    component: MyquestionComponent,
    canActivate: [CanActivateHomeGuard,CanActivatePromiseGuard],
  },
  {
    path: `${LECTIONS_PREFIX}/:courseId/mynotes`,
    component: MynotesComponent,
    canActivate: [CanActivateHomeGuard,CanActivatePromiseGuard],
  },
  {
    path: `${LECTIONS_PREFIX}/:courseId/mynotesto`,
    component: MynotestoComponent,
    canActivate: [CanActivateHomeGuard],
  },

  {
    path: `lection/watch/:lectureId`,
    component: LectionComponent,
    canActivate: [CanActivateHomeGuard,CanActivatePromiseGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(LectionsRoutes)],
  exports: [RouterModule],
})
export class LectionsRoutingModule {}
