<label
  class="app-checkbox"
  for="{{id}}"
  [ngClass]="{'checked': checked, 'app-checkbox': true}"
>
  <span>{{label}}</span>
  <div class="app-checkbox--mask"></div>
  <input
    type="checkbox"
    class="app-checkbox--field"
    id="{{id}}"
    [(ngModel)]="checked"
    (input)="toggle()"
  />
</label>
