<div class="row justify-content-center">
    <div class="col-md-12 text-center">
        <h2 class="mb-3 font-weight-bold">
            المواد
        </h2>
        <p>
            هناك عدد من المواد في موقع زمالتي
        </p>
    </div>
</div>
<div class="row justify-content-center">


    <div class="col-4" *ngFor="let course of courses">
        <div class="card" style="cursor: pointer;">
            <a (click)="onOpenCourse(course.id)">
                <img class="card-img-top" [src]="course.cover" alt="Card image cap">
                <div class="card-header">
                    {{ course.name }}
                </div>

            </a>
        </div>
    </div>
</div>