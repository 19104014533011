import { ExamsService } from "./../../../../services/apis/exam.service";
import { RecreateTestComponent } from "./recreate-test/recreate-test";
import { ActivatedRoute, Router } from "@angular/router";
import { LecturesService } from "./../../../../services/apis/lectures.service";
import { AskLecturerComponent } from "./ask-lecturer/ask-lecturer";
import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CoursesService } from "@services/apis/courses.service";
import { UserLecturesService } from "@services/apis/userLectures.service";
import { retry } from "rxjs/operators";
import * as moment from "moment";
import { NgxPubSubService } from "@pscoped/ngx-pub-sub";
import { AuthenticationService } from "@services/apis/authentication.service";

@Component({
  selector: "app-test-preparation",
  templateUrl: "test-preparation.html",
  styleUrls: ["./test-preparation.sass"],
})
export class TestPreparationComponent implements OnInit {
  @Input() isRightNavOpened: boolean;
  lectures: any[] = [];
  course: any = {};
  userLectures: any = [];
  sender: string = "";
  percentage: number = 0;
  courseId: string;
  constructor(
    private pubSub: NgxPubSubService,
    private modalService: NgbModal,
    private lectureApi: LecturesService,
    private activatedRoutes: ActivatedRoute,
    private courseApi: CoursesService,
    private userLectureApi: UserLecturesService,
    private examsApi: ExamsService,
    private router: Router,
    public lecturesService: LecturesService,
    public authApi: AuthenticationService
  ) { }
  ngOnInit() {
    this.activatedRoutes.params.subscribe(params => {
      if (params.courseId && params.courseId != undefined && params.courseId != 'undefined') {
        this.courseId = params.courseId;
      }
      else {
        this.activatedRoutes.firstChild.params.subscribe(param => {
          if (param.courseId && param.courseId != undefined && param.courseId != 'undefined') {
            this.courseId = param.courseId;
          }
        })
      }
    })

    if (this.courseId) {

      this.courseApi.get(this.courseId).subscribe((res) => {
        if (res) {
          this.course = res;
        }
      }, (err) => {
        console.log(err.error.error.message);
        if (err.error.error.statusCode == 400) {
          let currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
          currentUser.subscribedAndPaid = false;
          currentUser.allowLecture = false;
          currentUser.allowExam = false;
          localStorage.setItem("currentUser", JSON.stringify(currentUser));
          this.router.navigate(["/profile/payment"]);
        }

      });
      this.lectureApi
        .query({ where: { courseId: this.courseId, isDeleted: { neq: true } } })
        .subscribe(async (res) => {
          this.lectures = res["result"];
          const currentUser: any = JSON.parse(
            localStorage.getItem("currentUser")
          );
          if (currentUser) {
            this.sender = currentUser.userId;
          }
          let lectureIds = [];
          this.lectures.forEach((x) => lectureIds.push(x.id));
          this.userLectures = await this.userLectureApi
            .query({
              where: {
                userId: this.sender,
                lectureId: { inq: lectureIds },
              },
            })
            .toPromise();
          this.userLectures = this.userLectures["result"];
        });
    } else {
      this.lectureApi
        .query({ where: { isDeleted: { neq: true } } })
        .subscribe(async (res) => {
          this.lectures = res["result"];
          const currentUser: any = JSON.parse(
            localStorage.getItem("currentUser")
          );
          if (currentUser) {
            this.sender = currentUser.userId;
          }
          let lectureIds = [];
          this.lectures.forEach((x) => lectureIds.push(x.id));
          this.userLectures = await this.userLectureApi
            .query({
              where: {
                userId: this.sender,
                lectureId: { inq: lectureIds },
              },
            })
            .toPromise();
          this.userLectures = this.userLectures["result"];
        }, (err) => {
          console.log(err.error.error.message);
          if (err.error.error.statusCode == 400) {
            let currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
            currentUser.subscribedAndPaid = false;
            currentUser.allowLecture = false;
            currentUser.allowExam = false;
            localStorage.setItem("currentUser", JSON.stringify(currentUser));
            this.router.navigate(["/profile/payment"]);
          }
        });


    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      let burgerBtn = document.getElementById('right_menu_burger_btn');
      if (burgerBtn) burgerBtn.click()
    }, 1000);
  }

  getPercentage() {
    let percentage: number = 0;
    let userLecturesComplete = this.userLectures.filter(
      (x) => x.isCompleted == true
    );
    if (
      userLecturesComplete &&
      userLecturesComplete.length > 0 &&
      this.lectures &&
      this.lectures.length > 0
    ) {
      percentage = (userLecturesComplete.length / this.lectures.length) * 100;
      percentage = Math.round(percentage);
      return percentage;
    }
    return percentage;
  }

  askLecturer(id) {
    const ask = this.modalService.open(AskLecturerComponent, {
      windowClass: "forgot-password",
    });
    ask.componentInstance.lectureId = id;
    ask.componentInstance.courseId = this.courseId;
  }
  getLastSeen(lectureId) {
    let userLecture = this.userLectures.find(
      (item) => item.lectureId == lectureId
    );
    if (userLecture != null) {
      return userLecture.lastSeen;
    }
    return null;
  }
  getLastSeenTime(lectureId) {
    let userLecture = this.userLectures.find(
      (item) => item.lectureId == lectureId
    );
    if (userLecture != null) {
      return moment
        .utc(
          moment.duration(userLecture.lastSeenTime, "seconds").asMilliseconds()
        )
        .format("HH:mm:ss");
    }
    return null;
  }
  competeLecture() {
    let unCompleteLecture = this.userLectures.find(
      (lec) => lec.isCompleted == false
    );
    if (unCompleteLecture != null) {
      this.router.navigateByUrl(
        "/lection/watch/" + unCompleteLecture.lectureId
      );
    } else {
      let lecIds = this.userLectures.map((lec) => lec.lectureId);
      let unComplete = this.lectures.find((lec) => !lecIds.includes(lec.id));
      if (unComplete != null) {
        this.router.navigateByUrl("/lection/watch/" + unComplete.id);
      }
    }
  }
  async watchLectures(lectureId) {
    this.router.navigateByUrl("/lection/watch/" + lectureId);
  }



}
