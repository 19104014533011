import { LecturesService } from './../../../../../services/apis/lectures.service';
import { CoursesService } from './../../../../../services/apis/courses.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'course-details',
    templateUrl: './course-details.html',
    styleUrls: ['./course-details.scss']
})
export class CourseDetailsComponent implements OnInit {
    course: any;
    lectures: any[] = [];
    courseId: string;
    constructor(private activatedRoute: ActivatedRoute,
        private courseApi: CoursesService,
        private lecturesApi: LecturesService,
    ) { }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(
            (params) => {
                const id = params.courseId;
                if (id) {
                    console.log(id);
                    this.courseApi.getCourses({ where: { id: id }, include: ['profile'] }).subscribe((res) => {
                        if (res) {
                            console.log(res['result'][0]);
                            this.course = res['result'][0];
                            this.courseId = this.course.id;

                            this.lecturesApi.getLectures({
                                where:
                                {
                                    courseId: this.courseId,
                                    isDeleted: { neq: true },
                                    LectureName: { neq: null }
                                }
                            }).subscribe(lectures => {
                                this.lectures = lectures['result'];
                                console.log('this.lectures', this.lectures);
                            })

                        }
                    });
                }
            }
        );
    }
}
