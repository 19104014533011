
import { BaseModel } from "@services/base-model";

export class User implements BaseModel {
  id?: string;
  email?: string;
  password?: string;
  title?: string;
  firstName?: string;
  lastName?: string;
  mobile?: string;
  captchaResponse?: string;
  userId?: string;
  registrationCompleted?: boolean;
  blocked:boolean;
  name?:string;
  ignoreCaptcha:boolean;
  constructor() {
    this.ignoreCaptcha=false;
  }
}
