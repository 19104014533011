<div class="register-stepper registeration">
  <div
    class="register-stepper-step"
    *ngFor="let step of steps; let id = index"
    [ngClass]="{'active': step.isPassed}"
  >
    <div class="register-stepper-step-line" *ngIf="(steps.length==3 && id !== 2)||(steps.length==2 && id !== 1)"></div>
    <span class="register-stepper-step-line-point-text"
      >{{steps.length - id}}</span
    >
    <span class="register-stepper-step-name">{{step.name}}</span>
  </div>
</div>
