<div  (click)="downBox()"   class="icon alarm-icon icon-100 c-pointer" id="bell">  </div>
<div class="notifications" id="box"
[ngClass]="{'show': down, 'hide': !down}"
>
    <div *ngFor="let notification of notifications"   class="notifications-item"> 
        <div (click)="onOpenNotification(notification)" class="txt">
            <p class="title" >{{notification.body}}</p>
            <p>{{getduration(notification.created)}}</p>
        </div>
    </div>
    <div *ngIf="notifications.length==0"  class="notifications-item"> 
        <div  class="txt">
            <p class="title" >لا توجد رسائل جديده</p>
            <p></p>
        </div>
    </div>
    <button *ngIf="isAllNotifications" class="show_more" (click)="showMore()">المزيد</button>
</div>
  