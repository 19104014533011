export const environment = {
  production: true,
  baseUrl: '/api',
  socketUrl: '/',
  defaultLanguage: 'en',
  supportedLanguages: [
    {
      name: 'en',
      code: 'en'
    },
    {
      name: 'ar',
      code: 'ar'
    }
  ]
};
