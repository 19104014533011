import { QuestionsComponent } from "./exam/question/question";
import { QuestionNumbersComponent } from "./exam/question-numbers/question-numbers";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ExamComponent } from "./exam/exam";
import { NgModule } from "@angular/core";

import { ExamsRoutingModule } from "@routing/exams-routing.module";

import { ExamsPractice2TestComponent } from "./components/exams-practice-test-2-component/exams-practice-test-2.component";
import { ExamsTestEsseComponent } from "./components/exams-test-esse-component/exams-test-esse.component";
import { ExamsTestRandomComponent } from "./components/exams-test-random-component/exams-test-random.component";
import { ExamsTestSimpleLearningComponent } from "./components/exams-test-simple-learning-component/exams-test-simple-learning.component";
import { NgxPubSubModule } from "@pscoped/ngx-pub-sub";
import { CKEditorModule } from "ckeditor4-angular";

@NgModule({
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    ExamsRoutingModule,
    CommonModule,
    NgxPubSubModule,
    CKEditorModule
  ],
  declarations: [
    ExamsPractice2TestComponent,
    ExamsTestEsseComponent,
    ExamsTestRandomComponent,
    ExamsTestSimpleLearningComponent,
    ExamComponent,
    QuestionNumbersComponent,
    QuestionsComponent,
  ],
})
export class ExamsModule {}
