import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "@services/apis/authentication.service";
import { TransactionsService } from "@services/apis/transaction.service";
import { UsersService } from "@services/apis/users.service";

@Component({
  selector: "app-codeConfirm",
  templateUrl: "./codeConfirm.component.html",
  styleUrls: ["./codeConfirm.component.sass"],
})
export class CodeConfirmComponent implements OnInit {
  authForm: any;
  phoneVerificationStepActive: boolean;
  verificationInfo: Array<string>;
  loginFailed = false;
  userNotFound = false;
  includeCaptcha = false;
  userId;
  user: any;
  errorWithlogin = false;
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private transactiontransAPI: TransactionsService,
    private usersApi: UsersService
  ) {}

  async ngOnInit() {
    const currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    let _user = null;
    if (currentUser) {
      this.userId = currentUser.userId;
      this.user = currentUser;
      _user = await this.authService.get(this.userId).toPromise();
    }
    if (_user == null) {
      this.router.navigate(["/"]).then();
    }
    if (_user.isVerified) {
      await this.redirect();
    }
  }
  async handleVerificationData(data: any) {
    // const Code = data.join("");
    let _profile: any = await this.usersApi.get(this.userId).toPromise();
    this.authService.confirmEmail(data, _profile.email).subscribe(
      async (result: any) => {
        await this.redirect();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  private async redirect() {
    // const transactiontran: any = await this.transactiontransAPI
    //   .query({ where: { userId: this.userId } })
    //   .toPromise();
    // let haveTransactiontran = transactiontran.result.find(
    //   (x) => x.isSuccess === true
    // );
    if (this.user.isadmin) {
      this.router.navigate(["/dashboard/admin/articles-list"]).then();
    } else {
      // if (haveTransactiontran == null) {
      this.router.navigate(["/profile/payment"]).then();
      // } else {
      //   this.router.navigate(["/"]).then();
      // }
    }
  }
}
