import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Components.
import { ExamsTestSimpleLearningComponent } from "@modules/exams/components/exams-test-simple-learning-component/exams-test-simple-learning.component";
import { ExamsTestRandomComponent } from "@modules/exams/components/exams-test-random-component/exams-test-random.component";
import { ExamsTestEsseComponent } from "@modules/exams/components/exams-test-esse-component/exams-test-esse.component";
import { ExamsPractice2TestComponent } from "@modules/exams/components/exams-practice-test-2-component/exams-practice-test-2.component";

const EXAMS_PREFIX = "exams";

const ExamsRoutes: Routes = [
  {
    path: `${EXAMS_PREFIX}/test-esse`,
    component: ExamsTestEsseComponent,
  },
  {
    path: `${EXAMS_PREFIX}/test-random`,
    component: ExamsTestRandomComponent,
  },
  {
    path: `${EXAMS_PREFIX}/test-simple-learning`,
    component: ExamsTestSimpleLearningComponent,
  },
  {
    path: `${EXAMS_PREFIX}/test-practice-2`,
    component: ExamsPractice2TestComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(ExamsRoutes)],
  exports: [RouterModule],
})
export class ExamsRoutingModule {}
