<div class="info-page">
  <div *ngIf='urlPrefix == "base"'>
    <p>إعطاء اشخاص اشتراكات مجانية مع تحديد المدة والنوع</p>
    <p>
      معرفة عدد المستخدمين في الموقع والموجودين الان في الموقع ومدة الاشتراك لهم
      ونوع الاشتراك وأرقام جولاتهم وايملاتهم وإمكانية حظر المستخدم واعطائه ترقية
      مجانيةإمكانية تحميل نسخة على الاكسيل للمستخدمين
    </p>
  </div>
  <div *ngIf='urlPrefix == "price-adjustment"'>
    <p>تعديل أسعار الكورسات</p>
    <p>إعطاء اشخاص اشتراكات مجانية مع تحديد المدة والنوع</p>
    <p>لا يمكن اخذ التقاط الشاشة</p>
    <p>
      إضافة السؤال وحذف السؤال وتعديل السؤال مع تحديد هل هو عشوائي او تجريبي او
      كلاهما او مقالي او اختياري
    </p>
    <p>
      معرفة عدد المستخدمين في الموقع والموجودين الان في الموقع ومدة الاشتراك لهم
      ونوع الاشتراك وأرقام جولاتهم وايملاتهم وإمكانية حظر المستخدم واعطائه ترقية
      مجانيةإمكانية تحميل نسخة على الاكسيل للمستخدمين
    </p>
    <p>التحكم في الصفحة الرئيسية</p>
    <p>إضافة المحاضرات (نوع واسم المحاضرات والملخصات في داخلها )</p>
    <p>اضافة ملخصات الكلية</p>
    <p>إمكانية تغير رقم التواصل في الصفحة الرئيسية</p>
    <p>اضافة سبب االاجابة الصحيحة والخاطئة لكل سؤال</p>
    <p>تحديد الإجابات الصحيحة في الاختياري</p>
    <p>إمكانية إعطاء صلاحية للتحكم في الموقع في تحديد نوع الصلاحية</p>
  </div>
</div>
