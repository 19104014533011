<div class="col-lg-12 question_List float-div">
    <div class="row">
        <div class="col-lg-12">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <a ngbNavLink> الكل {{questions?.length}} </a>
                    <ng-template ngbNavContent>
                        <div class="col-lg-12 questions_numbers">
                            <div  id="all_numbers" class="row">
                                <p>قائمة الأسئلة</p>
                                <div (click)="changeQuestion(j)" class="number"
                                 *ngFor="let questNum of questions; let j = index"
                                 [ngClass]="{'number_bg': j == displayedQuest,
                                    'number_border_right':filterBorder(j)?.isRight == true,
                                    'number_border_wrong':filterBorder(j)?.isRight == false}"
                                    [id]="'number_'+ j ">
                                    {{ j + 1}}
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" [disabled]="disabled">
                    <a ngbNavLink
                        [ngClass]="{'right_label': isCompleted == true}">{{isCompleted == false? tabs['false'][0]: tabs['true'][0]}}
                    </a>
                    <ng-template ngbNavContent>
                        <p>E
                        </p>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3" [disabled]="disabled">
                    <a ngbNavLink
                        [ngClass]="{'wrong_label': isCompleted == true}">{{isCompleted == false? tabs['false'][1]: tabs['true'][1]}}
                    </a>
                    <ng-template ngbNavContent>
                        <p>Se
                        </p>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
    </div>
</div>