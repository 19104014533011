import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
@Injectable()
export class CanActivateExamLectureGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const userDate: any = JSON.parse(localStorage.getItem("currentUser"));
    if (
      userDate != null &&
      userDate.promise != null &&
      userDate.promise == true &&
      userDate.subscribedAndPaid != null &&
      userDate.subscribedAndPaid == true &&
      (userDate.allowExam !=null &&
      userDate.allowExam == true)||
      (userDate.allowLecture !=null &&
        userDate.allowLecture == true)
    ) {
      return true;
    } else {
      this.router.navigateByUrl("/profile/payment").then();
    }
  }
}
