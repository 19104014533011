import { CoursesService } from '@services/apis/courses.service';
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "@services/apis/authentication.service";
import { TransactionsService } from "@services/apis/transaction.service";
import { UsersService } from "@services/apis/users.service";
import * as moment from "moment";

@Component({
  selector: "app-home-analytics-sub-and-payment",
  templateUrl: "./home-analytics-sub-and-payment.component.html",
  styleUrls: ["./home-analytics-sub-and-payment.component.sass"],
})
export class SubjectsComponent implements OnInit {
  courses: any[] = [];
  userId: string;
  coursesIds: any[] = [];
  constructor(
    private coursesApi: CoursesService,
    private transactionsApi: TransactionsService,
    private activatedRoute: ActivatedRoute
  ) { }
  ngOnInit() {
    const currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser) {
      this.userId = currentUser.userId;
    }
    this.transactionsApi.query({
      where: {
        userId: this.userId,
        isDeleted: { neq: true }, isSuccess: true,
        endDate: {
          gte: moment()
        },
      }
    }).subscribe(res => {
      let result = res['result'];
      if (result.length > 0) {
        result.forEach(ele => {
          this.coursesIds.push(ele.courseId);
        });
      }
      this.coursesApi.query({
        where: {
          id: { inq: this.coursesIds }
        }
      }).subscribe(res => {
        this.courses = res['result']
      })
    })

  }
}
