<div class="row justify-content-center">
    <div class="col-md-12 text-center mt-5">
        <h2 class="mb-3 font-weight-bold">
            خطط الاشتراك
        </h2>
        <p>
            هناك عدد من الخطط للاشتراك في موقع زمالتي
        </p>
    </div>
</div>
<div class="row justify-content-center">
    <div class="col">
        <div class="card main-plan">
            <div class="card-header">

            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item">
                    محاضرات مسجلة
                </li>
                <li class="list-group-item">
                    اختبارات مقالي
                </li>
                <li class="list-group-item">
                    اختبارات متعددة
                </li>
            </ul>

        </div>
    </div>

    <div class="col" *ngFor="let item of items">
        <div class="card" [ngClass]="{'usual-plus': isPlanSelected(item)}">
            <div class="card-header">
                {{ item.title }}
            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item">
                    <img *ngIf="item.allowLecture == true && isPlanSelected(item) === false"
                        src="../../../../../assets/images/new_landing_page/checkmark.svg">
                    <img *ngIf="!item.allowLecture || item.allowLecture == false"
                        src="../../../../../assets/images/new_landing_page/close.svg">
                    <img *ngIf="item.allowLecture == true && isPlanSelected(item) === true"
                        src="../../../../../assets/images/new_landing_page/checkmark-circle.svg">
                </li>
                <li class="list-group-item">
                    <img *ngIf="item.allowExam == true && isPlanSelected(item) === false"
                        src="../../../../../assets/images/new_landing_page/checkmark.svg">
                    <img *ngIf="!item.allowExam || item.allowExam == false"
                        src="../../../../../assets/images/new_landing_page/close.svg">
                    <img *ngIf="item.allowExam == true && isPlanSelected(item) === true"
                        src="../../../../../assets/images/new_landing_page/checkmark-circle.svg">
                </li>
                <li class="list-group-item">
                    <img *ngIf="(item.allowLecture == true && item.allowExam == true && isPlanSelected(item) === false) || item.title === 'بنك الاسئلة فقط'"
                        src="../../../../../assets/images/new_landing_page/checkmark.svg">
                    <img *ngIf="(!item.allowLecture || !item.allowExam || item.allowLecture == false || item.allowExam == false) && item.title != 'بنك الاسئلة فقط'"
                        src="../../../../../assets/images/new_landing_page/close.svg">
                    <img *ngIf="item.allowLecture == true && item.allowExam == true && isPlanSelected(item) === true"
                        src="../../../../../assets/images/new_landing_page/checkmark-circle.svg">
                </li>
            </ul>
            <div class="card-footer">
                <div *ngFor="let childItem of item.items"
                    [ngClass]="{'d-none': childItem.type != 'price' && childItem.type != 'duration' && childItem.type != 'text-checkbox'}">
                    <h1 *ngIf="childItem.type === 'price'">
                        {{childItem.text}}
                        <span>
                            ريال
                        </span>
                    </h1>
                    <p *ngIf="childItem.type === 'duration'">
                        {{childItem.text}}
                    </p>
                    <label *ngIf="childItem.type === 'text-checkbox'" class="checkbox-field" for="{{ childItem.id }}">
                        <input type="checkbox" class="checkbox-field-control" id="{{ childItem.id }}"
                            [(ngModel)]="childItem.isChecked" (change)="checkValue(item,item.id)"
                            *ngIf="!isHome || childItem.id=='Lecture-1'||childItem.id=='Exam-1'" />
                    </label>
                    <button *ngIf="childItem.type === 'text-checkbox'" (click)="resolveAction()"
                        class="btn btn-subscripe">
                        {{childItem.text}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>