import { CoursesService } from "./../../../../services/apis/courses.service";
import {
  block3ItemCards,
  block4FellowshipItems,
} from "./../landing-component/constants/landing-fellowship.items";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { SettingsService } from "@services/apis/setting.service";

@Component({
  selector: "new-landing-page",
  templateUrl: "./new-landing-page.html",
  styleUrls: ["./new-landing-page.scss"],
})
export class NewLandingPage implements OnInit {
  block3Cards: Array<any>;
  landingFellowshipCells: Array<any>;
  @Input() user: any;
  setting: any = {};
  courses: any;
  fontStyle: any;
  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  @ViewChild("videoPlayer2", { static: false }) videoplayer2: ElementRef;
  constructor(
    private settingApi: SettingsService,
    private courseApi: CoursesService
  ) {
    this.block3Cards = block3ItemCards;
    this.landingFellowshipCells = block4FellowshipItems;
  }
  isPlay: boolean = false;
  toggleVideo() {
    this.videoplayer.nativeElement.play();
  }
  toggleVideo2() {
    this.videoplayer2.nativeElement.play();
  }
  async ngOnInit() {
    let setting: any = await this.settingApi.query({}).toPromise();
    this.setting = setting.result[0];
    this.preview();
    let courses: any = await this.courseApi.query({}).toPromise();
    this.courses = courses.result;
  }
  preview(): void {
    this.fontStyle = {
      backgroundColor: this.setting.backgroundColor,
      "font-size": this.setting.fontSize + "px",
      "font-weight": this.setting.fontWeight,
      "font-family": this.setting.fontFamily,
      color: this.setting.fontColor,
      "line-height": 1,
    };
  }
  youtubeLinkToId(url) {
    let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    if (url != null) {
      let match = url.match(regExp);
      return match && match[7].length == 11 ? match[7] : false;
    }
    return false;
  }
  //note : replace with routes
  onNavigate() {
    localStorage.removeItem("currentUser");
    sessionStorage.removeItem("currentUser");
    localStorage.removeItem("isAdmin");
    sessionStorage.removeItem("isAdmin");
    window.open("sign-up", "_self");
  }

  IsLoggedIn() {
    if (localStorage.currentUser) {
      return true;
    } else {
      return false;
    }
  }

  onNavigateAuth() {
    localStorage.removeItem("currentUser");
    sessionStorage.removeItem("currentUser");
    localStorage.removeItem("isAdmin");
    sessionStorage.removeItem("isAdmin");
    window.open("auth", "_self");
  }

  isAdmin() {
    if (localStorage.currentUser) {
      return JSON.parse(localStorage.currentUser).isadmin;
    } else {
      return false;
    }
  }
}
