import { AnswersService } from "./../../../../services/apis/answers.service";
import { ExamsService } from "./../../../../services/apis/exam.service";
import { QuestionssService } from "./../../../../services/apis/questions.service";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  selector: "app-question",
  templateUrl: "./question.html",
  styleUrls: ["./question.scss"],
})
export class QuestionsComponent implements OnInit {
  public Editor = ClassicEditor;
  @Input() exam: any;
  @Input() displayedQuest: number = 0;
  @Input() answered: boolean = false;
  @Input() isCompleted: boolean = false;
  @Output() ArticleQuestionsNumberEvent = new EventEmitter<Number>();
  @Output() rightAnswersNumber = new EventEmitter<Number>();
  @Output() questionId = new EventEmitter<string>();
  @Output() questionsArray = new EventEmitter<any[]>();
  @Output() isAnswered = new EventEmitter<boolean>();
  @Output() isRightAnswerEvent = new EventEmitter<boolean>();
  @Output() showAnswerEvent = new EventEmitter<boolean>();
  @Output() examUpdates = new EventEmitter<any>();
  @Output() answerForBorder = new EventEmitter<any>();
  questions: any[] = [];
  answers: any[] = [];
  AllQuestionsCount: number = 0;
  articleQuestionsNumber: number = 0;
  AnsweredArticleQuestions: number = 0;
  right: number = 0;
  wrong: number = 0;
  showAnswer: boolean = false;
  finalQuestion: boolean = false;
  isRightAnswer: boolean = false;
  characters: any = [];
  userId: string;

  config = {
    contentsLangDirection: "rtl",
    title: false,
    toolbar: [
      {
        name: "basicstyles",
        items: [
          "Bold",
          "Italic",
          "Underline",
          "Strike",
          "Subscript",
          "Superscript",
          "-",
          "CopyFormatting",
          "RemoveFormat",
        ],
      },
      {
        name: "paragraph",
        items: [
          "NumberedList",
          "BulletedList",
          "-",
          "Outdent",
          "Indent",
          "-",
          "Blockquote",
          "CreateDiv",
          "-",
          "JustifyLeft",
          "JustifyCenter",
          "JustifyRight",
          "JustifyBlock",
          "-",
          "BidiLtr",
          "BidiRtl",
        ],
      },
      { name: "links", items: ["Link", "Unlink", "Anchor"] },
      {
        name: "insert",
        items: [
          "Table",
          "HorizontalRule",
          "Smiley",
          "SpecialChar",
          "PageBreak",
        ],
      },
      "/",
      {
        name: "styles",
        items: ["Styles", "Format", "fontFamily", "Font", "FontSize"],
      },
      { name: "colors", items: ["TextColor", "BGColor"] },
    ],
  };

  constructor(
    private questionsApi: QuestionssService,
    private examsApi: ExamsService,
    private answersApi: AnswersService
  ) {
    this.characters = ["أ", "ب", "ج", "د", "و", "ر"];

  }
  ngOnInit() {
    this.userId = JSON.parse(localStorage.getItem("currentUser")).userId;
    this.queryQuestions();
    this.getAnsweredArticleQuestions();
    // this.iframeStyle();
  }

  ngOnChanges(): void {
    this.changeQuestion();
    this.resetAnswers();
    this.isAnswered.emit(this.answered);
    this.isRightAnswerEvent.emit(this.isRightAnswer);
    this.getAnsweredArticleQuestions();
    // this.iframeStyle();
  }

  ngAfterViewInit() {
    let editor = document.getElementById('cke_editor23')
    if (editor) {
      editor.childNodes[1]['style'].direction = 'rtl'
      document.getElementById('cke_editor1').childNodes[1]['style'].display = 'none'
    }

  }

  queryQuestions() {
    this.articleQuestionsNumber = 0;
    for (let i = 0; i < this.exam?.questions.length; i++) {
      this.questionsApi.get(this.exam?.questions[i]).subscribe((quest) => {
        if (quest.question.includes('<pre')) {
          console.log(quest.question);
          quest.question = quest.question.replace(/<pre/gm, '<p').replace(/<\/pre/gm, '</p');
          this.questionsApi.save(quest).subscribe(newQuest => console.log(newQuest.question));
        }

        this.questions.push(quest);
        if (quest.type == "مقالى")
          this.articleQuestionsNumber = this.articleQuestionsNumber + 1;
        if (i + 1 == this.exam?.questions.length) {
          this.AllQuestionsCount = this.questions.length;
          if (this.questions.length > 0) {
            this.AllQuestionsCount = this.questions.length;
            this.resetAnswers();
            this.getAnswer(this.questions[this.displayedQuest]?.id);
          }
        }
        this.questionId.emit(this.questions[this.displayedQuest]?.id);
        this.ArticleQuestionsNumberEvent.emit(this.articleQuestionsNumber);
        this.questionsArray.emit(this.questions);
      });
    }
  }

  getAnswer(questionId) {
    this.answersApi
      .query({
        where: {
          userId: this.userId,
          questionId: questionId,
          examId: this.exam?.id,
        },
      })
      .subscribe((res) => {
        if (res["result"].length > 0) {
          if (this.questions[this.displayedQuest]?.type == "مقالى") {
            this.answered = true;
            this.isRightAnswer = true;
            this.isAnswered.emit(this.answered);
            this.isRightAnswerEvent.emit(this.isRightAnswer);
          } else this.selectAnswer(res["result"][0].answer);
        } else {
          if (this.isCompleted == true) {
            this.answered = true;
            this.isAnswered.emit(this.answered);
            this.isRightAnswer =
              this.questions[this.displayedQuest]?.type == "مقالى"
                ? true
                : false;

            this.isRightAnswerEvent.emit(this.isRightAnswer);
          }
        }
      });
  }

  showRigthAnswer() {
    this.showAnswer = true;
    this.showAnswerEvent.emit(true);
  }

  changeQuestion() {
    if (this.displayedQuest == this.AllQuestionsCount - 1) {
      this.finalQuestion = true;
    }
  }

  resetAnswers() {
    this.answers.splice(0, this.answers.length);
    if (
      this.questions[this.displayedQuest] &&
      this.questions[this.displayedQuest]["choices"] != null
    ) {
      Object.entries(this.questions[this.displayedQuest]["choices"]).forEach(
        (choice) => {
          const answer = {
            text: choice[1]["text"],
            selected: false,
          };
          this.answers.push(answer);
        }
      );
    }
    if (
      this.questions[this.displayedQuest] &&
      this.questions[this.displayedQuest].id
    )
      this.getAnswer(this.questions[this.displayedQuest].id);
  }

  selectAnswer(answer) {
    let answerIndex = this.answers.findIndex((ans) => {
      return ans.text === answer;
    });
    if (answerIndex > -1) {
      this.answers[answerIndex].selected = true;
      this.answered = true;
      this.isAnswered.emit(this.answered);
      this.showRightOrWrongForSelectionOnly();
    }
  }

  showRightOrWrongForSelectionOnly() {
    let Selected = (this.questions[
      this.displayedQuest
    ].selectedAnswer = this.answers.filter((ans) => ans.selected == true));
    if (Selected && Selected[0] && Selected[0].text) {
      if (this.questions[this.displayedQuest].type == "مقالى") {
        this.isRightAnswer = true;
      } else if (Selected[0].text === this.getRightAnswerFromChoices().text) {
        this.isRightAnswer = true;
      } else {
        this.isRightAnswer = false;
        this.selectRightAnswer();
      }
    } else {
      Selected = this.questions[this.displayedQuest].rightAnswer;
      this.isRightAnswer = true;
    }
  }

  // if right answer is not selected, the right answer will be marked for the student knowledge
  selectRightAnswer() {

    // if new question and right answer has a value of 1
    let indexOfRightAnswer = this.questions[this.displayedQuest]['choices'].findIndex(x => {
      return x.value == 1
    })
    if (indexOfRightAnswer > -1) {
      //index of right answer in answers array
      let RightAnswerIndexAnswersArray = this.answers.findIndex(ans => {
        return ans.text === this.questions[this.displayedQuest]['choices'][indexOfRightAnswer].text
      });

      // select right answer 
      this.answers[RightAnswerIndexAnswersArray].selected = true;
      // giving the right answer a value of one for the ngClass of right answer
      this.answers[RightAnswerIndexAnswersArray].value = 1;

    }

  }

  showRightOrWrong() {
    let Selected = (this.questions[
      this.displayedQuest
    ].selectedAnswer = this.answers.filter((ans) => ans.selected == true));
    if (Selected && Selected[0] && Selected[0].text) {
      if (this.questions[this.displayedQuest].type == "مقالى") {
        this.isRightAnswer = true;
        this.AnsweredArticleQuestions = this.AnsweredArticleQuestions + 1;
        return Selected;
      } else if (Selected[0].text === this.getRightAnswerFromChoices().text) {
        this.isRightAnswer = true;
        this.right = this.right + 1;
      } else {
        this.isRightAnswer = false;
        this.wrong = this.wrong + 1;
      }
    } else {
      Selected = this.questions[this.displayedQuest].rightAnswer;
      this.isRightAnswer = true;
      if (this.questions[this.displayedQuest].type == "مقالى") {
        this.AnsweredArticleQuestions = this.AnsweredArticleQuestions + 1;
      } else this.right = this.right + 1;

      return Selected;
    }
    return Selected;
  }

  getRightAnswerFromChoices() {
    const rightAnswers = this.questions[this.displayedQuest]?.choices.filter(
      (x) => x.value == 1
    );
    return rightAnswers[0];
  }

  checkedAnswer(trueOrFalse: boolean, index?: number) {
    if (
      this.questions[this.displayedQuest].type == "مقالى" &&
      this.questions[this.displayedQuest].choices
    ) {
      let index = this.questions[this.displayedQuest].choices.filter(
        (x) => x.text == this.questions[this.displayedQuest].rightAnswer
      );
      index = this.answers.findIndex((x) => x.text == index[0].text);
      if (index > -1) {
        this.answers[index].choiceIndex = index;
        this.answers[index].selected = true;
      }
    } else if (this.questions[this.displayedQuest].type != "مقالى") {
      if (index > -1) {
        this.answers[index].selected = trueOrFalse;
        this.answers[index].choiceIndex = index;
      }
    }
    if (trueOrFalse == true) this.answered = true;
    this.saveAnswer(this.showRightOrWrong());



  }

  saveAnswer(selected) {
    let answerText: string;
    if (selected && selected[0] && selected[0].text) {
      answerText = selected[0].text;
    } else {
      answerText = selected;
    }
    const answer = {
      examId: this.exam.id,
      questionId: this.questions[this.displayedQuest]?.id,
      id: null || this.questions[this.displayedQuest]?.answerId,
      answer: answerText,
      userId: this.userId,
      lectureId: this.questions[this.displayedQuest]?.lectureId,
      rightAnswer: this.questions[this.displayedQuest]?.rightAnswer,
      isRightAnswer: this.isRightAnswer,
    };

    this.answersApi.save(answer).subscribe((res) => {
      this.questions[this.displayedQuest].answerId = res["id"];
      this.isAnswered.emit(this.answered);
      this.isRightAnswerEvent.emit(this.isRightAnswer);
      const answer = {
        questionNumber: this.displayedQuest,
        isRight: res.isRightAnswer,
      };
      this.answerForBorder.emit(answer);
    });
    this.submitExam();
  }

  submitExam() {
    let rightAnswers = this.right + this.AnsweredArticleQuestions;
    let currentQuestion = this.displayedQuest;
    this.rightAnswersNumber.emit(rightAnswers);
    let wrong = this.wrong;
    let time = document.getElementById("time_spent").innerHTML;
    const finalExam = {
      id: this.exam.id,
      userId: this.userId,
      questionNumbers: this.AllQuestionsCount,
      isCompleted: false,
      rightAnswers: rightAnswers,
      wrongAnswers: wrong,
      currentQuestion: currentQuestion,
      time: time,
    };
    this.examsApi.update(finalExam).subscribe((res) => {
      this.exam = res;
      this.examUpdates.emit(res);
    });
  }

  getAnsweredArticleQuestions() {
    let articleArray = [];
    this.questions.forEach((question) => {
      if (question.type == "مقالى") {
        articleArray.push(question.id);
      }
    });
    if (articleArray.length > 0) {
      this.answersApi
        .query({
          where: {
            userId: this.userId,
            questionId: { inq: articleArray },
            examId: this.exam.id,
          },
        })
        .subscribe((res) => {
          if (res["count"] > 0) {
            this.AnsweredArticleQuestions = res["count"];
          }

          this.right = this.exam.rightAnswers || 0;
          this.right =
            this.right != 0 ? this.right - this.AnsweredArticleQuestions : 0;
          this.wrong = this.exam.wrongAnswers || 0;
        });
    }
  }

  changeNumbersToAr(text) {
    let map = [
      "&#1632;",
      "&#1633;",
      "&#1634;",
      "&#1635;",
      "&#1636;",
      "&#1637;",
      "&#1638;",
      "&#1639;",
      "&#1640;",
      "&#1641;",
    ];
    text = text.replace(/\t/g, '')
      .replace(/\t/g, '')
    if (text) {
      text = text.replace(/\d(?=[^<>]*(<|$))/g, ($0) => {
        return map[$0];
      });
      return text;
    }
  }

  iframeStyle() {
    let frame: any = document.getElementById("questionIframe");
    // console.log(frame.contentDocument.children[0].children[1]);
    // console.log(frame.contentWindow);
    var style = document.createElement("style");
    style.textContent =
      `body {
        float: right;
        overflow: hidden;
        white-space: break-spaces;
      }
      td {
        border: 1px solid black;
        
      }
      table {
        margin-right:0;
        width: ${frame.clientWidth - 30}px;
      }
      p, strong, pre, div {
        text-align: right;
        white-space: break-spaces ;
        font-family: 'SegoeUI' ;

      }
      `;
    frame.contentDocument.head.appendChild(style);
    frame.height = frame.contentWindow.document.body.scrollHeight + "px";
    frame.contentDocument.children[0].setAttribute("dir", "rtl");
    frame.contentDocument.children[0].setAttribute("lang", "ar");
    var iframeDoc = frame.contentDocument || frame.contentWindow.document;
    if (typeof iframeDoc.addEventListener != "undefined") {
      iframeDoc.addEventListener("contextmenu", this.rightMenuDisable, false);
      iframeDoc.addEventListener("selectstart", this.rightMenuDisable, false);
    } else if (typeof iframeDoc.attachEvent != "undefined") {
      iframeDoc.attachEvent("contextmenu", this.rightMenuDisable);
      iframeDoc.attachEvent("selectstart", this.rightMenuDisable);
    }
  }

  rightMenuDisable(event: Event) {
    event.preventDefault();
    return false;
  }

  copyDisable($event: KeyboardEvent) {
    if (
      ($event.ctrlKey || $event.metaKey) &&
      $event.keyCode != undefined &&
      $event.keyCode == 67
    ) {
      return false;
    }
  }


  hideToolbar() {
    document.getElementById('cke_editor1').childNodes[1]['style'].display = 'none'
  }
}
