import { Component, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-switcher",
  styleUrls: ["./app-switcher.sass"],
  templateUrl: "./app-switcher.html",
})
export class AppSwitcherComponent {
  @Output() emitToggle = new EventEmitter<boolean>();
  isOpened: boolean;

  toggle() {
    this.isOpened = !this.isOpened;
    this.emitToggle.emit(this.isOpened);
  }
}
