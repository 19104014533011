import { environment } from "./../../environments/environment";
import { AsyncObject } from "./../modules/shared/asyncObject";
import { Injectable, Output, EventEmitter } from "@angular/core";
import io from "socket.io-client";
import { UsersService } from "./apis/users.service";
import { Subject } from "rxjs";

@Injectable()
export class NotificationSocketService {
  socket: any;
  private notification = new Subject<string>(); // Source
  notification$ = this.notification.asObservable(); // Stream

  constructor(private profileApi: UsersService) {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    if (user) {
      this.socket = io(environment.socketUrl);
      this.socket.emit("join", { id: user.userId });
      console.log(user.userId);

      this.socket.on("notification", (data) => {
        // console.log(data);
        this.notification.next(data);
      });
    }
  }

  disconnect() {
    this.socket.disconnect();
  }
}
