import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Components.
import { ProjectBasePageComponent } from "@modules/project/components/project-base-page-component/project-base-page.component";
import { ProjectControlYourSubscribeComponent } from "@modules/project/components/project-control-your-subscribe/project-control-your-subscribe.component";
import { ProjectUpdateArticlesComponent } from "@modules/project/components/project-update-articles/project-update-articles.component";

const PROJECT_PREFIX = "project";

const ProjectRoutes: Routes = [
  {
    path: `${PROJECT_PREFIX}/info/base`,
    component: ProjectBasePageComponent,
    data: {
      prefix: "base",
    },
  },
  {
    path: `${PROJECT_PREFIX}/info/price-adjustment`,
    component: ProjectBasePageComponent,
    data: {
      prefix: "price-adjustment",
    },
  },
  {
    path: `${PROJECT_PREFIX}/manage-students`,
    redirectTo: `${PROJECT_PREFIX}/info/base`,
  },
  {
    path: `${PROJECT_PREFIX}/base-control`,
    redirectTo: `${PROJECT_PREFIX}/info/base`,
  },
  {
    path: `${PROJECT_PREFIX}/update-articles`,
    component: ProjectUpdateArticlesComponent,
  },
  {
    path: `${PROJECT_PREFIX}/control-your-subscribe`,
    component: ProjectControlYourSubscribeComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(ProjectRoutes),
    RouterModule.forChild(ProjectRoutes),
  ],
  exports: [RouterModule],
})
export class ProjectRoutingModule {}
