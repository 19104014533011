import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-exams-practice-2-test",
  templateUrl: "./exams-practice-2-test.html",
  styleUrls: ["./exams-practice-2-test.sass"],
})
export class ExamsPractice2TestComponent implements OnInit {
  ngOnInit() {}
}
