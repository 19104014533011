<div class="auth">
    <form class="auth-form">
      <p class="auth-form-title"> تغيير كلمة السر</p>
      <div class="auth-form-content">
        <div class="form-field auth-form-field">
          <label class="form-control-label">الأيميل</label>
          <input id="email"
            class="form-control"
            type="email"
            name="recoveryEmail" [(ngModel)]="recoveryEmail"
          />
          <div class="error" >
            <label *ngIf="missingInput"> لم يتم إدخال إيميل </label>
            <label *ngIf="invalidInput">  إيميل غير صحيح </label>
            <label *ngIf="missingEmail">  هذا الحساب غير متواجد</label>
            <label *ngIf="serverError">  Server error.</label>
            <label *ngIf="emailSent">  تم إرسال الرابط بنجاح</label>
        </div>

      </div>
      </div>
      <div class="form-field form-field-submit">
        <button
          class="btn btn-green submit-btn no-border"
          (click)="RecoverPassword()">
           إرسال الرابط
        </button>
      </div>
    </form>
  </div>
  