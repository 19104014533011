import { GalleryListComponent } from "./components/gallery-list/gallery-list.component";
import { TkUploadComponent } from "./components/upload/upload.component";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { NavbarComponent } from "./components/navbar-component/navbar.component";
import { FooterComponent } from "./components/footer-component/footer.component";
import { CommonDialogComponent } from "./components/common-dialog-component/common-dialog.component";
import { CommonContextMenuComponent } from "./components/common-context-menu-component/common-context-menu.component";
import { AppCapchaFieldComponent } from "./components/app-capcha-field-component/app-capcha-field.component";
import { FellowshipTableComponent } from "./components/fellowship-table-component/fellowship-table.component";
import { ConfirmPhoneNumberComponent } from "./components/confirm-phone-number-component/confirm-phone-number.component";
import { TableWithPaginationComponent } from "./components/table-with-pagination-component/table-with-pagination.component";
import { RangeControlsComponent } from "./components/range-controls-component/range-controls.component";
import { VideoUploaderComponent } from "./components/video-uploader-component/video-uploader.component";
import { CounterItemsComponent } from "./components/counter-items-component/counter-items.component";
import { FormControlRadioComponent } from "./components/form-control-radio-component/form-control-radio.component";
import { FormControlFileComponent } from "./components/form-control-file-component/form-control-file.component";
import { FormControlEditComponent } from "./components/form-control-edit-component/form-control-edit.component";
import { AdminAskLectorComponent } from "./components/admin-ask-lector-component/admin-ask-lector.component";
import { RightNavBarComponent } from "./components/right-nav-bar-component/right-nav-bar.component";
import { AppSwitcherComponent } from "./components/app-switcher-component/app-switcher.component";
import { AppCheckboxComponent } from "./components/app-checkbox-component/app-checkbox.component";
import { AppPaginationComponent } from "./components/app-pagination-component/app-pagination.component";

import { NgxCaptchaModule } from "ngx-captcha";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";

import { LecturesService } from "@services/apis/lectures.service";
import { NotificationMenuComponent } from "./components/notification-menu/notification-menu.component";
import { CodeInputModule } from "angular-code-input";
import { VimeModule } from "@vime/angular/dist";

@NgModule({
  providers: [LecturesService],
  imports: [
    BrowserModule,
    FormsModule,
    NgxCaptchaModule,
    RouterModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatTooltipModule,
    CodeInputModule,
    VimeModule
  ],
  declarations: [
    NavbarComponent,
    CommonDialogComponent,
    CommonContextMenuComponent,
    FooterComponent,
    AppCapchaFieldComponent,
    FellowshipTableComponent,
    ConfirmPhoneNumberComponent,
    TableWithPaginationComponent,
    RangeControlsComponent,
    VideoUploaderComponent,
    CounterItemsComponent,
    AdminAskLectorComponent,
    FormControlRadioComponent,
    FormControlFileComponent,
    FormControlEditComponent,
    GalleryListComponent,
    TkUploadComponent,
    RightNavBarComponent,
    AppSwitcherComponent,
    AppCheckboxComponent,
    AppPaginationComponent,
    NotificationMenuComponent,
  ],
  exports: [
    NavbarComponent,
    CommonDialogComponent,
    CommonContextMenuComponent,
    FooterComponent,
    AppCapchaFieldComponent,
    FellowshipTableComponent,
    ConfirmPhoneNumberComponent,
    TableWithPaginationComponent,
    RangeControlsComponent,
    VideoUploaderComponent,
    CounterItemsComponent,
    AdminAskLectorComponent,
    FormControlRadioComponent,
    FormControlFileComponent,
    FormControlEditComponent,
    GalleryListComponent,
    TkUploadComponent,
    RightNavBarComponent,
    AppSwitcherComponent,
    AppCheckboxComponent,
    AppPaginationComponent,
  ],
})
export class SharedModule {}
