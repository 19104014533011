import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

type LocationTypes = "Root" | "Profile" | "SubAndPayment";

@Component({
  selector: "app-home-analytics-root",
  templateUrl: "./home-analytics-root.component.html",
})
export class AppHomeAnalyticsRootComponent implements OnInit {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.activeLocation = "Root";
  }

  activeLocation: LocationTypes;

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      if (params.tabID == "payment") {
        this.activeLocation = "SubAndPayment";
      } else if (params.tabID == "me") {
        this.activeLocation = "Profile";
      } else {
        this.activeLocation = "Root";
      }
    });
  }
}
