import { AuthenticationService } from "./../../../../../services/apis/authentication.service";
import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
// import { PasswordChanged } from '../password-changed/password-changed';
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

const languageKey = "language";
@Component({
  selector: "create-password",
  templateUrl: "./create-password.html",
  styleUrls: ["./create-password.sass"],
})
export class CreatePassword implements OnInit {
  lang: any;
  vcode: any;
  password = "";
  repassword = "";
  noMatch = false;

  constructor(
    translation: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService
  ) {
    this.lang = localStorage.getItem(languageKey);
    translation.store.onLangChange.subscribe((res) => (this.lang = res.lang));
  }

  async ngOnInit() {
    this.vcode = this.route.snapshot.queryParamMap.get("vCode");
  }

  showPassword(id: any) {
    document.getElementById(id)["type"] = "text";
  }

  hidePassword(id: any) {
    document.getElementById(id)["type"] = "password";
  }

  Submit() {
    if (!this.password || !this.repassword) {
      return;
    }
    if (this.password !== this.repassword) {
      this.noMatch = true;
    } else {
      this.authService.resetPassword(this.vcode, this.password).subscribe(
        (x) => {
          this.router.navigateByUrl("/auth").then();
        },
        (err) => {
          if (err.status === 404) {
            console.log(err);
            // note : show error
          }
        }
      );
    }
  }
}
