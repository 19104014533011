import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from './../../../../services/apis/authentication.service';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";

interface SectionIcon {
  text: string;
  iconClass: string;
  link: string
}

interface SectionItem {
  text: string;
  iconClass?: string;
  link?: string;
  isBottomSection?: boolean;
  lastLevelSectionItems?: Array<SectionIcon>;
  bottomSectionItems?: Array<SectionIcon>;
}

export type SectionItems = Array<SectionItem>;

@Component({
  selector: "app-right-nav-bar",
  templateUrl: "./right-nav-bar.html",
  styleUrls: ["./right-nav-bar.sass"],
})
export class RightNavBarComponent implements OnInit, OnChanges {
  courseId: string;
  @Input() isOpened: boolean = false;
  @Input() sectionItems: Array<SectionItem>;
  @Output() emitToggle = new EventEmitter<boolean>(this.isOpened);

  constructor(
    private activatedRoute: ActivatedRoute,
    private authApi: AuthenticationService,
    private router: Router,

  ) {
  }

  toggle(): void {
    this.isOpened = !this.isOpened;
    this.emitToggle.emit(this.isOpened);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpened && changes.isOpened.currentValue) {
      this.CreateRigthMenu();
    }
    this.activatedRoute.params.subscribe(params => {
      if (params.courseId && params.courseId != undefined && params.courseId != 'undefined') {
        this.courseId = params.courseId;
      }
      else {
        this.activatedRoute.firstChild.params.subscribe(param => {
          if (param.courseId && param.courseId != undefined && param.courseId != 'undefined') {
            this.courseId = param.courseId;
          }
        })
      }
    })


  }
  CreateRigthMenu() {
    const currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    this.sectionItems = [];
    if (currentUser) {
      if (currentUser.allowExam) {
        this.sectionItems.push({
          text: "بنك الاسئلة",
          iconClass: "brain-icon",
          lastLevelSectionItems: [
            {
              text: "انشاء اختبار عشوائي",
              iconClass: "random-test-icon",
              link: `/lections/${this.courseId}/create-test`,
            },
            {
              text: "الاختبارات السابقة",
              iconClass: "previous-tests-icon",
              link: `/lections/${this.courseId}/previous-tests`,
            },
            {
              text: "الاداء",
              iconClass: "productivity-icon",
              link: `/lections/${this.courseId}/performance`,
            },
          ],
        });
      }
      if (currentUser.allowLecture) {
        if (!currentUser.allowExam) {
          this.sectionItems.push({
            text: "بنك الاسئلة",
            iconClass: "brain-icon",
            lastLevelSectionItems: [
              {
                text: "الاختبارات السابقة",
                iconClass: "previous-tests-icon",
                link: `/lections/${this.courseId}/previous-tests`,
              },
              {
                text: "الاداء",
                iconClass: "productivity-icon",
                link: `/lections/${this.courseId}/performance`,
              },
            ],
          });
        }
        this.sectionItems.push({
          text: "المحاضرات",
          iconClass: "lections-icon",
          link: `/lections/${this.courseId}/test_preparation`,
        });
        this.sectionItems.push({
          text: "الملخصات",
          iconClass: "cv-icon",
          link: `/lections/${this.courseId}/summeries`,
        });
        this.sectionItems.push({
          text: "ملاحظاتي",
          iconClass: "my-notes-icon",
          link: `/lections/${this.courseId}/mynotes`,
        });
        this.sectionItems.push({
          text: "اسألتى",
          iconClass: "ask-me-icon",
          link: `/lections/${this.courseId}/myquestion`,
        });
      }
    }
    this.sectionItems.push({
      text: "اسألتي",
      isBottomSection: true,
      bottomSectionItems: [
        {
          text: "الاعدادات",
          iconClass: "menu-settings-icon",
          link: "/profile/me",
        },
        {
          text: "تسجيل خروج",
          iconClass: "menu-logout-icon",
          link: "/auth",
        },
      ],
    });
  }
  ngOnInit() {
    if (!this.sectionItems) {
      this.sectionItems = [];
    }
  }
  ngAfterViewInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params.courseId && params.courseId != undefined && params.courseId != 'undefined') {
        this.courseId = params.courseId;
      }
      else {
        this.activatedRoute.firstChild.params.subscribe(param => {
          if (param.courseId && param.courseId != undefined && param.courseId != 'undefined') {
            this.courseId = param.courseId;
          }
        })
      }
    })

  }

  logOut() {
    console.log('logout');
    this.authApi.logout();
    this.router.navigate(['auth'])
  }
}