<div class="project-update-articles">
  <div class="project-update-articles-card" *ngIf="mode === 'viewMode'">
    <div class="project-update-articles-card-top-info">
      <span>مادة المحاسبة</span>
      <span
        >التحضير لمادة المحاسبة لأختبار الهيئة السعودية للمحاسبين
        القانونين</span
      >
    </div>
    <div class="project-update-articles-card-bottom-info">
      <span>
        مع التطورات السريعة في المعايير المحاسبية والأنظمة والممارسات الصناعية،
        يحتاج الأفراد إلى التدريب المستمر لمواكبة هذه التطورات. كما أنهم بحاجة
        الى تحسين جودة التقارير المالية من خلال التسجيل والإفصاح بدقة عن
        المعاملات التجارية العادية وغير العادية. أعدت هذه الدورة من قبل
        الأخصائيين من الأوساط الأكاديمية والذين لديهم خبرة عملية راسخة. المنهجية
      </span>
    </div>
    <div class="project-update-articles-card-info-panel">
      <div class="content-left">
        <span>تمكين الترتيب</span>
        <button class="move-icon"></button>
      </div>
      <div class="content-right">
        <span>أضافة محاضرة</span>
        <button
          class="btn icon-circle btn-icon-plus"
          (click)="addLecture()"
        ></button>
      </div>
    </div>
    <div class="project-update-articles-card-add-article-info">
      <div class="menu-toggle-icon add-article-info-toggle-icon"></div>
      <div class="form-field">
        <label>الاسم</label>
        <input type="text" class="form-control" [(ngModel)]="Article.name" />
      </div>
      <div class="form-field-uploader">
        <button class="btn-large btn-green" (click)="addLinkToTheVideo()">
          إضافة لينك للفيديو
        </button>
        <div class="file-uploaders">
          <app-video-uploader
            [placeholder]="videoUploaderSrc"
            [placeholderColor]="'#2DAAA6'"
            [type]="'video'"
            [index]="'videoSrc'"
            (changeVideoSrc)="handleVideoSrcChange($event)"
          >
          </app-video-uploader>
          <app-video-uploader
            [placeholder]="summariesUploaderSrc"
            [placeholderColor]="'#2DAAA6'"
            [type]="'file'"
            [index]="'fileSrc'"
            (changeVideoSrc)="handleVideoSrcChange($event)"
          >
          </app-video-uploader>
        </div>
      </div>
    </div>
  </div>
  <div class="project-update-articles-add-new" *ngIf="mode === 'addMode'">
    <div class="project-update-articles-add-new-top-info">
      <span>إضافة مادة جديدة</span>
      <div class="btn icon-circle btn-icon-plus"></div>
    </div>
    <div class="project-update-articles-add-new-card">
      <div class="project-update-articles-add-new-top-info-fields">
        <app-form-control-edit
          [value]="Article.title"
          (updateValue)="handleEditFieldChange($event)"
        >
        </app-form-control-edit>
        <app-form-control-edit
          [value]="Article.details"
          (updateValue)="handleEditFieldChange($event)"
        >
        </app-form-control-edit>
        <app-form-control-edit
          [value]="Article.details_2"
          (updateValue)="handleEditFieldChange($event)"
        >
        </app-form-control-edit>
      </div>
      <div>
        <button class="btn-large btn-green" (click)="modifyArticle()">
          تعديل
        </button>
      </div>
    </div>
    <div class="project-update-articles-add-new-bottom-info">
      <div class="form-field">
        <label>اسم المادة</label>
        <div style="flex-direction: column">
          <button class="btn-icon btn-edit-icon"></button>
          <input class="form-control" type="text" />
        </div>
      </div>
      <div class="form-field">
        <label>نوع المادة</label>
        <div style="flex-direction: column">
          <button class="btn-icon btn-edit-icon"></button>
          <input class="form-control" type="text" />
        </div>
      </div>
      <div class="form-field">
        <label>وصف المادة</label>
        <div style="flex-direction: column">
          <button class="btn-icon btn-edit-icon"></button>
          <input class="form-control" type="text" />
        </div>
      </div>
      <div class="form-field-submit">
        <button class="btn-large btn-green" (click)="splitLectures()">
          تقسيم المحاضرات
        </button>
      </div>
    </div>
  </div>
</div>
