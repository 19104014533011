import { UsersService } from "./../../../../../services/apis/users.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "promise",
  templateUrl: "./promise.html",
  styleUrls: ["./promise.sass"],
})
export class PromiseComponent implements OnInit {
  constructor(private userApi: UsersService, private modalService: NgbModal) {}
  ngOnInit() {}

  handlepromiseClose() {
    const currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser) {
      this.userApi.promise(true, currentUser.userId).subscribe(
        (res) => {
          console.log(res);
          currentUser.promise = true;
          console.log(currentUser);
          localStorage.setItem("currentUser", JSON.stringify(currentUser));
        },
        (error) => {
          console.log(error);
        }
      );
    }
    this.modalService.dismissAll();
  }
}
