import { MessagesService } from "../../../../../services/apis/messages.service";
import { Component, OnInit, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-ask-lecturer",
  templateUrl: "ask-lecturer.html",
  styleUrls: ["./ask-lecturer.sass"],
})
export class AskLecturerComponent implements OnInit {
  @Input() public lectureId: string;
  @Input() public courseId: string;
  userId: string;
  message:string;
  constructor(
    private modalService: NgbModal,
    private msgApi: MessagesService
  ) {}
  ngOnInit() {}

  submit() {
    const senderId = JSON.parse(localStorage.getItem("currentUser")).userId;
    const MESSAGE = {
      senderId: senderId,
      message: this.message,
      lectureId: this.lectureId,
      courseId: this.courseId,
      isSeen:false,
      toAdmin:true
    };
    this.msgApi.save(MESSAGE).subscribe();
    this.close();
  }

  close() {
    this.modalService.dismissAll();
  }
}
