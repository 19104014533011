<div class="payments-form-heading ">
  <p class="heading-title text-extra-bold" >الدفع عن طريق</p>
  <div class="payments-form-heading-select-payment-method">
    <div class="payments-form-heading-select-payment-method-block">
      <img src="assets/images/payment-methods.png" />
      <label for="payment-method-common">
        <span>Credit card</span>
        <input type="radio" (change)="choosePaymentMethod()" value="Credit" [(ngModel)]="payementMethod" name="payment-method" id="payment-method-common" />
      </label>
    </div>
    <div class="resultpay payments-form-heading-select-payment-method-block ">
      <img
        src="assets/images/paypal-payment-method.png"
        id="payment-method-paypal"
      />
      <label for="payment-method-paypal" style="margin-top: 4px;">
        <input type="radio" (change)="choosePaymentMethod()"  name="payment-method" value="paypal" [(ngModel)]="payementMethod"   />
      </label>
    </div>
  </div>
</div>
