import { NotificationSocketService } from "./../../../../services/notifications-socket.service";
import {
  Component,
  OnInit,
  EventEmitter,
  HostListener,
  ElementRef,
} from "@angular/core";
import { NotificationsService } from "@services/apis/notifications.service";
import { async } from "rxjs/internal/scheduler/async";
import * as moment from "moment";
import { Router, NavigationEnd } from "@angular/router";
@Component({
  selector: "notification-menu",
  templateUrl: "./notification-menu.component.html",
  styleUrls: ["./notification-menu.component.sass"],
})
export class NotificationMenuComponent implements OnInit {
  notifications: any = [];
  interval: any;
  isAdmin: boolean = false;
  isAllNotifications: boolean = true;
  userId = null;
  allNotificationsCount: number = 0;
  skippedNotifications: number = 5;
  constructor(
    private elementRef: ElementRef,
    private notificationsApi: NotificationsService,
    private router: Router,
    private notificationSocketService: NotificationSocketService
  ) {}
  down = false;
  downBox() {
    this.down = !this.down;
    this.markAllAsRead();
  }
  @HostListener("document:click", ["$event", "$event.target"])
  clickOut(event, targetElement: HTMLElement) {
    if (!targetElement) {
      return;
    }
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.down = false;
    }
    //this.down=false;
    //this.emitToggle.emit(this.isOpened);
  }
  getduration(endDate) {
    moment.locale("ar");
    if (endDate) {
      var duration = moment.duration(moment(endDate).diff(moment())).humanize();
      moment.locale("en");
      return duration;
    }
    moment.locale("en");
    return "";
  }
  async ngOnInit() {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser != null) {
      this.userId = currentUser.userId;
      this.isAdmin = currentUser.isAdmin;
    }
    await this.setNotifications(this.isAdmin, this.userId);

    this.notificationSocketService.notification$.subscribe((n) => {
      this.notifications.unshift(n);
    });
    this.getNotificationsCount();
  }

  private async setNotifications(isAdmin: boolean, userId: any) {
    if (isAdmin) {
      this.notifications = await this.notificationsApi
        .query({
          where: {
            toAdmin: isAdmin,
            isSeen: false,
          },
          order: "created desc",
          limit: 5,
        })
        .toPromise();
    } else {
      this.notifications = await this.notificationsApi
        .query({
          where: {
            and: [
              {
                toAdmin: isAdmin,
                isSeen: false,
                senderId: userId,
              },
            ],
          },
          order: "created desc",
          limit: 5,
        })
        .toPromise();
    }
    this.notifications = this.notifications.result;
  }

  markAllAsRead() {
    this.notifications.forEach((notification) => {
      if (notification.isSeen == false) {
        notification.isSeen = true;
        this.notificationsApi.save(notification).subscribe();
      }
    });
  }

  onOpenNotification(item) {
    this.notificationsApi
      .save({
        id: item.id,
        isSeen: true,
      })
      .subscribe((res) => {
        if (item.messageType == "register") {
          this.router.navigate(["/dashboard/admin/manage-students"]);
        } else if (item.messageType == "question") {
          this.router.navigate(["/dashboard/admin"]);
        } else if (item.messageType == "answer") {
          this.router.navigate(["/lections/myquestion"]);
        } else if (item.messageType == "lecture") {
          this.router.navigate(["/lections"]);
        }
      });
  }

  showMore() {
    if (this.isAdmin == true) {
      this.notificationsApi
        .query({
          where: {
            toAdmin: this.isAdmin,
          },
          order: "created desc",
          limit: 5,
          skip: this.skippedNotifications,
        })
        .subscribe((res) => {
          this.pushToArray(res);
        });
    } else {
      this.notificationsApi
        .query({
          where: {
            and: [
              {
                toAdmin: this.isAdmin,
                senderId: this.userId,
              },
            ],
          },
          order: "created desc",
          limit: 5,
          skip: this.skippedNotifications,
        })
        .subscribe((res) => {
          this.pushToArray(res);
        });
    }
  }

  pushToArray(res) {
    if (res["count"] > 0) {
      res["result"].forEach((n) => {
        this.notifications.push(n);
      });
      this.skippedNotifications = this.skippedNotifications + 5;
      if (this.allNotificationsCount == this.notifications.length)
        this.isAllNotifications = false;
    }
  }

  getNotificationsCount() {
    if (this.isAdmin != true) {
      this.notificationsApi
        .query({
          where: {
            and: [
              {
                toAdmin: this.isAdmin,
                senderId: this.userId,
              },
            ],
          },
        })
        .subscribe((res) => (this.allNotificationsCount = res["count"]));
    } else {
      this.notificationsApi
        .query({
          where: {
            toAdmin: this.isAdmin,
          },
        })
        .subscribe((res) => (this.allNotificationsCount = res["count"]));
    }
  }
}
