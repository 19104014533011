<div class="register-fellowship-payments-form">
  <div class="payments-form-result">
    <app-register-payment-form-heading (payementChanged)="setPayementMethod($event)"></app-register-payment-form-heading>
    <p>اضغط على استكمال حتى تتم عملية الدفع عن طريق الحساب الخاص بكم</p>
    <div class="payments-form-result-submit">
      <div *ngIf="!selectPaymentMethod"
      class="form-control-feedback" style="color: red;">
          اختار طريقه الدفع
      </div>
      <div *ngIf="PaymentError"
      class="form-control-feedback" style="color: red;">
          حدث خطأ فى الدفع
      </div>
      <button [hidden]="isPaypal" (click)="confirmPaymentInfo()" class="btn btn-green no-border">
        إستكمال
      </button>
      <div [hidden]="!isPaypal" id="paypal-checkout-btn"></div>
    </div>
  </div>
</div>
