<div class="right-nav-bar" *ngIf="isOpened">

  <button class="toggle-nav-bar-icon btn-icon" (click)="toggle()"></button>
  <div class="right-nav-bar--divider-content">
    <div class="right-nav-bar--divider-content--top">
      <div class="section-1">
        <span [routerLink]="['/profile/me']"> عودة الى الملف الشخصي</span>
        <button class="arrow-back-icon c-pointer" [routerLink]="['/profile/me']"></button>
      </div>
      <div class="section-2">
        <span>زمالتي</span>
        <div class="book-icon-wrapper book-icon-wrapper--light">
          <div class="book-icon-light"></div>
        </div>
      </div>
    </div>
    <div class="right-nav-bar--divider-content--bottom">
      <ul class="right-nav-bar--divider-content--bottom--items">
        <li *ngFor="let sectionItem of sectionItems" class="menu-item">
          <div *ngIf="sectionItem.isBottomSection" class="menu-item--multiple">
            <!-- <div *ngFor="let bottomSectionItem of sectionItem.bottomSectionItems" class="menu-item--multiple-item">
              <a [routerLink]="bottomSectionItem.link">{{bottomSectionItem.text}}</a>
              <div class="{{bottomSectionItem.iconClass}} menu-item-icon"></div>
            </div> -->
            <div class="menu-item--multiple-item">
              <a routerLink="/profile/me">الاعدادات</a>
              <div class="menu-settings-icon menu-item-icon"></div>
            </div>
            <div class="menu-item--multiple-item">
              <a href="javascript:;" (click)="logOut()">تسجيل خروج</a>
              <div class="menu-logout-icon menu-item-icon"></div>
            </div>



          </div>
          <div *ngIf="!sectionItem.isBottomSection" class="menu-item--single">
            <div class="menu-item--single-content">
              <a [routerLink]="sectionItem.link">{{sectionItem.text}}</a>
              <div class="{{sectionItem.iconClass}} menu-item--icon"></div>
            </div>
            <div class="menu-item--last-level" *ngIf="sectionItem.lastLevelSectionItems">
              <div *ngFor="let lastLevelSectionItem of sectionItem.lastLevelSectionItems" class="menu-item--last-level-item">
                <a [routerLink]="lastLevelSectionItem.link">{{lastLevelSectionItem.text}}</a>
                <div class="{{lastLevelSectionItem.iconClass}} menu-item--last-level-icon"></div>
              </div>

            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>