    <div class="home-analytics-dialog-content row justify-content-center">
      <div class="col-lg-12">
          <div class="row justify-content-center">
              <img class="w-19 my-5" src="assets/images/law-dialog.png" />

          </div>
      </div>
      <div class="col-lg-12">
          <div class="row justify-content-center">
              <p class="font25 px-5">
                  قال تعالى (يَا أَيُّهَا الَّذِينَ آمَنُوا أَوْفُوا بِالْعُقُودِ) اتعهد
                  امام الله بعدم مشاركة حسابي مع الغير ولن نحلل مشاركة بيانات حسابك مع
                  الغير
                </p>
            </div>
            </div>
        <div class="col-lg-12">
          <div class="row justify-content-center">
            <button class="btn-green btn no-border promise "  (click)="handlepromiseClose()">
              اتعهد
            </button>
            </div>
        </div>
    </div>
