import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  AfterViewChecked,
} from "@angular/core";
import { async } from "@angular/core/testing";
import { Router } from "@angular/router";
import { AuthenticationService } from "@services/apis/authentication.service";
import { PlansService } from "@services/apis/plans.service";
import { TransactionsService } from "@services/apis/transaction.service";
import { UsersService } from "@services/apis/users.service";
import * as moment from "moment";
import { promise } from "protractor";
declare var paypal;
@Component({
  selector: "app-register-payment-form",
  templateUrl: "./register-payment-form.html",
  styleUrls: ["./register-payment-form.component.sass"],
})
export class RegisterPaymentFormComponent implements OnInit, AfterViewChecked {
  @ViewChild("paypalRef", { static: true }) paypalRef: ElementRef;
  @Output() afterSubmitPaymentInfo: EventEmitter<any> = new EventEmitter<any>();
  @Input() user;
  @Input() participationItem;
  registerPaymentForm: any;
  paymentInfoSubmitted: boolean;
  isDisable: boolean;
  paymentMethod: any;
  selectPaymentMethod: any = true;
  addScript: boolean = false;
  isPaypal: boolean = false;
  paidFor = false;
  PaymentError = false;
  paypalconfig = {
    env: "production",
    client: {
      // sandbox:
      //   "Ab9i0OMUa0lDVYg46lWmtaON-Ee5TmLXLzoIWaa6lbxwO-iA9uhg4r6s7fpldY4n1eAujCd69YIxA5aY",
      production:
        "AcyMcIVuuowFlUXqtb8dMqWisO5hjTadvABs150bBx3APCHA90e6cMIwSg4leHvhP12286sojvKmnQzz",
    },
    commit: true,
    payment: (data, action) => {
      return action.payment.create({
        payment: {
          transactions: [
            {
              amount: {
                total: this.participationItem.dolarPrice,
                currency: "USD",
              },
            },
          ],
        },
      });
    },
    onAuthorize: (data, action) => {
      return action.payment.execute().then(async (payment) => {
        console.log(data);

        if (payment.state == "approved") {
          let currentuser: any = JSON.parse(
            localStorage.getItem("currentUser")
          );
          let _profile = null;
          let _user = null;
          if (currentuser == null) {
            _profile = await this.userApi.get(this.user.userId).toPromise();
            _user = await this.authApi.get(this.user.userId).toPromise();
          } else {
            _profile = await this.userApi.get(currentuser.userId).toPromise();
            _user = await this.authApi.get(currentuser.userId).toPromise();
          }
          this.transactionApi
            .payPal(
              _profile,
              this.participationItem,
              payment.id,
              payment.payer.payer_info.payer_id
            )
            .subscribe(
              (res) => {
                if (_user.isVerified) {
                  this.router.navigate(["/profile/payment"]);
                } else {
                  this.router.navigate(["/confirmCode"]);
                }
              },
              (error) => {
                this.PaymentError = true;
              }
            );
        } else {
          this.PaymentError = true;
        }
      });
    },
  };
  constructor(
    private transactionApi: TransactionsService,
    private userApi: UsersService,
    private authApi: AuthenticationService,
    private planApi: PlansService,
    private router: Router
  ) {}
  ngAfterViewChecked(): void {
    if (!this.addScript) {
      this.addPaypalScript().then(() => {
        paypal.Button.render(this.paypalconfig, "#paypal-checkout-btn");
      });
    }
  }
  addPaypalScript() {
    this.addScript = true;
    return new Promise((resolve, reject) => {
      let scripttagElement = document.createElement("script");
      scripttagElement.src = "https://www.paypalobjects.com/api/checkout.js";
      scripttagElement.onload = resolve;
      document.body.appendChild(scripttagElement);
    });
  }
  ngOnInit() {
    this.registerPaymentForm = {};
  }
  async confirmPaymentInfo() {
    if (this.paymentMethod === "Credit") {
      let currentuser: any = JSON.parse(localStorage.getItem("currentUser"));
      let _profile = null;
      if (currentuser == null) {
        _profile = await this.userApi.get(this.user.userId).toPromise();
      } else {
        _profile = await this.userApi.get(currentuser.userId).toPromise();
      }
      this.participationItem.beforeDiscount = this.participationItem.text;
      this.participationItem.text = this.participationItem.afterDiscount;
      console.log(this.participationItem);
      this.transactionApi.pay(_profile, this.participationItem).subscribe(
        (result) => {
          window.open(result.redirectURL, "_self");
          this.paymentInfoSubmitted = true;
        },
        (error) => {
          console.log(error);
        }
      );
    } else if (this.paymentMethod === "paypal") {
    } else {
      this.selectPaymentMethod = false;
    }
  }
  setPayementMethod(value) {
    if (value == null || value === "") {
      this.selectPaymentMethod = false;
    } else {
      this.isPaypal = false;
      if (value === "paypal") {
        this.isPaypal = true;
      }
      this.paymentMethod = value;
      this.selectPaymentMethod = true;
    }
  }

  submitPaymentInfo() {
    this.afterSubmitPaymentInfo.emit();
  }
}
