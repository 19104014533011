import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { PlansService } from "@services/apis/plans.service";

@Component({
  selector: "app-register-payment-form-heading",
  templateUrl: "./register-payment-form-heading.html",
  styleUrls: ["./register-payment-form-heading.component.sass"],
})
export class RegisterPaymentFormHeadingComponent implements OnInit {
  payementMethod:string;
  @Output() payementChanged: EventEmitter<any> = new EventEmitter<any>();
  constructor(private participationsApi: PlansService){

  }
async ngOnInit(){

 }
 choosePaymentMethod(){
   this.payementChanged.emit(this.payementMethod);
 }
}
