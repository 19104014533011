<div class="video-uploader">
  <div class="video-uploader-pane">
    <span [ngStyle]='{"color": placeholderColor}'>{{placeholder}}</span>
  </div>
  <input
    type="file"
    [(ngModel)]="videoSrc"
    (change)="onVideoSrcChange($event)"
  />
</div>
