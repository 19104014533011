<div class="phone-verification-step">
  <img
    src="assets/images/attendance.png"
    class="phone-verification-step-block-image"
  />
  <p class="phone-verification-step-block">
    <span> تم ارسال رسالة تأكيد على الايميل </span>
    <span>الرجاء التحقق منها وإدخال</span>
    <span> كود التأكيدى للدخول</span>
  </p>
  <p (click)="resendEmailCode()" class="phone-verification-step-block">
    <button type="button" (click)="resendEmailCode()" class="btn btn-link">ارسل مرة اخرى</button>
    <span>لم يصلني كود </span>
  </p>
  <div>
    <div class="phone-verification-step-input-container">
      <!-- <input
        [(ngModel)]="verificationInfo[0]"
        [ngModelOptions]="{ standalone: true }"
        maxlength="1"
        type="number"
      />
      <input
        [(ngModel)]="verificationInfo[1]"
        [ngModelOptions]="{ standalone: true }"
        maxlength="1"
        type="number"
      />
      <input
        [(ngModel)]="verificationInfo[2]"
        [ngModelOptions]="{ standalone: true }"
        maxlength="1"
        type="number"
      />
      <input
        [(ngModel)]="verificationInfo[3]"
        [ngModelOptions]="{ standalone: true }"
        maxlength="1"
        type="number"
      />
      <input
        [(ngModel)]="verificationInfo[4]"
        [ngModelOptions]="{ standalone: true }"
        maxlength="1"
        type="number"
      /> -->
      <code-input [isCodeHidden]="true"
              [codeLength]="5"
              [isCodeHidden]="false"
              (codeChanged)="onCodeChanged($event)"
              (codeCompleted)="submit($event)"
              id="code">
  </code-input>
    </div>
    <div class="form-field">
      <button [disabled]="disabled" [ngClass]="{'red_btn': disabled == false}" (click)="clearCode()" class="btn grey_btn submit-btn no-border">
        {{confirmationBtn}}
      </button>
    </div>
  </div>
</div>
