import { ActivatedRoute } from '@angular/router';
import { SummariesService } from "./../../services/apis/summaries.service";
import { Component, OnInit, Input } from "@angular/core";
import { LecturesService } from "@services/apis/lectures.service";
import { StringifyOptions } from "querystring";

@Component({
  selector: "summaries",
  templateUrl: "./summaries.component.html",
  styleUrls: ["./summaries.component.sass"],
})
export class SummariesComponent implements OnInit {
  @Input() isRightNavOpened: boolean;
  isTimeThrowed: boolean;
  summaries: any[] = [];
  courseId: StringifyOptions;

  constructor(
    public lecturesService: LecturesService,
    private summeryApi: SummariesService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if (params.courseId && params.courseId != undefined && params.courseId != 'undefined') {
        this.courseId = params.courseId;
      }
      else {
        this.activatedRoute.firstChild.params.subscribe(param => {
          if (param.courseId && param.courseId != undefined && param.courseId != 'undefined') {
            this.courseId = param.courseId;
          }
        })
      }
    })
    this.summeryApi.query({where: {courseId: this.courseId}}).subscribe((res) => {
      this.summaries = res["result"];
    });
  }
}
