import { ExamsService } from "./../../../../services/apis/exam.service";
import { AnswersService } from "./../../../../services/apis/answers.service";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-question-numbers",
  templateUrl: "./question-numbers.html",
  styleUrls: ["./question-numbers.scss"],
})
export class QuestionNumbersComponent implements OnInit {
  @Input() isCompleted: boolean = false;
  @Input() exam: any;
  @Input() displayedQuest: number;
  @Input() questions: any[] = [];
  @Input() answersForBorder: any[] = [];
  @Input() userId: string;
  @Input() examId: string;
  @Input() newAnswer: any;
  @Output() questionNumber = new EventEmitter<Number>();
  disabled = true;
  active = 1;
  tabs: any = {
    false: ["المكتمل 0", "المتبقي 0"],
    true: ["إجابة صحيحة", "إجابة خاطئة"],
  };

  constructor(
    private answersApi: AnswersService,
    private examsApi: ExamsService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.tabsInnerText();
      this.showRightOrWrongAnswers();
    }, 2000);
  }

  ngOnChanges(): void {
    setTimeout(() => {
      this.getUpdatedExam();
      this.tabsInnerText();
      this.addNewAnswer(this.newAnswer);
    }, 2000);
    this.Scroll();
  }

  addNewAnswer(newAnswer: any) {
    let index = this.answersForBorder?.findIndex(
      (x) => x?.questionNumber == newAnswer?.questionNumber
    );
    if (index == -1) {
      this.answersForBorder.push(newAnswer);
    } else {
      this.answersForBorder[index] = newAnswer;
    }
  }

  tabsInnerText() {
    if (this.exam) {
      setTimeout(() => {
        let completed = this.exam.rightAnswers + this.exam.wrongAnswers || 0;
        let left = this.exam.questions?.length - completed || 0;
        if (this.isCompleted == true) {
          this.tabs["true"][0] = `الإجابة الصحيحة  ${
            this.exam.rightAnswers == null ? "" : this.exam.rightAnswers
          }`;
          this.tabs["true"][1] = ` الإجابة الخاطئة  ${
            this.exam.wrongAnswers == null ? "" : this.exam.wrongAnswers
          }`;
        } else {
          this.tabs["false"][0] = ` المتبقي  ${left}`;
          this.tabs["false"][1] = ` المكتمل  ${completed}`;
        }
      }, 500);
    }
  }

  filterBorder(j: number) {
    const answer = this.answersForBorder?.filter((ans) => {
      return ans?.questionNumber == j;
    });
    return answer[0];
  }

  showRightOrWrongAnswers() {
    this.answersForBorder.splice(0, this.answersForBorder.length);
    let counter = 0;

    for (let i = 0; i < this.questions.length; i++) {
      let isRightAnswer: any;
      this.answersApi
        .query({
          where: {
            userId: this.userId,
            examId: this.examId,
            questionId: this.questions[i].id,
          },
        })
        .subscribe(async (ans) => {
          if (ans["count"] > 0) {
            const answer = ans["result"][0];
            counter = i;
            this.answersForBorder.push({
              questionNumber: counter,
              isRight: await answer.isRightAnswer,
            });
          } else {
            counter = i;
            if (this.isCompleted == true) {
              if (this.questions[counter].type == "مقالى") {
                isRightAnswer = true;
              } else isRightAnswer = false;
            } else isRightAnswer = "skipped";
            this.answersForBorder.push({
              questionNumber: counter,
              isRight: isRightAnswer,
            });
          }
        });
    }
  }

  changeQuestion(questionNumber: Number) {
    this.questionNumber.emit(questionNumber);
    // this.Scroll();
  }

  getUpdatedExam() {
    this.examsApi.get(this.exam.id).subscribe((res) => {
      this.exam = res;
    });
  }

  Scroll() {
    let widthDiv = document.getElementById("all_numbers");
    let left = document.getElementById(`number_${this.displayedQuest}`);
    if (this.displayedQuest > 4) {
      widthDiv.scrollLeft = left.offsetLeft - 500;
    } else {
      if (left) widthDiv.scrollLeft = left.offsetLeft + 500;
    }
  }
}
