import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "@services/apis/authentication.service";
import { UsersService } from "@services/apis/users.service";
import { error } from "protractor";

@Component({
  selector: "app-home-analytics-profile",
  templateUrl: "./home-analytics-profile.component.html",
  styleUrls: ["./home-analytics-profile.component.sass"],
})
export class ProfileComponent implements OnInit {
  editProfile: any;
  user: any;
  invalidMobile = false;
  emailExists = false;
  notMatched = false;
  confirmPassword: any;
  currentPassword: any;
  newPassword: any;
  invalidPassword = false;
  phoneExist = false;
  haserror = false;
  savedForm: boolean = false;
  constructor(
    private authApi: AuthenticationService,
    private usersApi: UsersService,
    private router: Router
  ) {
    this.editProfile = {};
  }
  async ngOnInit() {
    const currentUser: any = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser != null) {
      this.user = await this.usersApi
        .query({ where: { userId: currentUser.userId } })
        .toPromise();
      this.user = this.user.result[0];
    }
  }
  showPassword() {
    (document.getElementById("password") as HTMLInputElement).type = "text";
  }

  hidePassword() {
    (document.getElementById("password") as HTMLInputElement).type = "password";
  }
  isValidMobile(mobile): boolean {
    return /^\+[0-9]?()[0-9](\s|\S)(\d[0-9]{9})$/.test(mobile);
  }
  updateFormStep() {
    if (!this.isValidMobile(this.user.mobile)) {
      this.invalidMobile = true;
      return;
    } else {
      this.invalidMobile = false;
    }
    if (this.newPassword != null) {
      if (this.confirmPassword !== this.newPassword) {
        this.notMatched = true;
        return;
      }
    }
    this.user.password = this.currentPassword;
    this.user.newPassword = this.newPassword;
    this.resetError();
    this.usersApi.updateUser(this.user).subscribe(
      (res) => {
        if (this.user.password != null && this.user.newPassword != null) {
          let data = {
            userId: this.user.id,
            currentpassword: this.user.password,
            newpassword: this.user.newPassword,
          };
          this.authApi.changePassword(data).subscribe(
            (result) => {
              // msg
              this.savedForm = true;
              setTimeout(() => {
                this.savedForm = false;
              }, 3000);
              this.confirmPassword = "";
              this.currentPassword = "";
              this.newPassword = "";
              // this.router.navigate(["/auth"]).then();
            },
            (error) => {
              console.log(error);
              this.invalidPassword = true;
            }
          );
        } else {
          // this.router.navigate(["/"]).then();
          this.savedForm = true;
          setTimeout(() => {
            this.savedForm = false;
          }, 5000);
        }
        this.usersApi
          .query({ where: { userId: this.user.id } })
          .subscribe((res) => {
            this.user = res["result"][0];
          });
      },
      (error) => {
        if (
          error.status === 451 &&
          error.error.message === "هذاالأيميل  لديه حساب أخر بالفعل."
        ) {
          this.emailExists = true;
        } else if (error.status === 450) {
          this.phoneExist = true;
        } else {
          this.haserror = true;
        }
      }
    );
  }
  resetError() {
    this.phoneExist = false;
    this.haserror = false;
    this.emailExists = false;
  }
}
