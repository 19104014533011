<div class="lections-create-test">
    <div class="lections-create-test--content" [ngClass]="{'collapsed': lecturesService.rightNavState }">
        <div class="lections-create-test--content--section">


            <ng-container *ngIf="questions && questions.length; else noQuestions">
                <div *ngFor="let question of questions">
                    <div class="admin-articles-modify-middle margfo">
                        <span class="spanedit"> {{question.message}}</span>
                        <div style="margin-left: 4%;">
                            <img class="editimg hand" src="assets/icons/raise-hand.svg" />
    
                        </div>
                    </div>
    
                    <div class="card cardsub">
    
                        <div class="divmain">
                            <div class="contenttitle">
                                <h4> {{question.answer}}</h4>
                                <div style="margin-top: 5%; ">
                                    <span style="font-size: 17px;"> من مادة </span>
                                    <span style="font-size: 22px;
                    margin-right: 1%;">: {{question._lecture._course.name}}</span>
    
                                </div>
                                <div style="margin-top: 5px; ">
                                    <span style="font-size: 17px;"> من محاضرة </span>
                                    <span class="spanmohspa">: {{question._lecture.LectureName}}</span>
                                </div>
    
                            </div>
                            <div class="another">
                                <h4>{{question.created | date: "d/MM/yyyy"}}</h4>
                            </div>
    
                        </div>
    
    
    
    
    
                    </div>
                </div>
            </ng-container>
            <ng-template #noQuestions>
                <div>
                    <div class="admin-articles-modify-middle margfo d-flex justify-content-center">
                        <span class="spanedit">لا يوجد أسئلة</span>
                    </div>
                </div>
            </ng-template>
            

        </div>
    </div>
</div>