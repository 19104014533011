import { Component, OnInit,Input } from '@angular/core';
import { LecturesService } from '@services/apis/lectures.service';

@Component({
  selector: 'mynotesto',
  templateUrl: './mynotesto.component.html',
  styleUrls: ['./mynotesto.component.sass']
})
export class MynotestoComponent implements OnInit {

  @Input() isRightNavOpened: boolean;
  isTimeThrowed: boolean;
  constructor(public lecturesService: LecturesService) { }

  ngOnInit(): void {
  }

}
