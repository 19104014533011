<div class="container">
    <div class="row first_row">
        <div class="col-12 back_to_home">
            <a [routerLink]="['/']" class="back_to_home">
                العودة إلى الصفحة الرئيسية
            </a>
        </div>
        <div class="col-12 loader">
            <div class="load-3">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <!-- <p class="w-75">Loading</p> -->
            </div>
        </div>
        <div class="col-10" *ngIf="error"> Error:
            {{error}}
        </div>
    </div>
</div>